import { RefObject, useEffect, useState } from 'react'
import styled from 'styled-components'
import { cardapioItem } from '../../../types/cardapioItem'
import { Request, RequestEvent } from '../../../types/requests'
import { StoreParams } from '../../../types/storeInfo'
import { capitalizeEachWord } from '../../../utils/capitalizeEachWord'
import { getRequestInfo } from '../../../utils/getRequestInfo'

import imageError from '../../../assets/icons/image-error.png'
import RequestStatus from './RequestStatus'
import OrderEvaluation from './OrderEvaluation'
import { setRequestToBeRepeated, setStoreParams } from '../../../store/features/info/infoSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OverlayNotification from '../../../components/OverlayNotification'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { clear } from '../../../store/features/cart/cartSlice'
import { setType } from '../../../store/features/cardapio/cardapioSlice'

type RequestCardProps = {
  request: Request,
  stores: StoreParams[],
  containerRef: RefObject<HTMLDivElement>
}

type RequestCardContainerProps = {
  bg: string
}

const RequestCardContainer = styled.div<RequestCardContainerProps>`
  border: 1px solid #cccccc;
  border-radius: 1rem;
  color: #464646;

  width: 100%;

  font-size: 1.3rem;

  padding: 1.4rem;
  margin-bottom: 1.8rem;

  box-shadow: 3px 3px 9px -8px rgb(0 0 0 / 52%);

  .request_info {
    display: flex;

    margin-bottom: 1.6rem;

    .status {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      margin-right: 1rem;

      img {
        width: 60%;

        margin-bottom: 1rem;
      }

      p {
        font-weight: 700;
      }
    }

    .description {
      flex: 2;
    }

    p {
      font-size: 1.2rem;
      margin: .2rem 0;
    }

    .price, .method {
      font-weight: 700;
    }

    .price {
      color: #00a989;
    }

    .method {
      color: #ffc409;
    }
  }

  .store_details {
    display: flex;
    align-items: center;
  }

  .store_info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #cccccc;

    margin-bottom: 1.6rem;
    padding-bottom: 1rem;

    .icon {
      border-radius: .4rem;

      width: 4rem;
      height: 4rem;

      margin-right: 1rem;

      &.valid_image {
        background: #${({ bg }) => bg};
      }

      img {
        border-radius: .4rem;

        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
      }
    }

    .name {
      font-size: 1.2rem;
      font-weight: 700;
    }

    button {
      color: ${({ theme }) => theme.colors.primary.normal};
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  .action_buttons {
    display: flex;

    border-top: 1px solid #cccccc;
    padding: 2rem 0;

    button {
      flex: 1;

      color: #ff0000;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  button:disabled {
    color: #aaaaaa;

    cursor: initial;
  }

  @media (min-width: 646px) {
    width: 45%;
    margin-right: 5.4rem;
    margin-bottom: 3.2rem;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (min-width: 800px) {
    margin-right: 7rem;
  }
`

function RequestCard({ request, stores, containerRef }: RequestCardProps) {
  const { stores: storesList } = useSelector((state: RootState) => state.stores)

  const [photoError, setPhotoError] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [requestStatusOpen, setRequestStatusOpen] = useState<boolean>(false)
  const [orderEvaluationOpen, setOrderEvaluationOpen] = useState<boolean>(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const items = JSON.parse(request.Itens)
  const events: RequestEvent[] = JSON.parse(request.Eventos)

  const splittedDate = request.DataHora.split('T')
  const date = splittedDate[0].split('-').reverse().join('/')

  const splittedHour = splittedDate[1].split('.')[0].split(':')
  const hour = `${splittedHour[0]}:${splittedHour[1]}`

  const requestInfo = getRequestInfo(request.Evento)

  const currentRequestStore = stores.find((store) => store.Sigla === request.Sigla)

  const storePhoto = currentRequestStore?.LogoUri
  const storeColor = currentRequestStore?.Parametros.find((param) => param.Chave === 'MV_COLOR_BACKGROUND')?.Valor

  const storeEvaluation = currentRequestStore?.Parametros.find((param) => param.Chave === 'MV_LISTAGEM_AVALIACAO')?.Valor
  const parsedStoreEvaluation = storeEvaluation ? JSON.parse(storeEvaluation) :  null

  const storeLicense = currentRequestStore?.Parametros.find((param) => param.Chave === 'MV_NROLICENCA')?.Valor

  function goToStore(store: StoreParams) {
    dispatch(setStoreParams(store))

    navigate('/loja/cardapio')
  }

  function checkStore (store: StoreParams): boolean {
    const currentStoreOnStoreList = storesList.find((st) => st.Id === store?.Id)

    return currentStoreOnStoreList ? true : false
  }
  
  function handleRepeatRequest(store: StoreParams | undefined, requestData: string, requestType: 'withdraw' | 'delivery' | 'drive') {
    dispatch(clear(requestType))
    
    if (!store || !checkStore(store)) {
      setError('A loja não está mais disponível.')
      return
    }

    dispatch(setRequestToBeRepeated({
      request: requestData,
      type: requestType
    }))

    const menuType = requestType === 'withdraw' ? 'retirada' : requestType === 'delivery' ? 'entrega' : 'drive'

    dispatch(setType(menuType))

    goToStore(store)
  }

  useEffect(() => {
    if (!containerRef.current) return

    if (requestStatusOpen || orderEvaluationOpen) {
      containerRef.current.classList.add('modal_open')
    } else {
      containerRef.current.classList.remove('modal_open')
    }
  }, [requestStatusOpen, orderEvaluationOpen, containerRef])

  useEffect(() => {
    if (requestStatusOpen) {
      setOrderEvaluationOpen(false)
    }

    if (orderEvaluationOpen) {
      setRequestStatusOpen(false)
    }
  }, [requestStatusOpen, orderEvaluationOpen])

  return (
    <RequestCardContainer bg={storeColor || 'ffffff'}>
      <div className="store_info">
        <div className="store_details">
          <div className={`icon ${!photoError && 'valid_image'}`}>
            <img
              src={(!photoError && storePhoto) ? storePhoto : imageError}
              onError={() => setPhotoError(true)}
              alt="Ícone da loja"
            />
          </div>
          <div className="name">{request.NomeLoja}</div>
        </div>
        <button
          type="button"
          onClick={() => setRequestStatusOpen(true)}
          disabled={!Boolean(events.find((ev) => ev.EVENTO === 'CONFIRMED'))}
        >Acompanhar</button>
      </div>
      <div className="request_info">
        <div className="status">
          <img src={requestInfo[0]} alt="Ícone do pedido" />
          <p>{requestInfo[1]}</p>
        </div>
        <div className="description">
          <strong>{request.Tipo}</strong>
          <p><strong>Pedido:</strong> {request.CdPed}</p>
          <p><strong>Valor:</strong> <span className="price">R$ {request.ValorFechamento.toFixed(2)}</span></p>
          <p><strong>Forma:</strong> <span className="method">{request.FormaBrand}</span></p>
          <p><strong>Data:</strong> {date} {hour}</p>
          {
            items.map((item: any) => (
              item.Sabores.map((taste: cardapioItem) => (
                <p key={taste.Codigo}><strong>({item.Quantidade}) {capitalizeEachWord(taste.Nome)}</strong></p>
              ))
            ))
          }
        </div>
      </div>
      <div className="action_buttons">
        <button
          type="button"
          disabled={!Boolean(currentRequestStore)}
          onClick={() => {
            const type = request.Tipo === 'RETIRADA' ? 'withdraw' : request.Tipo === 'ENTREGA' ? 'delivery' : 'drive'

            handleRepeatRequest(currentRequestStore, request.Itens, type)
          }}
        >Repetir Pedido</button>
        <button
          type="button"
          onClick={() => setOrderEvaluationOpen(true)}
          disabled={!Boolean(parsedStoreEvaluation && storeLicense)}
        >Avaliação</button>
      </div>
      {
        requestStatusOpen && (
          <RequestStatus
            events={events}
            request={request}
            openOrderEvaluation={() => setOrderEvaluationOpen(true)}
            close={() => setRequestStatusOpen(false)}
          />
        )
      }
      {
        orderEvaluationOpen && parsedStoreEvaluation && storeLicense && (
          <OrderEvaluation
            request={request}
            parameters={parsedStoreEvaluation}
            license={storeLicense}
            close={() => setOrderEvaluationOpen(false)}
          />
        )
      }
      {
        error && (
          <OverlayNotification text={error} close={() => setError('')} />
        )
      }
    </RequestCardContainer>
  )
}
export default RequestCard
