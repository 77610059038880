import styled from 'styled-components'

type WarningProps = {
  text: string,
  btnText: string,
  action: () => void
}

const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  height: 100%;

  margin-top: 18rem;
  padding: 0 2rem;

  p, button {
    font-size: 1.4rem;
    font-weight: 700;
  }

  p {
    margin-bottom: 3rem;
  }

  button {
    background: #ff0000;
    border-radius: 2rem;
    color: #ffffff;

    padding: .8rem 1.4rem;
  }
`

function Warning ({ text, btnText, action }: WarningProps) {
  return (
    <WarningContainer>
      <p>{text}</p>
      <button
        type="button"
        onClick={action}
      >{btnText}</button>
    </WarningContainer>
  )
}

export default Warning
