import { Route, Routes } from 'react-router-dom'
import Address from '../pages/Address'
import EditProfile from '../pages/Profile/components/EditProfile'
import Home from '../pages/Home'
import Profile from '../pages/Profile'
import Requests from '../pages/Requests'
import RestorePassword from '../pages/RestorePassword'
import Sign from '../pages/Sign'
import AddressProtectedRoute from './AddressProtectedRoute'
import InitialPageRouteWrapper from './InitialPageRouteWrapper'
import ModifyPassword from '../pages/Profile/components/ModifyPassword'
import LoginProtectedRoute from './LoginProtectedRoute'
import Loja from '../pages/Loja'
import ProductDetails from '../pages/ProductDetails'
import SearchProducts from '../pages/SearchProducts'
import Cardapio from '../pages/Cardapio'
import Info from '../pages/Info'
import Cart from '../pages/Cart'

function Router() {
  return (
    <Routes>
      <Route path="/" element={<AddressProtectedRoute />}>
        <Route path="/" element={<InitialPageRouteWrapper />}>
          <Route path="/" element={<Home />} />
          <Route path="/perfil" element={<Profile />} />
          <Route path="/pedidos" element={<Requests />} />
        </Route>
        <Route path='/loja' element={<Loja />}>
          <Route path="cardapio" element={<Cardapio />} />
          <Route path="informacoes" element={<Info />} />
        </Route>
        <Route path='/pesquisa' element={<SearchProducts />} />
        <Route path='/loja/cardapio/:codigoProduto' element={<ProductDetails />} />
        <Route path='/' element={<LoginProtectedRoute />}>
          <Route path='/carrinho/entrega' element={<Cart currentCart="delivery" />} />
          <Route path='/carrinho/retirada' element={<Cart currentCart="withdraw" />} />
          <Route path='/carrinho/drive' element={<Cart currentCart="drive" />} />
          <Route path='/pedidos' element={<Requests />} />
        </Route>
      </Route>
      <Route path="/restaurarSenha" element={<RestorePassword />} />
      <Route path="/login" element={<Sign />} />
      <Route path="/perfil/editar" element={<EditProfile />} />
      <Route path="/perfil/senha" element={<ModifyPassword />} />
      <Route path="/endereco" element={<Address />} />
    </Routes>
  )
}

export default Router