import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { cleanNotification } from '../../store/features/notification/notificationSlice'
import { NotificationContainer } from './styles'

type NotificationProps = {
  text: string,
  btnText?: string,
  action?: () => void
}

function Notification({ text, btnText, action }: NotificationProps) {
  const notificationRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      notificationRef.current?.classList.add('going_out')
    }, 2000)

    setTimeout(() => {
      dispatch(cleanNotification())
    }, 2500)
  }, [dispatch])

  return (
    <NotificationContainer ref={notificationRef}>
      <p>{text}</p>
      {
        btnText && action && (
          <button onClick={action}>{btnText}</button>
        )
      }
    </NotificationContainer>
  )
}
export default Notification
