import { ChevronLeft, MapPin, Trash } from 'lucide-react'
import { useState } from 'react'
import { Address } from '../../../types/address'

type AddressItemProps = {
  address: Address,
  deleteAddress: () => void,
  isActive: boolean
}

function AddressItem({ address, deleteAddress, isActive }: AddressItemProps) {
  const [isRemoveAddressButtonOpen, setIsRemoveAddressButtonOpen] = useState<boolean>(false)

  function toggleRemoveAddressButton () {
    setIsRemoveAddressButtonOpen((prevState) => !prevState)
  }

  function handleRemoveItem () {
    deleteAddress()
    setIsRemoveAddressButtonOpen(false)
  }

  return (
    <div className={`${isRemoveAddressButtonOpen && 'open'}`}>
      <div
        className={`main_part ${isActive && 'active'}`}
        onMouseDown={toggleRemoveAddressButton}
        onTouchStart={toggleRemoveAddressButton}
      >
        <div className="icon">
          <MapPin />
        </div>
        <div className="address_info">
          {address?.Cep && <p>CEP: {address.Cep}</p>}
          <p>
            {address.Log.toUpperCase()}
            {address.Numero && `, ${address.Numero}`}
            {address.Bai.d && ` - ${address.Bai.d}`}
          </p>
          {
            address.Cid && (
              <p className="city">{address.Cid.d}{address.Uf && `/${address.Uf}`}</p>
            )
          }
        </div>
        <button type="button">
          <ChevronLeft />
        </button>
      </div>
      <div className="remove_address_part">
        <button onClick={handleRemoveItem}>
          <Trash />
          <span>Excluir</span>
        </button>
      </div>
    </div>
  )
}

export default AddressItem