import axios from 'axios'
import { Address } from '../../../types/address'

async function getStoreInfo (token: string, domain: string) {
  const env = process.env.REACT_APP_STORE_INFO

  if (!domain || !env || !token) return

  const response = await axios({
    method: 'GET',
    url: `${domain}/${env}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

async function getStoreParams (token: string, body: Address, storeId: string, domain: string) {
  const env = process.env.REACT_APP_STORE_PARAMS

  if (!domain || !storeId || !token || !env || !body) return

  const response = await axios({
    method: 'POST',
    url: `${domain}/${env}/${storeId}`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: body
  })

  return response
}

export const infoService = {
  getStoreInfo, getStoreParams
}
