import { ProfileHeaderContainer } from '../styles'

import { ArrowLeft } from 'lucide-react'
import { Link } from 'react-router-dom'
import Logo from '../../../components/Logo'

type ProfileHeaderProps = {
  userName: string
}

function ProfileHeader({ userName }: ProfileHeaderProps) {
  return (
    <ProfileHeaderContainer>
      <div className="page_context">
        <div className="icon_wrapper">
          <Logo />
        </div>
        <p>Olá <strong>{userName}</strong></p>
        <Link to="/">
          <ArrowLeft size={28} />
        </Link>
      </div>
    </ProfileHeaderContainer>
  )
}

export default ProfileHeader
