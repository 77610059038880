/* eslint-disable react-hooks/exhaustive-deps */

import { Minus, Plus, Trash2 } from 'lucide-react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeItemQtd, removeItem } from '../../../store/features/cart/cartSlice'
import { RootState } from '../../../store/store'
import { Size } from '../../../types/cardapioItem'
import { ItemOnCart } from '../../../types/cart'
import { capitalizeEachWord } from '../../../utils/capitalizeEachWord'
import { CartItemCard } from '../styles'

type ItemCardProps = {
  item: ItemOnCart,
  recalculate: () => void
}

function ItemCard ({ item, recalculate }: ItemCardProps) {
  const { type } = useSelector((state: RootState) => state.cardapio)
  const { storeParams } = useSelector((state: RootState) => state.token)

  const dispatch = useDispatch()

  const currentCart: 'withdraw' | 'delivery' | 'drive' =
    type === 'retirada'
      ? 'withdraw'
      : type === 'entrega'
        ? 'delivery'
        : 'drive'

  function handleDeleteItem () {
    const removeItemParams = {
      cart: currentCart,
      id: item.id
    }

    dispatch(removeItem(removeItemParams))
  }

  function handleIncreaseItemQuantity () {
    const changeItemQtdParams = {
      cart: currentCart,
      id: item.id,
      type: 'plus' as 'plus'
    }

    dispatch(changeItemQtd(changeItemQtdParams))
  }

  function handleDecreaseItemQuantity () {
    if (item.Quantidade <= 1) {
      handleDeleteItem()
    }

    const changeItemQtdParams = {
      cart: currentCart,
      id: item.id,
      type: 'minus' as 'minus'
    }

    dispatch(changeItemQtd(changeItemQtdParams))
  }

  useEffect(() => {
    recalculate()
  }, [item.Quantidade, recalculate])

  const basePrice = item.Sabores.length > 1 ? item.additionalInfo.Preco : item.Tamanho.Preco

  const selectedSizes: Size[] = item.Sabores.map((tastes) => tastes.Tamanhos[0])

  const additionalsPrice =
    selectedSizes.reduce((prev, curr) => prev + (
      curr.Adicionais.reduce(
        (prev, curr) => prev + (curr.Checked ? (item.Sabores.length > 1 ? curr.PrecoMeioMeio : curr.PrecoCheio) : 0),
        0
      )
    ), 0) + (item.Borda ? item.Borda.Preco : 0) + (item.TipoMassa ? item.TipoMassa.Preco : 0)

  const complementsPrice =
    selectedSizes[0]?.Complementos.reduce((prev, curr) => prev + (
      curr.Itens.reduce((prev, curr) => prev + (curr.Preco * curr.Quantidade), 0)
    ), 0) || 0

  const additionalPrice = additionalsPrice + complementsPrice

  const fullDiscountPrice = (additionalPrice + item.Tamanho.PrecoReferencia) * item.Quantidade
  const fullPrice = ((additionalPrice + basePrice) * item.Quantidade) - (item.ValorDesconto || 0)

  return (
    <CartItemCard background={`${storeParams.item}/${item.Sabores[0].Codigo}.jpg`}>
      <div className="cart_item_header">
        <button type="button" onClick={handleDeleteItem}>
          <Trash2 size={24} color="#ff0000" />
        </button>
        <p>{item.Sabores[0].Nome}{item.Sabores[1] && ` / ${item.Sabores[1].Nome}`}</p>
        <div className="image" />
      </div>

      <div className="cart_item_details">
        <div className="item_size">
          <span className="detail_name">Tamanho: </span>
          <span className="prop_name">{item.Tamanho.Nome}</span>
          <span className="prop_price">R$ {basePrice.toFixed(2)}</span>
        </div>

        {
          item.TipoMassa && (
            <div>
              <span className="detail_name">Massa: </span>
              <div className="item">
                <span className="prop_qtd">{item.TipoMassa.Quantidade}</span>
                <span className="prop_name">{capitalizeEachWord(item.TipoMassa.Nome)}</span>
                <span className="prop_price">R$ {item.TipoMassa.Preco.toFixed(2)}</span>
              </div>
            </div>
          )
        }

        {
          item.Borda && (
            <div>
              <span className="detail_name">Borda: </span>
              <div className="item">
                <span className="prop_qtd">{item.Borda.Quantidade}</span>
                <span className="prop_name">{capitalizeEachWord(item.Borda.Nome)}</span>
                <span className="prop_price">R$ {item.Borda.Preco.toFixed(2)}</span>
              </div>
            </div>
          )
        }

        {
          selectedSizes[0].Complementos.length > 0 &&
          selectedSizes[0].Complementos.map((complement) =>
            complement.Itens.find((complement) => complement.Quantidade > 0) &&
          (
            <div key={complement.Nome}>
              <span className="detail_name">{capitalizeEachWord(complement.Nome)}: </span>
              {
                complement.Itens.map((item) => item.Quantidade > 0 && (
                  <div key={item.Nome} className="item">
                    <span className="prop_qtd">{item.Quantidade}</span>
                    <span className="prop_name">
                      {capitalizeEachWord(item.Nome)}
                    </span>
                    {item.Preco > 0 && (
                      <span className="prop_price">R$ {item.Preco.toFixed(2)}</span>
                    )}
                  </div>
                ))
              }
            </div>
          ))
        }

        {
          item.Sabores.find((taste) => taste.Ingredientes.find((ingredient) => !ingredient.Checked)) && (
            <div className="item_ingredients">
              <p className="detail_name">Ingredientes:</p>
              {
                item.Sabores.map((taste) => taste.Ingredientes.find((ingredient) => !ingredient.Checked) && (
                  <div>
                    <span className="heading">{capitalizeEachWord(taste.Nome)}: </span>
                    <div className="ingredients_list">
                      {
                        taste.Ingredientes.map((ingredient) => !ingredient.Checked && (
                          <span className="ingredient_detail item">
                            <span className="prop_price">(SEM) </span>
                            {capitalizeEachWord(ingredient.Nome)}
                          </span>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }

        {
          ((selectedSizes[0].Adicionais.find((add) => add.Checked)) ||
          (selectedSizes[1]?.Adicionais.find((add) => add.Checked))) && (
            <div className="item_additionals">
              <p className="detail_name">Adicionais:</p>
              {
                selectedSizes.map((size, index) => (
                  <div className="add_group" key={index}>
                    {
                      size.Adicionais.find((add) => add.Checked) &&
                        <span className="heading">{capitalizeEachWord(item.Sabores[index].Nome)}: </span>
                    }
                    {
                      size.Adicionais.map((add) => add.Checked && (
                        <div key={add.Descricao} className="item">
                          <span className="prop_name">{capitalizeEachWord(add.Descricao)}</span>
                          <span className="prop_price">
                            + R$ {(item.Sabores.length > 1 ? add.PrecoMeioMeio : add.PrecoCheio).toFixed(2)}
                          </span>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          )
        }

        {
          item.Observacao && (
            <div className="item_observations">
              <span className="detail_name">Observação: </span>
              <span className="prop_name">{item.Observacao}</span>
            </div>
          )
        }
      </div>

      <div className="cart_item_footer">
        <div className="qtd_actions">
          <button type="button" onClick={handleDecreaseItemQuantity}>
            <Minus size={24} color="#990000" />
          </button>
          <span>{item.Quantidade}</span>
          <button type="button" onClick={handleIncreaseItemQuantity}>
            <Plus size={24} color="#0858bb" />
          </button>
        </div>
        <div className="pricing">
          {fullDiscountPrice > fullPrice && (
            <span className="discount">R$ {(fullDiscountPrice).toFixed(2)}</span>
          )}
          <span className="price">R$ {(fullPrice).toFixed(2)}</span>
        </div>
      </div>
    </CartItemCard>
  )
}
export default ItemCard
