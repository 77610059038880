import axios from 'axios'
import { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AgilePageLayout from '../../../components/AgilePageLayout'
import Field from '../../../components/Field'
import OverlayNotification from '../../../components/OverlayNotification'
import RedButton from '../../../components/RedButton'
import ScaleLoader from '../../../components/ScaleLoader'
import useFormError from '../../../hooks/useFormError'
import { loginWithRefreshToken } from '../../../store/features/user/userSlice'
import { AppDispatch, RootState } from '../../../store/store'
import { validateEmptyField } from '../../../utils/fieldValidations'
import { cpfMask, phoneNumberMask } from '../../../utils/masks'
import { onlyNumbers } from '../../../utils/onlyNumbers'

function EditProfile() {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const { userData, userId, accessToken, refreshToken } = useSelector((state: RootState) => state.user)
  const { generalLink } = useSelector((state: RootState) => state.token)

  const [name, setName] = useState<string>(userData.CompleteUser.Usuario.Nome || '')
  const [nickname, setNickname] = useState<string>(userData.CompleteUser.Usuario.Apelido || '')
  const [phone, setPhone] = useState<string>(userData.CompleteUser.Usuario.Telefone || '')
  const [cpf, setCpf] = useState<string>(userData.CompleteUser.Usuario.CPF || '')

  const [isDataUpdating, setIsDataUpdating] = useState<boolean>(false)

  const [error, setError] = useState<string>('')

  const [
    setNewError,
    getErrorByFieldname,
    cleanErrorsByFieldname
  ] = useFormError()

  function validateFields () {
    const isNameFieldValid = validateEmptyField(
      name,
      'name',
      'Nome completo é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    return (isNameFieldValid)
  }

  async function handleUpdateData () {
    const env = process.env.REACT_APP_EDIT_ACCOUNT_DATA

    if (!validateFields() || !env || !userId) return

    const updateDataBody = {
      Id: userId,
      Login: userData.email,
      Nome: name || null,
      Apelido: nickname || null,
      Telefone: onlyNumbers(phone) || null,
      CPF: onlyNumbers(cpf) || null,
      Ativo: true,
      FotoUri: userData.CompleteUser.Usuario.FotoUri || null,
      Enderecos: userData.CompleteUser.Usuario.Enderecos
    }

    setIsDataUpdating(true)

    try {
      await axios({
        method: 'PUT',
        url: `${generalLink}/${env}/${userId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: updateDataBody
      })

      const loginWithRefreshTokenParams = {
        token: refreshToken,
        domain: generalLink
      }

      setIsDataUpdating(false)
      dispatch(loginWithRefreshToken(loginWithRefreshTokenParams))
      navigate('/perfil')
    } catch (err: any) {
      let message

      if (err?.response?.status === 500) {
        message = 'Algum erro ocorreu no sistema, tente novamente mais tarde'
      } else {
        message = err?.response?.data?.Messages?.[0]
      }
      
      setError(message)
      setIsDataUpdating(false)
    }
  }

  function handlePhoneChange (value: string) {
    if (value.length > 15) return

    setPhone(value)
  }

  function handleCpfChange (value: string) {
    if (value.length >= 15) return

    setCpf(value)
  }

  const nameFieldProps = {
    value: name,
    placeholder: 'Nome completo...',
    error: getErrorByFieldname('name'),
    change: (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value),
    clear: () => setName('')
  }

  const nicknameFieldProps = {
    value: nickname,
    placeholder: 'Apelido...',
    change: (e: ChangeEvent<HTMLInputElement>) => setNickname(e.target.value),
    clear: () => setNickname('')
  }

  const phoneFieldProps = {
    value: phoneNumberMask(phone),
    placeholder: 'Telefone...',
    change: (e: ChangeEvent<HTMLInputElement>) => handlePhoneChange(e.target.value),
    clear: () => setPhone('')
  }

  const cpfFieldProps = {
    value: cpfMask(cpf),
    placeholder: 'CPF...',
    change: (e: ChangeEvent<HTMLInputElement>) => handleCpfChange(e.target.value),
    clear: () => setCpf('')
  }

  return (
    <AgilePageLayout
      goBack={() => navigate('/perfil')}
      useProfileHeader
    >
      <div>
        <Field fieldProps={nameFieldProps} />
        <Field fieldProps={nicknameFieldProps} />
        <Field fieldProps={phoneFieldProps} />
        <Field fieldProps={cpfFieldProps} />
        <RedButton
          type="button"
          onClick={handleUpdateData}
        >
          Alterar
        </RedButton>
      </div>
      {
        isDataUpdating && (
          <ScaleLoader />
        )
      }
      {
        error && (
          <OverlayNotification text={error} close={() => setError('')} />
        )
      }
    </AgilePageLayout>
  )
}
export default EditProfile