import styled, { keyframes } from 'styled-components'

const goingIn = keyframes`
  from {
    margin-top: -100%;
  }

  to {
    margin-top: 0;
  }
`

const goingOut = keyframes`
  from {
    margin-top: 0;
  }

  to {
    margin-top: -100%;
  }
`

const MobileMenuContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary.normal};
  
  width: 100%;

  animation: ${goingIn} .25s ease-out 1 backwards;

  &.going_out {
    animation-name: ${goingOut};
  }

  .heading {
    display: flex;
    justify-content: center;

    position: relative;

    padding: 1rem;

    .logo {
      width: 10rem;

      img {
        width: 100%;
      }
    }

    button {
      position: absolute;

      color: #ffffff;

      right: 1.6rem;
      top: 1.6rem;
    }
  }

  .content_wrapper {
    width: 8rem;

    display: flex;
    align-items: center;
  }

  a {
    display: flex;
    justify-content: center;

    color: #ffffff;
    font-weight: 800;

    padding: .8rem;

    border-top: 1px solid #ffffff;

    svg {
      margin-right: .4rem;
    }
  }
`

export {
  MobileMenuContainer
}