import axios from 'axios'
import { Star } from 'lucide-react'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'

import AgilePageLayout from '../../../components/AgilePageLayout'
import OverlayNotification from '../../../components/OverlayNotification'
import ScaleLoader from '../../../components/ScaleLoader'
import { RootState } from '../../../store/store'
import { Request } from '../../../types/requests'
import { OrderEvaluationContainer } from '../styles'
import ActionButtons from './ActionButtons'

type OrderEvaluationProps = {
  request: Request,
  parameters: string[],
  license: string,
  close: () => void
}

function OrderEvaluation({ request, parameters, license, close }: OrderEvaluationProps) {
  const orderEvaluationPortal = document.getElementById('order_evaluation_portal')

  const { generalLink } = useSelector((state: RootState) => state.token)
  const { userData } = useSelector((state: RootState) => state.user)

  const [evaluations, setEvaluations] = useState(() => parameters.map((param) => ({
    name: param,
    rate: 0
  })))

  const [userSuggestion, setUserSuggestion] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [success, setSuccess] = useState<string>('')

  if (!orderEvaluationPortal) return null

  function validateOrderEvaluation () {
    if (evaluations.find((evaluation) => evaluation.rate === 0)) {
      return false
    }

    return true
  }

  async function handleSendOrderEvaluation () {
    if (!validateOrderEvaluation()) {
      setMessage('Forneça todas as notas antes de enviar sua avaliação.')
      return
    }

    setLoading(true)

    const json = JSON.stringify({
      mensagem: userSuggestion,
      items: evaluations
    })

    const evaluationBody = {
      userName: userData.email,
      codigo: request.Id,
      json
    }

    let storeLicense

    try {
      const getLicenseBody = {
        userName: process.env.REACT_APP_STORE_TOKEN_USERNAME,
        password: license,
        versaoApi: process.env.REACT_APP_STORE_TOKEN_VERSION
      }

      const response: any = await axios({
        method: 'POST',
        data: getLicenseBody,
        url: process.env.REACT_APP_TOKEN
      })

      storeLicense = response.data.access_token
    } catch (err) {
      setLoading(false)
      setError('A loja está indisponível no momento, tente novamente mais tarde.')
    }

    if (!storeLicense) return

    try {
      await axios({
        method: 'POST',
        url: `${generalLink}/${process.env.REACT_APP_EVALUATION}`,
        data: evaluationBody,
        headers: {
          Authorization: `Bearer ${storeLicense}`
        }
      })

      setLoading(false)
      setSuccess('Avaliação enviada com sucesso, muito obrigado!')
    } catch (err: any) {
      setLoading(false)
      setError('Algum erro ocorreu durante o envio da sua avaliação, por favor, tente mais tarde.')
    }
  }

  return ReactDOM.createPortal((
    <OrderEvaluationContainer>
      <AgilePageLayout goBack={close}>
        <div className="content_wrapper">
          <div className="heading">
            <h2>Avalie seu Pedido</h2>
            <p>Seu pedido foi entregue e você pode avaliar o restaurante agora.</p>
            <p>Conte-nos como foi sua experiência.</p>
          </div>
          <div className="evaluation">
            <h2>Sua Avaliação</h2>
            <div className="evaluation_list">
              {
                evaluations.map((evaluation) => (
                  <div className="evaluation_group">
                    <p>{evaluation.name}</p>
                    <div className="evaluation_rating">
                      {
                        [1, 2, 3, 4, 5].map((pos) => (
                          <button
                            type="button"
                            className="star"
                            key={pos}
                            onClick={() => {
                              setEvaluations((prevState) => prevState.map((ev) => 
                                ev.name === evaluation.name ? {
                                  ...ev,
                                  rate: pos
                                } : ev
                              ))
                            }}
                          >
                            <Star
                              color={(evaluation.rate >= pos) ? '#fc0' : '#aaaaaa'}
                              fill={(evaluation.rate >= pos) ? '#fc0' : '#aaaaaa'}
                            />
                          </button>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="commentary">
            <h2>Algum comentário?</h2>
            <textarea
              placeholder="Deixe um comentário ou uma sugestão" rows={4}
              value={userSuggestion}
              onChange={(e) => setUserSuggestion(e.target.value)}
            ></textarea>
          </div>
          <ActionButtons
            close={close}
            action={handleSendOrderEvaluation}
            closeText="Voltar"
            actionText="Enviar avaliação"
          />
        </div>
      </AgilePageLayout>
      {
        message && (
          <OverlayNotification text={message} close={() => setMessage('')} />
        )
      }
      {
        loading && (
          <ScaleLoader />
        )
      }
      {
        (error || success) && (
          <OverlayNotification text={error || success} close={() => close()} />
        )
      }
    </OrderEvaluationContainer>
  ), orderEvaluationPortal)
}
export default OrderEvaluation