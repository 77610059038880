import ReactDOM from 'react-dom'

import { Compass, ShoppingBag, User, X } from 'lucide-react'

import { MobileMenuContainer } from './styles'
import { NavLink } from 'react-router-dom'
import Overlay from '../Overlay'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import Logo from '../Logo'

type MobileMenuProps = {
  handleClose: () => void,
  goingOut: boolean
}

function MobileMenu({ handleClose, goingOut }: MobileMenuProps) {
  const MobileMenuPortal = document.getElementById('mobile_menu_portal')

  const { userData } = useSelector((state: RootState) => state.user)

  if (!MobileMenuPortal) return null

  return ReactDOM.createPortal((
    <Overlay position="top" onClick={handleClose}>
      <MobileMenuContainer className={ goingOut ? 'going_out' : undefined }>
        <div className="heading">
          <div className="logo">
            <Logo />
          </div>
          <button className="close" onClick={handleClose}>
            <X />
          </button>
        </div>
        <ul>
          <li>
            <NavLink to="/">
              <div className="content_wrapper">
                <Compass />
                Explorar
              </div>
            </NavLink>
          </li>
          {
            userData.CompleteUser && (
              <>
                <li>
                  <NavLink to="/pedidos">
                    <div className="content_wrapper">
                      <ShoppingBag />
                      Pedidos
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/perfil">
                    <div className="content_wrapper">
                      <User />
                      Perfil
                    </div>
                  </NavLink>
                </li>
              </>
            )
          }
        </ul>
      </MobileMenuContainer>
    </Overlay>
  ), MobileMenuPortal)
}

export default MobileMenu