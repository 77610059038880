import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { ItemCardapio } from '../../types/cardapio'
import { capitalizeEachWord } from '../../utils/capitalizeEachWord'
import { CategoryItemWrapper } from './styles'

import itemImageError from '../../assets/icons/image-error.png'
import { useState } from 'react'

type CategoryItemCardProps = {
  item: ItemCardapio,
  navigateToItem: (code: number) => void,
  chefSuggestion: boolean
}

function CategoryItemCard(
  {
    item,
    navigateToItem,
    chefSuggestion
  }: CategoryItemCardProps) {
  const { storeParams } = useSelector((state: RootState) => state.token)

  const [imageError, setImageError] = useState<boolean>(false)

  return (
    <CategoryItemWrapper onClick={() => navigateToItem(item.Codigo)} key={item.Codigo} chef={chefSuggestion}>
      <div className={`image ${imageError && 'image_with_error'}`}>
        <LazyLoadImage src={!imageError ? `${storeParams.item}/${item.Codigo}.jpg` : itemImageError} onError={() => setImageError(true)} />
      </div>
      <div className="info">
        <div className="names">
          <strong className="title">{capitalizeEachWord(item.Nome)}</strong>
          <p className="description">{item.ReceitaCurta}</p>
        </div>
        <div className="pricing">
          {
            !chefSuggestion
              ? (
                <>
                  <span className={`old ${item.PrecoReferencia !== item.ValorComDesconto ? 'risked' : undefined}`}>De: <span className="amplified">R$ {item.PrecoReferencia.toFixed(2)}</span></span>
                  {item.PrecoReferencia !== item.ValorComDesconto && <span className="new">Por: <span className="amplified">R$ {item.ValorComDesconto.toFixed(2)}</span></span>}
                </>
              )
              : (
                <>
                  <span className='old amplified'>R$ {item.ValorComDesconto.toFixed(2)}</span>
                </>
              )
          }
        </div>
      </div>
    </CategoryItemWrapper>
  )
}

export default CategoryItemCard
