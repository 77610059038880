import styled from 'styled-components';

export const AccountActionContainer = styled.div`
  background: #ffffff;

  margin-bottom: 6rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .privacy_link {
    margin-bottom: 1rem;

    a {
      color: #ff0000;
      font-style: italic;
    }
  }

  button {
    background: ${({ theme }) => theme.colors.primary.normal};
    border-radius: 3rem;
    color: #ffffff;

    width: 95%;

    font-weight: 700;

    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 1rem;
    }
  }
`

export const ProfileHeaderContainer = styled.header`
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    padding: 2.6rem;
  }

  a {
    position: absolute;

    top: 2rem;
    left: 2rem;

    color: #ffffff;
  }

  .icon_wrapper {
    width: 180px;

    margin-bottom: 2.4rem;

    img {
      width: 100%;
    }
  }

  p {
    color: #ffffff;
    font-size: 1.5rem;
  }
`

export const EditSectionContainer = styled.div`
  background: #ffffff;

  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem;

  > div {
    display: flex;
    justify-content: space-around;
  }

  button {
    color: ${({ theme }) => theme.colors.primary.normal};

    display: flex;
    align-items: center;

    font-size: 1.2rem;
    font-weight: 700;

    padding: 1rem 0;

    svg {
      margin-right: .6rem;
    }
  }
`

export const AddressListContainer = styled.div`
  background: #ffffff;
  color: ${({ theme }) => theme.colors.primary.normal};

  padding: 2rem;

  .address_list_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 2rem;

    h2 {
      font-size: 1.3rem;
    }

    button {
      color: ${({ theme }) => theme.colors.primary.normal};
      font-size: 1.1rem;
      font-weight: 700;

      display: flex;
      align-items: center;
    }

    svg {
      margin-right: .4rem;
    }
  }

  .listing {
    > div {
      border-radius: .3rem;

      position: relative;

      width: 100%;
      margin-bottom: 2rem;

      overflow-x: hidden;

      &.open {
        .main_part {
          right: 14rem;
        }
      }

      .main_part {
        background: #f0f0f0;
        border: 2px solid #f0f0f0;

        position: relative;
        right: 0;
        display: flex;

        font-size: 1.2rem;
        padding: 1rem;

        width: 100%;

        transition: right .2s;
        z-index: 500;

        button {
          color: #777777;
        }

        &.active {
          border: 2px solid #ff0000;
        }
      }

      .remove_address_part {
        position: absolute;
        height: 100%;

        width: 14rem;

        top: 0;
        right: 0;

        button {
          background: #ff0000;
          color: #ffffff;

          font-weight: 700;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          width: 100%;
          height: 100%;

          svg {
            margin-bottom: .6rem;
          }
        }
      }

      .address_info {
        color: #121212;
        text-align: left;

        flex: 1;

        p {
          font-weight: 700;

          &:nth-child(2) {
            margin: .4rem 0;
          }

          &.city {
            color: #777777;
          }
        }
      }

      .icon {
        color: ${({ theme }) => theme.colors.primary.normal};

        display: flex;
        align-items: center;

        margin-right: 1rem;
      }
    }
  }
`

export const ProfilePageContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary.normal};
`
