/* eslint-disable react-hooks/exhaustive-deps */

import { ArrowLeft } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ItemOptions from './components/ItemOptions'
import { clearHalfItem, getHalfItem, getItem, reset } from '../../store/features/item/itemSlice'
import { AppDispatch, RootState } from '../../store/store'
import { PDHeader } from './styles'
import OverlayNotification from '../../components/OverlayNotification'
import PDHeaderItem from './components/PDHeaderItem'
import { ItemCardapio } from '../../types/cardapio'
import ImageModal from './components/ImageModal'

function ProductDetails () {
  const { generalLink, storeToken, storeParams } = useSelector((state: RootState) => state.token)
  const { type } = useSelector((state: RootState) => state.cardapio)
  const { item, halfItem, isLoading, isError } = useSelector((state: RootState) => state.item)

  const [imageModal, setImageModal] = useState<string>('')

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { codigoProduto } = useParams()

  useEffect(() => {
    if (!codigoProduto || !storeToken.access_token) return

    const getItemArgs = {
      type: type,
      token: storeToken.access_token,
      code: codigoProduto,
      domain: generalLink
    }

    dispatch(getItem(getItemArgs))
  }, [codigoProduto, storeToken.access_token, type, generalLink])

  function handleGoBackButton () {
    navigate('/loja/cardapio')
  }

  const changeHalfItem = useCallback((item: ItemCardapio | undefined) => {
    if (!item) {
      dispatch(clearHalfItem())
      return
    }

    const getHalfItemProps = {
      type,
      code: item.Codigo,
      token: storeToken.access_token,
      domain: storeParams.api
    }

    dispatch(getHalfItem(getHalfItemProps))
  }, [])

  function removeHalfItem () {
    dispatch(clearHalfItem())
  }

  function closeErrorModal () {
    dispatch(reset())

    navigate('/loja/cardapio')
  }

  return (
    <div>
      <PDHeader>
        <div className="item_description">
          <div className="pdh_container page_context">
            <button onClick={handleGoBackButton}>
              <ArrowLeft size={24} color="#ffffff" />
            </button>
            {
              halfItem && (
                <button className="remove_option_btn" onClick={removeHalfItem}>
                  Trocar
                </button>
              )
            }
          </div>
          <div className="desc_group page_context">
            <div
              className="item_description"
              onClick={() => setImageModal(`${storeParams.item}/${codigoProduto}.jpg`)}
            >
              <h2>{!isLoading && item?.Nome}</h2>
              <p>{!isLoading && item?.ReceitaCurta}</p>
            </div>
            {
              halfItem && (
                <div
                  className="item_description"
                  onClick={() => setImageModal(`${storeParams.item}/${halfItem.Codigo}.jpg`)}
                >
                  <h2>{halfItem?.Nome}</h2>
                  <p>{halfItem?.ReceitaCurta}</p>
                </div>
              )
            }
          </div>
          <div className="items">
            <PDHeaderItem
              background={`${storeParams.item}/${codigoProduto}.jpg`}
              action={(image: string) => setImageModal(image)}
            />
            {
              halfItem && (
                <PDHeaderItem
                  background={`${storeParams.item}/${halfItem.Codigo}.jpg`}
                  action={(image: string) => setImageModal(image)}
                />
              )
            }
          </div>
        </div>
      </PDHeader>
      {
        imageModal && (
          <ImageModal
            close={() => setImageModal('')}
            image={imageModal}
          />
        )
      }
      {
        item?.Tamanhos && (
          <ItemOptions
            setHalf={changeHalfItem}
            codigo={Number(codigoProduto)}
          />
        )
      }
      {
        isError && (
          <OverlayNotification
            text="Algum erro ocorreu na busca pelo item, tente novamente mais tarde."
            close={closeErrorModal}
          />
        )
      }
    </div>
  )
}

export default ProductDetails
