import { getDistance } from 'geolib'
import { Address } from '../types/address'

export default function getStoreDistance (firstStoreAddress: Address, secondStoreAddress: Address) {
  if (!secondStoreAddress?.Location || !firstStoreAddress?.Location) return 0

  const distance = getDistance(
    {
      latitude: firstStoreAddress.Location.Latitude,
      longitude: firstStoreAddress.Location.Longitude
    }, {
      latitude: secondStoreAddress.Location.Latitude,
      longitude: secondStoreAddress.Location.Longitude
    }
  )

  return Math.round(distance / 1000)
}
