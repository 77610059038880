import styled, { keyframes } from 'styled-components'

const bounce = keyframes`
  0%, 10%, 30%, 50% {  transform: translateY(50%); }

  20% { transform: translateY(calc(50% - 1rem)); }

  40% { transform: translateY(calc(50% - .5rem)); }
`

export const AddressSelectorContainer = styled.div`
  background: #ffffff;

  width: 100%;

  position: fixed;

  bottom: 0;
  left: 0;

  max-height: 40rem;

  display: flex;
  flex-direction: column;

  h3 {
    color: #464646;

    text-align: center;
    font-size: 1.4rem;

    padding: 1.6rem;
  }

  .options {
    flex: 1;

    overflow: auto;
  }

  label {
    display: flex;
    align-items: center;

    padding: .6rem 1.6rem;

    .address_description {
      margin-left: .8rem;

      font-size: 1.1rem;
    }
  }

  .add_new_address_button {
    color: #ff0000;
    font-weight: 700;

    display: flex;
    align-items: center;

    width: 100%;

    padding: 1.6rem;
  }
`

export const PageNavigationOverlay = styled.div`
  position: fixed;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background: #00000088;

  z-index: 1000;
`

export const PageNavigationContainer = styled.nav`
  background: #ffffff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  h3 {
    color: #464646;
    font-size: 1.8rem;
    text-align: center;

    padding: 1.2rem 0;
  }

  ul {
    li {
      display: flex;

      a {
        border-top: 1px solid #aaaaaa;

        color: #121212;
        font-size: 1.4rem;
        text-align: center;

        padding: .8rem 0;
        
        flex: 1;
      }

      &:last-child {
        a {
          color: #ff0000;

          display: flex;
          justify-content: center;
          align-items: center;

          padding: 1.4rem 0;

          svg {
            margin-right: 1.2rem;
          }
        }
      }
    }
  }
`

export const CategoriesContainer = styled.div`
  margin: 1rem 0;
  padding: 0 1.2rem 8rem;

  .menu_error_wrapper {
    text-align: center;

    font-size: 1.2rem;
    font-weight: 700;

    padding-top: 25rem;
  }
`

export const EstimateContainer = styled.div`
  padding: 0 .8rem;

  .estimate_info_box {
    border: 1px solid #ea0e4b;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1.2rem;

    position: relative;
  }

  p {
    font-size: 1.4rem;

    strong {
      margin-right: .8rem;
    }
  }

  .icon {
    position: absolute;

    top: 50%;
    left: 1.4rem;
    transform: translateY(-50%);
  }

  @media (max-width: 400px) {
    p {
      strong {
        margin-right: .4rem;
      }
    }

    .icon {
      display: none;
    }
  }
`

export const FooterMenuContainer = styled.footer`
  border-top: 1px solid #aaaaaa;
  
  position: fixed;
  left: 0;
  right: 0;
  bottom: -1px;

  margin: 0 auto;

  .cart_button_wrapper {
    position: absolute;
    top: -120%;
    left: 50%;

    transform: translateX(-50%);

    display: flex;
    justify-content: center;
  }

  .action_buttons {
    display: flex;

    box-shadow: 0px 0px 15px 10px rgba(0,0,0,.03);

    button {
      background: #ffffff;

      padding: 1rem;

      flex: 1;
    }
  }

  #cart_button {
    background: #ff0000;
    border-radius: 50%;

    display: flex;
    justify-content: center;

    padding: 1.6rem;

    transform: translateY(50%);

    animation-name: ${bounce};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;

    &:disabled {
      background: #cccccc;
    }
  }
`

export const CardapioContainer = styled.section`
  .catalog_types {
    display: flex;
    justify-content: space-around;

    margin: 1.6rem 0;

    button {
      background: #ffffff;
      border: 1px solid #888888;
      border-radius: 1.8rem;
      color: #888888;

      font-size: 1.4rem;

      padding: .8rem 2rem;

      box-shadow: 4px 4px 9px -8px rgba(0,0,0,0.52);

      &.active {
        background: #ea0e4b;
        border: 1px solid #ea0e4b;
        color: #ffffff;
      }
    }
  }

  #app_mode {
    color: #121212;
    font-size: 1.2rem;
    font-weight: 700;
    
    width: 95%;

    margin: 1rem auto 0;

    text-align: right;
  }
`
