import { ItemCardapio } from '../../../types/cardapio'
import Category from '../../../components/Category'
import { CategoriesContainer } from '../styles'

type CategoriesProps = {
  cardapio: ItemCardapio[],
  categories: string[],
  searchPage?: boolean
}

function Categories ({ cardapio, searchPage, categories }: CategoriesProps) {
  let sugestoesChefe: ItemCardapio[] = []

  for (const i of cardapio) {
    if (i.Chef === 'SIM') {
      sugestoesChefe = [...sugestoesChefe, i]
    }
  }

  return (
    <CategoriesContainer id="category_container">
      {
        sugestoesChefe.length > 0 && !searchPage && (
          <Category
            id="SUGESTÕES DO CHEFE"
            title="SUGESTÕES DO CHEFE"
            items={sugestoesChefe}
            chefSuggestion={true}
          />
        )
      }
      {
        categories.map((category) => {
          const categoryItems = cardapio.filter((item) => item.DescricaoCategoria === category)

          return (
            categoryItems.length > 0
              ? (
                  <Category
                    key={categoryItems[0].CodigoCategoria}
                    id={categoryItems[0].DescricaoCategoria}
                    title={categoryItems[0].DescricaoCategoria}
                    items={categoryItems}
                    chefSuggestion={false}
                  />
                )
              : null
          )
        })
      }
      {
        cardapio.length < 1 && (
          <div className="menu_error_wrapper">
            Não existem itens para essa modalidade.
          </div>
        )
      }
    </CategoriesContainer>
  )
}

export default Categories
