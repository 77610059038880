import styled from 'styled-components';

type StoreButtonProps = {
  iconColor: string
}

export const StoreSearchContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 500;

  .content {
    flex: 1;

    background: #ffffff;

    padding-top: 1rem;

    overflow: auto;
  }
`

export const ItemSliderContainer = styled.section`
  width: 100%;
  height: 27rem;
  
  margin: 0 auto 2rem;

  padding: 1rem .6rem;

  .slider_item {
    width: 96%;
    border-radius: 1rem;
    margin: 2rem 0;

    margin-bottom: 4rem;

    transition: transform .2s;

    img {
      border-radius: 1rem;

      width: 100%;
      height: 22rem;

      object-fit: cover;
      object-position: center;
    }

    &.active_item {
      transform: scale(1.1);
    }
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.primary.normal};
  }

  @media (max-width: 400px) {
    height: 25rem;

    .slider_item img {
      height: 20rem;
    }

    .slider_item.active_item {
      img {
        height: 21rem;
      }
    }
  }

  @media (max-width: 365px) {
    height: 23rem;

    .slider_item img {
      height: 18rem;
    }

    .slider_item.active_item {
      img {
        height: 19rem;
      }
    }
  }

  @media (max-width: 330px) {
    height: 21rem;

    .slider_item img {
      height: 16rem;
    }

    .slider_item.active_item {
      img {
        height: 17rem;
      }
    }
  }

  @media (max-width: 300px) {
    height: 19rem;

    .slider_item img {
      height: 14rem;
    }

    .slider_item.active_item {
      img {
        height: 15rem;
      }
    }
  }
`

export const CategoriesContainer = styled.div`
  margin: 0 auto 2rem;
  padding-bottom: 2rem;

  padding: 0 1rem 1rem;

  button {
    border: 2px solid transparent;
    border-radius: .6rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    margin-right: 2rem;
    padding: 1rem 1rem 0;

    z-index: 100;

    &.selected {
      border: 2px solid ${({ theme }) => theme.colors.primary.normal};

      img {
        filter: drop-shadow(0px 5px 5px #00000048);
      }
    }

    img {
      width: 6rem;

      margin-bottom: 1.4rem;

      z-index: 50;
    }

    span {
      color: ${({ theme }) =>  theme.colors.primary.normal};
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
`

export const StoreListContainer = styled.section`
  padding: 0 1rem;

  .store_location {
    color: ${({ theme }) => theme.colors.primary.normal};
    background: #e0e0e0;

    font-size: 1.3rem;

    display: block;

    padding: 1rem .6rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 680px) {
    .list {
      display: flex;
      flex-wrap: wrap;
    }
  }
`

export const StoreButtonContainer = styled.button<StoreButtonProps>`
  background: #ffffff;
  border-radius: .6rem;

  display: flex;

  position: relative;

  width: 100%;
  height: 12rem;

  text-align: left;

  margin-bottom: 2rem;

  transition: transform .18s ease-out;

  &:hover {
    transform: scale(1.03);
  }

  .distance {
    color: #ff0000;
    font-size: 1.3rem;
    font-weight: 700;

    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    padding: 0 1rem 1rem 0;
  }

  strong {
    display: block;

    margin-bottom: .8rem;
  }

  .icon {
    background: ${({ iconColor }) => `#${iconColor}`};

    border-top-right-radius: .6rem;
    border-top-left-radius: .6rem;

    width: 10rem;
    min-width: 10rem;
    height: 100%;
    min-height: 12rem;

    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;

      border-top-left-radius: .6rem;
      border-bottom-left-radius: .6rem;

      object-fit: contain;
      object-position: center;

      &.image_error {
        object-fit: contain;

        transform: scale(.8);

        opacity: .7;
      }
    }
  }

  .desc {
    flex: 1;
    padding: .6rem;
  }

  .address_info {
    color: #666666;

    display: flex;
    align-items: center;

    font-weight: 700;

    svg {
      margin-right: .4rem;
    }
  }

  @media (min-width: 680px) {
    flex-direction: column;
    height: initial;

    width: 45%;

    margin-right: 6rem;

    &:nth-child(2n) {
      margin-right: 0;
    }

    .icon {
      width: 100%;
      height: 10rem;

      img {
        border-top-right-radius: .6rem;
        border-bottom-left-radius: 0;
      }
    }

    .distance {
      width: 100%;
    }
  }

  @media (min-width: 860px) {
    flex-direction: column;
    height: initial;

    width: 30%;

    margin-right: 4rem;

    &:nth-child(2n) {
      margin-right: 4rem;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
`

export const SearchButtonElement = styled.button`
  background: #ffffff;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.primary.normal};

  display: flex;

  width: 100%;

  font-size: 1.4rem;

  padding: 1.6rem 1.2rem;
  margin-right: 4rem;

  svg {
    margin-right: 1.4rem;
  }

  p {
    font-weight: 800;
  }

  @media (max-width: 960px) {
    order: 1;

    margin-right: 0;
  }

  @media (max-width: 360px) {
    p {
      font-size: 1.3rem;
    }
  }

  @media (max-width: 312px) {
    p {
      font-size: 1.2rem;
    }
  }
`

export const HomeHeaderContainer = styled.header`
  background: ${({ theme }) => theme.colors.primary.normal};

  padding: 1.6rem 4rem;

  .content_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width: 8rem;
    min-width: 8rem;

    margin-right: 4rem;

    img {
      width: 100%;
    }
  }

  .profile_options {
    display: flex;
    align-items: center;

    width: 100%;
    max-width: fit-content;

    .hamburguer_button {
      display: none;
    }

    .links {
      display: flex;

      button {
        color: #ffffff;
        font-size: 1.6rem;

        &:first-child {
          margin-right: 3rem;
        }
      }
    }
  }

  @media (max-width: 960px) {
    padding: 1.6rem 2rem;

    .content_wrapper {
      flex-direction: column;
    }

    .logo {
      display: none;
    }

    .profile_options {
      order: 0;

      max-width: initial;

      display: flex;
      justify-content: space-between;

      margin: 1rem 0 2rem;
    }
  }

  @media (max-width: 520px) {
    .profile_options {
      .hamburguer_button {
        display: block;
      }

      .common_link {
        display: none;
      }
    }
  }
`

export const HomePageContainer = styled.div`
  .store_sections_wrapper {
    background: #f6f6f6;

    padding-top: 4rem;
  }
`
