import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import Notification from '../../components/Notification'
import { RootState } from '../../store/store'
import Header from './components/Header'

function Loja () {
  const navigate = useNavigate()

  const { type } = useSelector((state: RootState) => state.cardapio)
  const { notification } = useSelector((state: RootState) => state.notification)

  return (
    <main>
      <Header />
      <Outlet />
      {
        notification && (
          <Notification
            text={notification}
            btnText="Finalizar compra"
            action={() => navigate(`/carrinho/${type}`)}
          />
        )
      }
    </main>
  )
}

export default Loja
