/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import ScaleLoader from '../../components/ScaleLoader'
import { getAllStores, getGeneralParams, getStoresByCategory } from '../../store/features/stores/storesSlice'
import { AppDispatch, RootState } from '../../store/store'
import { Banner } from '../../types/banners'
import { Category } from '../../types/categories'
import AddressSelector from '../../components/AddressSelector'
import Categories from './components/Categories'
import HomeHeader from './components/HomeHeader'
import ItemSlider from './components/ItemSlider'
import StoreList from './components/StoreList'
import StoreSearch from './components/StoreSearch'
import { HomePageContainer } from './styles'
import { clear } from '../../store/features/cart/cartSlice'

function Home() {
  const { generalToken, generalLink } = useSelector((state: RootState) => state.token)
  const { stores, isLoading: isStoresLoading } = useSelector((state: RootState) => state.stores)

  const dispatch = useDispatch<AppDispatch>()

  const [isAddressSelectorOpen, setIsAddressSelectorOpen] = useState<boolean>(false)

  const [banners, setBanners] = useState<Banner[]>()
  const [isBannersLoading, setIsBannersLoading] = useState<boolean>(false)

  const [categories, setCategories] = useState<Category[]>()
  const [isCategoriesLoading, setIsCategoriesLoading] = useState<boolean>(false)

  const [selectedCategory, setSelectedCategory] = useState<string>('')

  const [isStoreSearchOpen, setIsStoreSearchOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(clear('delivery'))
    dispatch(clear('withdraw'))
    dispatch(clear('drive'))
  }, [])

  // banners gathering
  useEffect(() => {
    const env = process.env.REACT_APP_BANNERS

    if (!generalToken || !env) return

    setIsBannersLoading(true)

    const data = {
      Id: 6,
      Cep: '15025000',
      Uf: 'SP',
      Numero: '4270',
      Complemento: null,
      Log: 'AVENIDA BADY BASSITT',
      Ativo: true,
      Bai: {
        c: 24703.0,
        d: 'BOA VISTA'
      },
      Cid: {
        c: 9659.0,
        d: 'SAO JOSE DO RIO PRETO'
      },
      Location: {
        Latitude: -208147462.0,
        Longitude: -493919145.0
      }
    };

    (async () => {
      try {
        const response = await axios({
          method: 'POST',
          headers: {
            Authorization: `Bearer ${generalToken}`
          },
          url: `${generalLink}/${env}`,
          data
        })

        if (response.status !== 200 || !response.data) {
          throw new Error()
        }
  
        setIsBannersLoading(false)
        setBanners(response.data)
      } catch (err) {
        setIsBannersLoading(false)
      }
    })()
  }, [generalToken, generalLink])

  // categories gathering
  useEffect(() => {
    const env = process.env.REACT_APP_CATEGORIES
    
    if (!generalToken || !env) return

    setIsCategoriesLoading(true)

    const data = {
      Id: 6,
      Cep: '15025000',
      Uf: 'SP',
      Numero: '4270',
      Complemento: null,
      Log: 'AVENIDA BADY BASSITT',
      Ativo: true,
      Bai: {
        c: 24703.0,
        d: 'BOA VISTA'
      },
      Cid: {
        c: 9659.0,
        d: 'SAO JOSE DO RIO PRETO'
      },
      Location: {
        Latitude: -208147462.0,
        Longitude: -493919145.0
      }
    };

    (async () => {
      try {
        const response = await axios({
          method: 'POST',
          headers: {
            Authorization: `Bearer ${generalToken}`
          },
          url: `${generalLink}/${env}`,
          data
        })

        if (response.status !== 200 || !response.data) {
          throw new Error()
        }

        setCategories(response.data)
        setIsCategoriesLoading(false)
      } catch (err) {
        setIsCategoriesLoading(false)
      }
    })()
  }, [generalToken, generalLink])

  // stores gathering
  useEffect(() => {
    const env = process.env.REACT_APP_STORES
    
    if (!generalToken || !env) return

    if (selectedCategory) {
      dispatch(getStoresByCategory({ generalToken, category: selectedCategory, generalLink }))
      return
    }

    dispatch(getAllStores({ generalToken, generalLink }))
  }, [generalToken, dispatch, selectedCategory, generalLink])

  // general params gathering
  useEffect(() => {
    if (!generalToken || !generalLink) return

    dispatch(getGeneralParams({
      token: generalToken,
      domain: generalLink
    }))
  }, [generalToken, generalLink])

  const loading = (isBannersLoading || isCategoriesLoading)

  return (
    <HomePageContainer>
      <HomeHeader
        openSearchStoreMenu={() => setIsStoreSearchOpen(true)}
        openAddressSelector={() => setIsAddressSelectorOpen(true)}
      />
      <div className="store_sections_wrapper">
        <div className="page_context">
          { banners && (banners.length > 0) && <ItemSlider items={banners} /> }
          { categories && (categories.length > 2) && <Categories categories={categories} setCategory={setSelectedCategory} selectedCategory={selectedCategory} /> }
          { stores && (stores.length > 0) && !isStoresLoading && <StoreList stores={stores} /> }
        </div>
      </div>
      {
        isAddressSelectorOpen && (
          <AddressSelector close={() => setIsAddressSelectorOpen(false)} />
        )
      }
      {
        (isStoreSearchOpen && stores && categories) && (
          <StoreSearch stores={stores} close={() => setIsStoreSearchOpen(false)} />
        )
      }
      {
        loading && (
          <ScaleLoader />
        )
      }
    </HomePageContainer>
  )
}

export default Home