import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Address } from '../../../types/address'
import { Parameters, StoreInfo, StoreParams } from '../../../types/storeInfo'
import { infoService } from './infoService'

type GetStoreInfoProps = {
  token: string,
  domain: string
}

type GetStoreParamsProps = {
  token: string,
  body: Address,
  storeId: string,
  domain: string
}

type UpdateFeeProps = {
  fee: number
}

type setRepeatedRequestProps = {
  type: 'drive' | 'withdraw' | 'delivery'
  request: string
}

const initialState = {
  info: {} as StoreInfo,
  params: {} as StoreParams,
  appParams: {
    logo: '',
    background: ''
  },
  requestToBeRepeated: '',
  repeatRequestType: '' as 'drive' | 'withdraw' | 'delivery',
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: ''
}

export const getStoreInfo = createAsyncThunk(
  'info/get_store_info',
  async (props: GetStoreInfoProps, thunkApi) => {
    try {
      const response = await infoService.getStoreInfo(props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
        err.message ||
        String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

export const getStoreParams = createAsyncThunk(
  'info/get_store_params',
  async (props: GetStoreParamsProps, thunkApi) => {
    try {
      const response = await infoService.getStoreParams(props.token, props.body, props.storeId, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
        err.message ||
        String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

export const defineAppStoreData = createAsyncThunk(
  'token/app',
  async (props: GetStoreParamsProps, thunkApi) => {
    try {
      const response = await infoService.getStoreParams(props.token, props.body, props.storeId, props.domain)

      if (response?.status !== 200) {
        throw new Error('A loja não está disponível no momento.')
      }

      return response.data
    } catch (err: any) {
      return thunkApi.rejectWithValue(err)
    }
  }
)

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    reset: (state) => {
      state.info = {} as StoreInfo
      state.params = {} as StoreParams
      state.requestToBeRepeated = ''
      state.repeatRequestType = '' as 'drive' | 'withdraw' | 'delivery'
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    updateFee: (state, action: PayloadAction<UpdateFeeProps>) => {
      state.info = {
        ...state.info,
        TaxaEntrega: action.payload.fee
      }
    },
    setStoreParams: (state, action: PayloadAction<StoreParams>) => {
      state.params = action.payload
    },
    setRequestToBeRepeated: (state, action: PayloadAction<setRepeatedRequestProps>) => {
      state.requestToBeRepeated = action.payload.request
      state.repeatRequestType = action.payload.type
    },
    clearRepeatRequest: (state) => {
      state.requestToBeRepeated = ''
      state.repeatRequestType = '' as 'delivery' | 'withdraw' | 'drive'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStoreInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.info = action.payload
      })
      .addCase(getStoreInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
      .addCase(getStoreParams.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStoreParams.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.params = action.payload
      })
      .addCase(getStoreParams.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
      .addCase(defineAppStoreData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(defineAppStoreData.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        const backgroundParam = action.payload.Parametros.find((param: Parameters) => param.Chave === 'MV_COLOR_BACKGROUND')?.Valor
        
        state.appParams = {
          background: backgroundParam ? `#${backgroundParam}` : '',
          logo: action.payload?.LogoUri
        }
      })
      .addCase(defineAppStoreData.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
  }
})

export const { reset, updateFee, setStoreParams, setRequestToBeRepeated, clearRepeatRequest } = infoSlice.actions
export default infoSlice.reducer
