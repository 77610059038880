import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { RootState } from '../store/store'

function AddressProtectedRoute() {
  const { selectedAddress, attemptedToAddAddress } = useSelector((state: RootState) => state.user)

  const navigate = useNavigate()

  useEffect(() => {
    if (selectedAddress?.Log || !attemptedToAddAddress) return

    navigate('/endereco')
  }, [navigate, selectedAddress, attemptedToAddAddress])

  return (
    <Outlet />
  )
}
export default AddressProtectedRoute