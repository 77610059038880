import { ChevronLeft, X } from 'lucide-react'
import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { SearchBarContainer } from './styles'

type SearchBarProps = {
  handleGoBack: () => void,
  searchValue: string,
  handleChangeSearchValue: (e: ChangeEvent<HTMLInputElement>) => void,
  cleanSearchValue: () => void,
  placeholder: string
}

function SearchBar ({ handleGoBack, searchValue, handleChangeSearchValue, cleanSearchValue, placeholder }: SearchBarProps) {
  const { storeParams } = useSelector((state: RootState) => state.token)

  return (
    <SearchBarContainer background={`#${storeParams.bgColor}`}>
        <div className="page_context">
          <button className="icon-wrapper" onClick={handleGoBack}>
            <ChevronLeft size={28} color="#ffffff" />
          </button>
          <input
            type="text"
            placeholder={placeholder}
            value={searchValue}
            onChange={handleChangeSearchValue}
          />
          <button className="icon-wrapper" onClick={cleanSearchValue}>
            <X size={28} color="#ffffff" />
          </button>
        </div>
      </SearchBarContainer>
  )
}
export default SearchBar
