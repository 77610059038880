import { ChevronDown, MapPin } from 'lucide-react'
import { Address } from '../../types/address'
import { AddressButtonContainer } from './styles'

type AddressButtonProps = {
  handleAddressSelector: () => void,
  selectedAddress: Address,
  homePageStyle?: boolean
}

function AddressButton ({ handleAddressSelector, selectedAddress, homePageStyle }: AddressButtonProps) {
  return (
    <AddressButtonContainer homePageStyle={homePageStyle}>
      <button
        type="button"
        onClick={handleAddressSelector}
        className="address_selector_button"
      >
        <h3>ENDEREÇO DE ENTREGA</h3>
        <div>
          <MapPin size={20} />
          <span>{selectedAddress?.Log?.toUpperCase()}, {selectedAddress?.Numero}</span>
          <ChevronDown size={20} />
        </div>
      </button>
    </AddressButtonContainer>
  )
}

export default AddressButton
