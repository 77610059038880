import styled, { css } from 'styled-components'

type AddressButtonProps = {
  homePageStyle?: boolean
}

export const AddressSelectorContainer = styled.div`
  background: #ffffff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  width: 100%;

  position: fixed;

  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  max-height: 40rem;

  display: flex;
  flex-direction: column;

  h3 {
    color: ${({ theme }) => theme.colors.primary.normal};

    text-align: center;
    font-size: 1.4rem;

    border-bottom: 1px solid #efefef;
    padding: 1.6rem;
  }

  .options {
    flex: 1;

    overflow: auto;
  }

  label {
    display: flex;
    align-items: center;

    border-bottom: 1px solid #efefef;

    padding: .6rem 1.6rem;

    .address_description {
      margin-left: .8rem;

      font-size: 1.1rem;
    }
  }

  button {
    font-weight: 700;

    display: flex;
    align-items: center;

    width: 100%;

    padding: 1rem 1.6rem;

    svg {
      margin-right: 1rem;
    }
  }

  .add_new_address_button {
    color: #ff0000;

    border-bottom: 1px solid #efefef;
  }

  .login_button {
    color: ${({ theme }) => theme.colors.primary.normal};
  }
`

export const AddressButtonContainer = styled.div<AddressButtonProps>`
  margin-right: 3rem;

  button {
    color: #ffffff;

    text-align: left;
  }

  div {
    display: flex;
    align-items: center;

    margin-top: .6rem;
  }

  svg {
    margin-right: .4rem;
  }

  strong {
    display: block;
    margin-bottom: .8rem;

    @media (min-width: 961px) {
      display: none;
    }
  }

  p {
    display: flex;
    align-items: center;

    span {
      margin: 0 .4rem;
    }
  }

  @media (max-width: 360px) {
    strong {
      font-size: 1.3rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 312px) {
    strong {
      font-size: 1.2rem;
    }

    p {
      font-size: 1.1rem;
    }
  }

  ${({ homePageStyle }) => homePageStyle && css`
    @media (min-width: 961px) {
      h3 {
        display: none;
      }
    }
  `}

  ${({ homePageStyle }) => !homePageStyle && css`
    margin: 0 .8rem;

    button {
      border: 1px solid #ff0000;
      color: #000000;

      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;

      padding: .6rem;
      margin: 0 auto 2rem;
    }
  `}
`