import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetBorders } from '../../../../store/features/item/itemSlice'
import { Addon, Addons } from '../../../../types/cardapioItem'
import ComplementContainer from './ComplementContainer'
import SpecialComplement from './SpecialComplement'

type ComplementsProps = {
  addons: Addons[],
  setComplement: (name: string, arg: Addon) => void,
  complements: Addons[],
  quant: number,
  sizeCode: number,
  pasta: Addons | undefined,
  border: Addons | undefined
}

function Complements ({ addons, setComplement, complements, quant, sizeCode, pasta, border }: ComplementsProps) {
  const dispatch = useDispatch()

  function isFieldChecked (fieldName: string, addon: string): boolean {
    const currentComplement = complements.find((complement) => complement.Nome === addon)

    const itemQtd = currentComplement?.Itens.find((complement) => complement.Nome === fieldName)?.Quantidade

    return Boolean(itemQtd ? itemQtd > 0 : false)
  }

  function borderOrPasta (origin: string, type: string): 'B' | 'P' | false {
    if (origin === 'B' && type === 'R') {
      return 'B'
    }

    if (origin === 'M' && type === 'R') {
      return 'P'
    }

    return false
  }

  const pastaItem =
    pasta?.Itens
      .find((item) => item.Quantidade > 0)

  useEffect(() => {
    if (!pastaItem) return

    if (pastaItem.AceitaBorda === 'N') {
      const resetBordersProps = {
        sizeCode
      }

      dispatch(resetBorders(resetBordersProps))
    }
  }, [pastaItem, dispatch, sizeCode])

  return (
    <>
      {
        pasta && (
          <SpecialComplement
            title="Massas"
            addon={pasta}
            setComplement={setComplement}
          />
        )
      }
      {
        border && (!pastaItem || pastaItem?.AceitaBorda === 'S') && (
          <SpecialComplement
            title="Bordas"
            addon={border}
            setComplement={setComplement}
          />
        )
      }
      {
        addons.map((addon) => !borderOrPasta(addon.Origem, addon.TipoItem) && (
          <ComplementContainer
            addon={addon}
            quant={quant}
            isFieldChecked={isFieldChecked}
            setComplement={setComplement}
            key={`${addon.Nome}${addon.Origem}${addon.TipoItem}`}
          />
        ))
      }
    </>
  )
}

export default Complements
