import styled from 'styled-components'

type StoreCardContainerProps = {
  background: string
}

type CartItemCardProps = {
  background: string
}

export const PaymentMethodsContainer = styled.div`
  .buttons {
    display: flex;
    justify-content: space-around;

    padding: 1.6rem 0;

    button {
      background: #ffffff;
      border: 1px solid #9f9f9f;
      border-radius: .8rem;

      color: #9f9f9f;
      font-weight: 700;

      width: 45%;

      padding: .6rem 0;

      &.active {
        background: #ff0000;
        border: 1px solid #ff0000;
        color: #ffffff;

        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.12);
      }
    }
  }

  .payment_options {
    padding: 0 .6rem;
  }

  .local_payment {
    .method {
      border-bottom: 1px solid #e2e2e2;

      &:last-child {
        border-bottom: 0;
      }
    }

    .item_definition {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 1rem 0;

      span {
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }

  .error {
    color: #ff0000;
    font-size: 1.2rem;
    font-weight: 700;

    margin-left: .6rem;
  }
`

export const CartItemCard = styled.div<CartItemCardProps>`
  margin: 2rem 0;

  border-radius: 1rem;

  .cart_item_header, .cart_item_footer {
    background: #f2f2f2;
  }

  .cart_item_header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: .4rem;

    button {
      padding: .4rem .8rem;
    }

    p {
      font-size: 1.4rem;
      font-weight: 700;

      margin-right: 1rem;
    }

    .image {
      width: 8rem;
      height: 6rem;

      background-image: url(${({ background }) => background});
      background-position: center;
      background-size: cover;

      border-top-left-radius: .8rem;
      border-top-right-radius: .8rem;
    }
  }

  .cart_item_details {
    color: #666666;
    font-size: 1.3rem;

    padding: 2rem .4rem;

    div {
      display: inline-block;
    }
    
    > div {
      display: block;
      margin-bottom: .6rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .detail_name {
      font-weight: 700;

      margin-right: .8rem;
    }

    .prop_name {
      margin-right: .4rem;
    }

    .prop_price {
      color: #990000;
      font-weight: 700;
    }

    .prop_qtd {
      background: #bbbbbb;
      color: #000000;
      font-size: 1.4rem;
      font-weight: 700;

      padding: .2rem .6rem;
      margin-right: .6rem;
    }

    .item {
      &:after {
        content: ',';
        margin-right: .6rem;
      }

      &:last-child::after {
        content: '';
      }
    }
  }

  .cart_item_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: 700;

    .qtd_actions {
      font-size: 1.6rem;

      display: flex;
      align-items: center;

      button {
        padding: 1rem 1.4rem;
      }
    }

    .pricing {
      display: flex;
      align-items: center;

      margin-right: 1rem;
    }

    .price {
      font-size: 1.8rem;
      color: #00bb00;
    }

    .discount {
      font-size: 1.6rem;
      color: #ff0000;
      text-decoration: line-through;

      margin-right: 1.4rem;
    }
  }

  .heading {
    font-weight: 700;
    
    display: inline-block;

    margin-right: .6rem;
    margin-top: .6rem;
  }

  @media (min-width: 678px) {
    width: 42%;

    margin: 1rem 2rem 0;
  }

  @media (min-width: 926px) {
    width: 28%;

    margin: 1rem 2rem 0;
  }
`

export const CartItemsContainer = styled.div`
  padding: 0 .4rem;

  @media (min-width: 678px) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const StoreCardContainer = styled.div<StoreCardContainerProps>`
  display: flex;

  padding: 1rem .8rem;

  .image {
    background-image: url(${({ background }) => background});
    background-size: cover;
    background-position: center;
    
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;

    width: 10rem;
    height: 10rem;

    margin-right: 1.6rem;
  }

  .info {
    font-weight: 700;
    flex: 1;

    p {
      font-size: 1.6rem;

      margin-bottom: 1.2rem;
    }

    span {
      color: #888888;

      display: flex;
      align-items: center;

      font-size: 1.2rem;

      margin-bottom: 2rem;

      svg {
        margin-right: .8rem;
      }
    }

    .distance {
      font-size: 1.2rem;

      text-align: right;
    }
  }
`

export const SectionHeader = styled.div`
  background: #f2f2f2;

  color: #999999;
  font-size: 1.4rem;

  padding: 1.2rem .6rem;
`

export const CartHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;

  padding: .8rem .6rem;

  div {
    display: flex;
    align-items: center;

    h2 {
      font-size: 1.8rem;

      margin-left: 1.8rem;
    }
  }

  button {
    background: none;

    color: #ff0000;
    font-size: 1.6rem;
    font-weight: 700;

    padding: 0 1rem;
  }
`

export const CartItemsSection = styled.section`
  padding-bottom: 2rem;
`

export const CartContainer = styled.div`
  > section:last-of-type {
    margin-bottom: 8rem;
  }

  .voucher_button_wrapper {
    margin: 0 .8rem;

    #voucher_button {
      background: #8be7c2;
      border-radius: .6rem;
      color: #3c916a;

      font-size: 1.4rem;
      font-weight: 700;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 1rem;

      width: 100%;
    }
  }
`
