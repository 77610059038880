import { ChangeEvent, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Additional as AdditionalType } from '../../../../types/cardapioItem'
import SearchBar from '../../../../components/SearchBar'
import Additional from './Additional'
import { ArrowRight } from 'lucide-react'
import { AdditionalsContainer } from './styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

type AdditionalsProps = {
  currentSelectedAdds: AdditionalType[],
  adds: AdditionalType[],
  close: () => void,
  set: (add: AdditionalType) => void,
  halfItem: boolean,
  quant: number
}

function Additionals ({ adds, close, set, currentSelectedAdds, halfItem, quant }: AdditionalsProps) {
  const { storeParams } = useSelector((state: RootState) => state.token)

  const [searchValue, setSearchValue] = useState<string>('')

  const itemAdditionalsPortal = document.getElementById('item_additionals_portal')

  function handleChangeSearchValue (e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    const htmlBody = document.querySelector('body')
    if (!htmlBody) return

    const topPos = window.scrollY

    htmlBody.style.position = 'fixed'
    htmlBody.style.top = `-${topPos}px`

    return () => {
      const oldTopPos = htmlBody.style.top
      htmlBody.style.position = ''
      htmlBody.style.top = ''
      window.scrollTo(0, parseInt(oldTopPos || '0') * -1)
    }
  }, [])

  const filteredAdds =
    searchValue.length > 0
      ? adds.filter((add) => add.Descricao.toLowerCase().includes(searchValue.toLowerCase()))
      : adds

  if (!itemAdditionalsPortal) return null

  return ReactDOM.createPortal((
    <AdditionalsContainer background={`#${storeParams.bgColor}`}>
      <SearchBar
        searchValue={searchValue}
        handleChangeSearchValue={handleChangeSearchValue}
        cleanSearchValue={() => setSearchValue('')}
        handleGoBack={close}
        placeholder="Buscar adicionais..."
      />
      <div className="additionals_list">
        <div className="page_context">
          {
            filteredAdds.map((add) => (
              <Additional
                key={add.Descricao}
                add={add}
                set={set}
                halfItem={halfItem}
                quant={quant}
                checked={Boolean(currentSelectedAdds.find((selectedAdd) => (
                  (selectedAdd.Descricao === add.Descricao) && selectedAdd.Checked
                )))}
              />
            ))
          }
        </div>
      </div>
      <footer>
        <div className="page_context">
          <button type="button" onClick={close}>
            <span>Concluir</span>
            <ArrowRight size={28} color="#ffffff" />
          </button>
        </div>
      </footer>
    </AdditionalsContainer>
  ), itemAdditionalsPortal)
}

export default Additionals
