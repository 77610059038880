import axios from 'axios'
import { Address } from '../../../types/address'
import { LoginCredentials } from '../../../types/login'

async function loginWithRefreshToken (token: string, domain: string) {
  const env = process.env.REACT_APP_REFRESH_LOGIN

  if (!env || !token || !domain) return

  const response = await axios({
    method: 'PUT',
    url: `${domain}/${env}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

async function loginWithCredentials (credentials: LoginCredentials, token: string, domain: string) {
  const env = process.env.REACT_APP_CREDENTIAL_LOGIN

  if (!env || !domain || !token || !credentials) return

  const response = await axios({
    method: 'POST',
    url: `${domain}/${env}`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: credentials
  })

  return response
}

async function register (credentials: any, token: string, domain: string) {
  const env = process.env.REACT_APP_REGISTRATION

  if (!env || !domain || !token || !credentials) return

  const response = await axios({
    method: 'POST',
    url: `${domain}/${env}`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: credentials
  })

  return response
}

async function addNewAddress (address: Address, token: string, id: number, domain: string) {
  const env = process.env.REACT_APP_ADD_ADDRESS

  if (!env || !token || !domain || !id || !address) return

  const response = await axios({
    method: 'POST',
    url: `${domain}/${env}/${id}`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: address
  })

  return response
}

export const userService = {
  loginWithRefreshToken,
  loginWithCredentials,
  register,
  addNewAddress
}
