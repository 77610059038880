import axios from 'axios'
import { Minus, Plus, X } from 'lucide-react'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'
import Overlay from '../../../../components/Overlay'
import OverlayNotification from '../../../../components/OverlayNotification'
import ScaleLoader from '../../../../components/ScaleLoader'
import { addItem } from '../../../../store/features/cart/cartSlice'
import { RootState } from '../../../../store/store'
import { cardapioItem, Suggestion } from '../../../../types/cardapioItem'
import { ItemOnCart, Prices } from '../../../../types/cart'
import { SuggestionsContainer } from './styles'
import SuggestionCard from './SuggestionCard'

type SuggestionsProps = {
  suggestionList: Suggestion[]
  finalAction: () => void
}

function Suggestions({ suggestionList, finalAction }: SuggestionsProps) {
  const dispatch = useDispatch()

  const { storeToken, storeParams } = useSelector(
    (state: RootState) => state.token
  )
  const { type } = useSelector((state: RootState) => state.cardapio)
  const { info } = useSelector((state: RootState) => state.info)

  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion>(
    suggestionList[0]
  )
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1)

  const [loadingSuggestionItem, setLoadingSuggestionItem] =
    useState<boolean>(false)

  const [error, setError] = useState<string>('')

  const suggestionsPortal = document.getElementById('suggestions_portal')

  if (!suggestionsPortal) return null

  function handleMinusButton() {
    if (selectedQuantity <= 1) return

    setSelectedQuantity(prevState => prevState - 1)
  }

  function handlePlusButton() {
    setSelectedQuantity(prevState => prevState + 1)
  }

  function selectSuggestion(suggestionCode: number, suggestionSize: string) {
    const newSelectedSuggestion = suggestionList.find(
      suggestion =>
        suggestion.Codigo === suggestionCode &&
        suggestion.DescricaoTamanho === suggestionSize
    )

    if (!newSelectedSuggestion) return

    setSelectedSuggestion(newSelectedSuggestion)
  }

  async function addSuggestionToCart() {
    setLoadingSuggestionItem(true)

    try {
      const detailsResponse = await axios({
        method: 'GET',
        url: `${storeParams.api}/${process.env.REACT_APP_ITEM_DETAILS}/${type}/${selectedSuggestion.Codigo}`,
        headers: {
          Authorization: `Bearer ${storeToken.access_token}`
        }
      })

      const getPricesParams = {
        CodigoEmpresa: info.Codigo,
        CodigoTamanho: selectedSuggestion.CodigoTamanho,
        CodigoProdutos: [selectedSuggestion.Codigo],
        FlagPreco: type === 'drive' ? 'D' : type === 'retirada' ? 'R' : 'E'
      }

      const pricesResponse = await axios({
        method: 'POST',
        url: `${storeParams.api}/${process.env.REACT_APP_ITEM_PRICE}`,
        headers: {
          Authorization: `Bearer ${storeToken.access_token}`
        },
        data: getPricesParams
      })

      const suggestionItem: cardapioItem = detailsResponse.data
      const prices: Prices = pricesResponse.data

      const suggestionSize = suggestionItem.Tamanhos.find(
        size => size.Codigo === selectedSuggestion.CodigoTamanho
      )

      if (!suggestionSize) {
        throw new Error(
          'Um erro ocorreu na identificação do tamanho da sugestão.'
        )
      }

      if (!prices) {
        throw new Error('Um erro ocorreu ao adicionar a sugestão ao carrinho.')
      }

      const tastes = [
        {
          ...suggestionItem,
          Tamanhos: [suggestionSize]
        }
      ]

      setLoadingSuggestionItem(false)

      const suggestionOnCart: ItemOnCart = {
        id: v4(),
        Tamanho: suggestionSize,
        Quantidade: selectedQuantity,
        Observacao: '',
        Sabores: tastes,
        TipoMassa: null,
        Borda: null,
        ValorUnitario: selectedSuggestion.Valor,
        ValorTotal: selectedSuggestion.Valor * selectedQuantity,
        additionalInfo: prices
      }

      const addItemParams = {
        cart:
          type === 'drive'
            ? 'drive'
            : type === 'retirada'
            ? 'withdraw'
            : ('delivery' as 'drive' | 'withdraw' | 'delivery'),
        item: suggestionOnCart
      }

      dispatch(addItem(addItemParams))
    } catch (err) {
      setLoadingSuggestionItem(false)
    }

    finalAction()
  }

  function handleCloseErrorModal() {
    setError('')

    finalAction()
  }

  return ReactDOM.createPortal(
    <Overlay>
      <SuggestionsContainer className="small_context">
        <div className="card_heading">
          <div className="title">
            <h2>{suggestionList[0].Titulo}</h2>
            <button type="button" onClick={finalAction}>
              <X color="#ff0000" size={24} />
            </button>
          </div>
          <div className="current_suggestion_container">
            <div className="image_wrapper">
              <img
                src={`${storeParams.item}/${selectedSuggestion.Foto}`}
                alt="Imagem do item sugerido"
              />
            </div>
            <p>
              Valor Unitário:{' '}
              <span>R$ {selectedSuggestion.Valor.toFixed(2)}</span>
            </p>
          </div>
        </div>
        <div className="qtd_interaction_wrapper">
          <div className="section_header">
            <h4>Quantidade</h4>
          </div>
          <div className="quantity_selection">
            <button type="button" onClick={handleMinusButton}>
              <Minus size={24} color="#990000" />
            </button>
            <p>{selectedQuantity}</p>
            <button type="button" onClick={handlePlusButton}>
              <Plus size={24} color="#0858bb" />
            </button>
          </div>
        </div>
        <div className="suggestion_listing_container">
          <div className="section_header">
            <h4>Outras Sugestões</h4>
          </div>
          <div className="suggestions_listing">
            <ScrollContainer
              className="scroll_container custom_scrollbar"
              hideScrollbars={false}
            >
              {suggestionList.map(suggestion => (
                <SuggestionCard
                  key={`${suggestion.Codigo}${suggestion.DescricaoTamanho}`}
                  image={`${storeParams.item}/${suggestion.Foto}`}
                  name={suggestion.Nome}
                  size={suggestion.DescricaoTamanho}
                  action={() =>
                    selectSuggestion(
                      suggestion.Codigo,
                      suggestion.DescricaoTamanho
                    )
                  }
                  selected={
                    selectedSuggestion.Codigo === suggestion.Codigo &&
                    selectedSuggestion.DescricaoTamanho ===
                      suggestion.DescricaoTamanho
                  }
                />
              ))}
            </ScrollContainer>
          </div>
        </div>
        <div className="actions">
          <button
            type="button"
            className="add_button"
            onClick={addSuggestionToCart}
          >
            Adicionar: (+ R${' '}
            {(selectedSuggestion.Valor * selectedQuantity).toFixed(2)})
          </button>
          <button type="button" className="refuse_button" onClick={finalAction}>
            Não, obrigado!
          </button>
        </div>
      </SuggestionsContainer>
      {loadingSuggestionItem && <ScaleLoader />}
      {error && (
        <OverlayNotification text={error} close={handleCloseErrorModal} />
      )}
    </Overlay>,
    suggestionsPortal
  )
}
export default Suggestions
