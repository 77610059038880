import styled, { css } from 'styled-components'

type LayoutContainerProps = {
  hideItemStructure?: boolean
}

export const LayoutContainer = styled.main<LayoutContainerProps>`
  background: ${({ theme }) => theme.colors.primary.normal};

  display: flex;
  flex-direction: column;

  height: 100vh;

  padding: 0 .4rem;

  header {
    padding: 1rem .2rem;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    button {
      background: none;
    }
  }

  .image_wrapper {
    width: 40%;

    img {
      width: 100%;
    }
  }

  .label {
    font-size: 1.2rem;
    font-weight: 700;
    
    margin: 2rem 0;
    text-align: center;
  }

  .container {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      max-width: 40rem;
      width: 50%;

      margin-bottom: 3rem;

      img {
        width: 100%;

        margin-bottom: 1rem;

        transform: rotate(-10deg);
      }

      p {
        color: #ffffff;

        font-size: 1.4rem;
        font-weight: 700;

        text-align: center;
      }

      @media (max-width: 400px) {
        width: 60%;
      }

      @media (max-width: 350px) {
        width: 70%;
      }
    }

    ${({ hideItemStructure }) => !hideItemStructure && css`
      > div:last-child {
        background: #f2f2f2;
        border-radius: 1.6rem;

        width: 95%;
        min-height: 23rem;

        padding: 2rem;
      }
    `}
  }
`
