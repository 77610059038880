import styled from 'styled-components'

type ActionButtonsProps = {
  close: () => void,
  action: () => void,
  closeText: string,
  actionText: string
}

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    border-radius: 2rem;

    font-size: 1.3rem;
    font-weight: 700;

    width: 45%;

    padding: .8rem;

    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.28);

    &:first-child {
      color: #ff0000;
    }

    &:last-child {
      background: #ff0000;
      color: #ffffff;
    }
  }

  @media (max-width: 420px) {
    flex-direction: column;

    button {
      width: 100%;

      &:first-child {
        margin-bottom: 1.6rem;
      }
    }
  }
`

function ActionButtons({ close, action, closeText, actionText }: ActionButtonsProps) {
  return (
    <ActionButtonsContainer>
      <button type="button" onClick={close}>
        {closeText}
      </button>
      <button type="button" onClick={action}>
        {actionText}
      </button>
    </ActionButtonsContainer>
  )
}
export default ActionButtons