/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterMenu from './components/FooterMenu'
import { getCardapio, setType } from '../../store/features/cardapio/cardapioSlice'
import { AppDispatch, RootState } from '../../store/store'
import { CardapioContainer } from './styles'
import ScaleLoader from '../../components/ScaleLoader'
import Estimate from '../../components/Estimate'
import Categories from './components/Categories'
import axios from 'axios'
import Warning from './components/Warning'
import { useNavigate } from 'react-router-dom'
import { clearRepeatRequest, updateFee } from '../../store/features/info/infoSlice'
import AddressButton from '../../components/AddressSelector/AddressButton'
import AddressSelector from '../../components/AddressSelector'
import { reset } from '../../store/features/item/itemSlice'
import { ItemOnCart } from '../../types/cart'
import { addItem, clear } from '../../store/features/cart/cartSlice'
import OverlayNotification from '../../components/OverlayNotification'

function Cardapio () {
  const { cardapio, categories, isLoading, type, cardapioAttempted } = useSelector((state: RootState) => state.cardapio)
  const { storeToken, storeParams } = useSelector((state: RootState) => state.token)
  const { selectedAddress } = useSelector((state: RootState) => state.user)
  const { info } = useSelector((state: RootState) => state.info)
  const { requestToBeRepeated, repeatRequestType } = useSelector((state: RootState) => state.info)

  const [verifying, setVerifying] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [itemVerifying, setItemVerifying] = useState<boolean>(false)

  const [isAddressSelectorOpen, setIsAddressSelectorOpen] = useState<boolean>(false)
  const [attendNeighbourhood, setAttendNeighbourhood] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const firstRender = useRef<boolean>(true)

  function handleAddressSelector () {
    setIsAddressSelectorOpen(true)
  }

  function verifyItems (items: ItemOnCart[]) {
    setItemVerifying(true)

    if (cardapio.length === 0 && cardapioAttempted) {
      setError('O catálogo da loja não está disponível nessa modalidade.')
      return false
    }

    if ((type === 'entrega' && !selectedAddress)) {
      setError('Você precisa selecionar um endereço válido antes de repetir o pedido.')
      return false
    }

    if ((type === 'entrega' && !attendNeighbourhood)) {
      setError('Essa loja não atende o endereço selecionado.')
      return false
    }

    let stillInCatalog = true

    items.forEach((item) => {
      item.Sabores.forEach((flavour) => {
        const itemExistOnCatalog = cardapio.find((item) => item.Codigo === flavour.Codigo)

        if (!itemExistOnCatalog) {
          stillInCatalog = false
        }
      })
    })

    if (!stillInCatalog) {
      setError('Os itens do pedido não estão mais disponíveis no catálogo.')
      return false
    }

    return true
  }

  function addRepeatRequestToCart (repeatedRequestOnCart: ItemOnCart[]) {
    dispatch(clear(repeatRequestType))

    repeatedRequestOnCart.forEach((request) => {
      const addItemProps = {
        cart: type === 'retirada' ? 'withdraw' : type === 'entrega' ? 'delivery' : 'drive' as 'withdraw' | 'delivery' | 'drive',
        item: request
      }
  
      dispatch(addItem(addItemProps))
    })

    const requestCart = repeatRequestType === 'withdraw' ? 'retirada' : repeatRequestType === 'delivery' ? 'entrega' : 'drive'
    navigate(`/carrinho/${requestCart}`)
  }

  useEffect(() => {
    dispatch(reset())
  }, [])

  useEffect(() => {
    if (!requestToBeRepeated || !storeParams.api || !storeToken.access_token || !cardapioAttempted) return

    const parsedRequest = JSON.parse(requestToBeRepeated)

    if (!verifyItems(parsedRequest)) {
      setItemVerifying(false)
      dispatch(clearRepeatRequest())
      return
    }

    setItemVerifying(false)
    addRepeatRequestToCart(parsedRequest)
  }, [requestToBeRepeated, storeParams.api, storeToken.access_token, cardapio])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    }

    if (!storeToken.access_token || !storeParams.api || cardapio || isLoading) return

    const getCardapioParams = {
      option: type,
      token: storeToken.access_token,
      domain: storeParams.api
    }

    if (type === 'entrega' && !selectedAddress) {
      return
    }

    dispatch(getCardapio(getCardapioParams))
  }, [
    type,
    storeToken.access_token,
    selectedAddress,
    storeParams.api,
    cardapio,
    dispatch,
    isLoading
  ])

  useEffect(() => {
    if (info.LojaEntrega === 'NAO' && type === 'entrega') {
      if (info.LojaRetirada === 'SIM') {
        dispatch(setType('retirada'))
      } else if (info.LojaDrive === 'SIM') {
        dispatch(setType('drive'))
      }
    }

    if (info.LojaRetirada === 'NAO' && type === 'retirada') {
      if (info.LojaEntrega === 'SIM') {
        dispatch(setType('entrega'))
      } else if (info.LojaDrive === 'SIM') {
        dispatch(setType('drive'))
      }
    }

    if (info.LojaDrive === 'NAO' && type === 'drive') {
      if (info.LojaEntrega === 'SIM') {
        dispatch(setType('entrega'))
      } else if (info.LojaRetirada === 'SIM') {
        dispatch(setType('retirada'))
      }
    }
  }, [info.LojaDrive, info.LojaRetirada, info.LojaEntrega])

  useEffect(() => {
    const env = process.env.REACT_APP_STORE_ATTENDS

    if (type !== 'entrega' || !storeParams.api || !env || !storeToken.access_token) return
    (async () => {
      setVerifying(true)

      try {
        const response = await axios({
          method: 'GET',
          url: `${storeParams.api}/${process.env.REACT_APP_STORE_ATTENDS}/${selectedAddress.Bai.c}`,
          headers: {
            Authorization: `Bearer ${storeToken.access_token}`
          }
        })
        if (response.data.Codigo !== info.Codigo) {
          throw new Error('Incompatible store code')
        }

        setVerifying(false)
        setAttendNeighbourhood(true)
        dispatch(updateFee({ fee: response.data.TaxaEntrega }))
      } catch (err) {
        setVerifying(false)
        setAttendNeighbourhood(false)
      }
    })()
  }, [type, selectedAddress, dispatch, info.Codigo, storeParams.api, storeToken.access_token])


  useEffect(()=> {},[])
  return (
    <CardapioContainer className="page_context">
      <section className="options">
        {
          type !== 'salao' && (
            <div className="catalog_types">
              {
                info.LojaEntrega === 'SIM' && (
                  <button
                    className={type === 'entrega' ? 'active' : 'not-active'}
                    type="button"
                    onClick={() => dispatch(setType('entrega'))}
                  >
                    Entrega
                  </button>
                )
              }
              {
                info.LojaRetirada === 'SIM' && (
                  <button
                    className={type === 'retirada' ? 'active' : 'not-active'}
                    type="button"
                    onClick={() => dispatch(setType('retirada'))}
                  >
                    Retirada
                  </button>
                )
              }
              {
                info.LojaDrive === 'SIM' && (
                  <button
                    className={type === 'drive' ? 'active' : 'not-active'}
                    type="button"
                    onClick={() => dispatch(setType('drive'))}
                  >
                    Drive
                  </button>
                )
              }
            </div>
          )
        }
      </section>
      {
        (type === 'entrega' && !selectedAddress?.Log)
          ? <Warning
              text="Você precisa de um endereço para acessar o cardápio de entrega"
              btnText="Adicionar endereço"
              action={() => navigate('/endereco')}
            />
          : !isLoading && !verifying
              ? (type === 'entrega' && !attendNeighbourhood)
                  ? (
                    <>
                      <AddressButton
                        selectedAddress={selectedAddress}
                        handleAddressSelector={handleAddressSelector}
                      />
                      <Warning
                        text="Essa loja não atende esse endereço"
                        btnText="Adicionar novo endereço"
                        action={() => navigate('/endereco')}
                      />
                    </>
                  )
                  : (
                      <>
                        { type === 'entrega' && selectedAddress?.Log && cardapio.length > 0 && (
                            <AddressButton
                              selectedAddress={selectedAddress}
                              handleAddressSelector={handleAddressSelector}
                            />
                        ) }
                        {
                          type !== 'salao' && cardapio.length > 0 && (
                            <Estimate />
                          )
                        }
                        <p id="app_mode">Mode: {type === 'salao' ? 'LOJA' : 'APP'}</p>
                        { cardapio && <Categories cardapio={cardapio} categories={categories} /> }
                      </>
                    )
              : (
                <ScaleLoader />
                )
      }
      <FooterMenu type={type} />
      {
        isAddressSelectorOpen && (
          <AddressSelector
            close={() => setIsAddressSelectorOpen(false)}
          />
        )
      }
      {
        error && !isLoading && (
          <OverlayNotification text={error} close={() => setError('')} />
        )
      }
      {
        itemVerifying && (
          <ScaleLoader />
        )
      }
    </CardapioContainer>
  )
}

export default Cardapio
