/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import OverlayNotification from '../../components/OverlayNotification'
import ScaleLoader from '../../components/ScaleLoader'
import SimpleCard from '../../components/SimpleCard'
import useFormError from '../../hooks/useFormError'
import { clear, defineDiscount } from '../../store/features/cart/cartSlice'
import { RootState } from '../../store/store'
import { DeliveryTime, PaymentMethod } from '../../types/storeInfo'
import { validateCardNumberField, validateCpfField, validateCvvField, validateEmptyField, validateExpiringDateField, validatePhoneField } from '../../utils/fieldValidations'
import { findPos } from '../../utils/findPos'
import { cardNumberMask, cpfMask, cvvMask, expiringDateMask, phoneNumberMask } from '../../utils/masks'
import { onlyNumbers } from '../../utils/onlyNumbers'
import AddressSection from './components/AddressSection'
import CartFooter from './components/CartFooter'
import CartHeader from './components/CartHeader'
import CartItems from './components/CartItems'
import CartSection from './components/CartSection'
import MoreInfo from './components/MoreInfo'
import PaymentMethods from './components/PaymentMethods'
import StoreCard from './components/StoreCard'
import Voucher from './components/Voucher'
import { CartContainer } from './styles'
import gift from '../../assets/icons/gift.png'
import coupons from '../../assets/icons/coupons.png'
import { VoucherItemType } from '../../types/cart'
import { ArrowRight, Ticket } from 'lucide-react'
import VoucherSelection from './components/VoucherSelection'
import { clearRepeatRequest } from '../../store/features/info/infoSlice'

type CartProps = {
  currentCart: 'withdraw' | 'delivery' | 'drive'
}

export type VoucherItem = {
  isValid: boolean,
  ValorCupom: number
}

export type OnlinePaymentOptionsType = {
  name: string,
  card: {
    number: string,
    expiringDate: string,
    cvv: string
  },
  cpf: string
}

export type MoreInfoType = {
  cpf: string,
  phone: string
}

type Fidelity = {
  PontosAtual: number,
  PontosDoPedido: number,
  PontosParaGanhar: number,
  ValorVoucher: number
}

type Campaign = {
  CAMPANHA_ATIVA: string,
  VALOR_COMPRA_RECEBE_CUPOM: number,
  NOME_CAMPANHA: string,
  QTDE_CUPOM_QUE_RECEBE: number
}

function Cart({ currentCart }: CartProps) {
  let placeBase = null

  const { deliveryCart, withdrawCart, driveCart } = useSelector((state: RootState) => state.cart)
  const { userData, selectedAddress } = useSelector((state: RootState) => state.user)
  const { info, requestToBeRepeated } = useSelector((state: RootState) => state.info)
  const { storeParams, storeToken } = useSelector((state: RootState) => state.token)
  const { type } = useSelector((state: RootState) => state.cardapio)

  const { search } = useLocation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [error, setError] = useState<string>('')

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSalesLoading, setIsSalesLoading] = useState<boolean>(true)
  const [isTimestampsLoading, setIsTimestampsLoading] = useState<boolean>(true)
  const [isCampaignLoading, setIsCampaignLoading] = useState<boolean>(true)
  const [isFidelityLoading, setIsFidelityLoading] = useState<boolean>(true)
  const [isVouchersLoading, setIsVouchersLoading] = useState<boolean>(true)

  const [isError, setIsError] = useState<boolean>(false)
  const [requestError, setRequestError] = useState<string>('')
  const [requestSuccess, setRequestSuccess] = useState<boolean>(false)

  const [timestamps, setTimestamps] = useState<DeliveryTime[]>([])

  const [methods, setMethods] = useState<PaymentMethod[]>()
  const [localPaymentOption, setLocalPaymentOption] = useState<string>('')
  const [onlinePaymentOptions, setOnlinePaymentOptions] = useState<OnlinePaymentOptionsType>({
    name: '',
    card: {
      number: '',
      expiringDate: '',
      cvv: ''
    },
    cpf: ''
  })

  const [voucher, setVoucher] = useState<string>('')
  const [method, setMethod] = useState<'local' | 'online'>('local')
  const [deliveryTime, setDeliveryTime] = useState<DeliveryTime>(() => {
    if (timestamps.length > 0) {
      return timestamps[0]
    } else {
      return {
        DiaSemana: -1,
        Horario: 'Imediato'
      }
    }
  })
  const [tip, setTip] = useState<string>('')
  const [noteInfo, setNoteInfo] = useState<boolean>(false)

  const [moreInfo, setMoreInfo] = useState<MoreInfoType>({
    cpf: cpfMask(userData.CompleteUser.Usuario.CPF) || '',
    phone: phoneNumberMask(userData.CompleteUser.Usuario.Telefone) || ''
  })

  const [selectedVoucher, setSelectedVoucher] = useState<VoucherItem | null>(null)
  const [fidelity, setFidelity] = useState<Fidelity>()
  const [campaign, setCampaign] = useState<Campaign>()
  const [accountVouchers, setAccountVouchers] = useState<VoucherItemType[]>()

  const [voucherSelectionOpen, setVoucherSelectionOpen] = useState<boolean>(false)

  const [setNewError, getErrorByFieldname, cleanErrorsByFieldname] = useFormError()

  const moreInfoRef = useRef<HTMLDivElement>(null)

  const currentCartObject =
    currentCart === 'delivery'
      ? deliveryCart
      : currentCart === 'withdraw'
        ? withdrawCart
        : driveCart

  const getAccountVouchers = useCallback(async () => {
    if (!storeParams.api) return

    setIsVouchersLoading(true)

    try {
      const response = await axios({
        method: 'GET',
        url: `${storeParams.api}/${process.env.REACT_APP_VOUCHERS}/${userData.email}`,
        headers: {
          Authorization: `Bearer ${storeToken.access_token}`
        }
      })

      setAccountVouchers(response.data)
      setIsVouchersLoading(false)
    } catch (err) {
      setIsVouchersLoading(false)
    }
  }, [])

  const getCampaign = useCallback(async () => {
    if (!storeParams.api) return

    setIsCampaignLoading(true)

    try {
      const response = await axios({
        method: 'GET',
        url: `${storeParams.api}/${process.env.REACT_APP_CAMPAIGN}`,
        headers: {
          Authorization: `Bearer ${storeToken.access_token}`
        }
      })

      if (response.status !== 200) {
        throw new Error('campaign does not exist')
      }
      
      setCampaign(response.data)
      setIsCampaignLoading(false)
    } catch (err) {
      setIsCampaignLoading(false)
    }
  }, [])

  const getFidelity = useCallback(async () => {
    const items = currentCartObject.map((cartItem) => {
      let { additionalInfo, id, ValorTotal, ValorUnitario, ...item } = cartItem

      item = {
        ...item,
        Tamanho: {
          ...item.Tamanho,
          ...additionalInfo
        }
      }

      return item
    })

    const fidelityData = {
      Login: userData.email,
      FlagPreco: type === 'drive' ? 'D' : type === 'retirada' ? 'R' : 'E',
      Itens: items
    }

    try {
      const response = await axios({
        method: 'POST',
        url: `${storeParams.api}/${process.env.REACT_APP_FIDELITY}`,
        headers: {
          Authorization: `Bearer ${storeToken.access_token}`
        },
        data: fidelityData
      })

      setFidelity(response.data)
      setIsFidelityLoading(false)
    } catch (err) {
      setIsFidelityLoading(false)
    }
  }, [])

  const getSales = useCallback(async () => {
    setIsSalesLoading(true)

    const items = currentCartObject.map((cartItem) => {
      let { additionalInfo, id, ValorTotal, ValorUnitario, ...item } = cartItem

      item = {
        ...item,
        Tamanho: {
          ...item.Tamanho,
          ...additionalInfo
        }
      }

      return item
    })

    const salesData = {
      CodigoEmpresa: info.Codigo,
      FlagPreco: type === 'drive' ? 'F' : type === 'retirada' ? 'D' : 'E',
      Itens: items
    }

    try {
      const response = await axios({
        method: 'POST',
        url: `${storeParams.api}/${process.env.REACT_APP_SALES}`,
        headers: {
          Authorization: `Bearer ${storeToken.access_token}`
        },
        data: salesData
      })

      const defineDiscountParams = {
        cart: type === 'retirada' ? 'withdraw' : type === 'entrega' ? 'delivery' : 'drive' as 'withdraw' | 'delivery' | 'drive',
        Itens: response.data.Itens
      }

      dispatch(defineDiscount(defineDiscountParams))
      setVoucher('')
      setIsSalesLoading(false)
    } catch (err) {
      setIsSalesLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!requestToBeRepeated) return
    
    dispatch(clearRepeatRequest())
  }, [requestToBeRepeated])

  useEffect(() => {
    if (!voucher) setSelectedVoucher(null)
  }, [voucher])

  useEffect(() => {
    const protectCondition =
      (currentCart === 'withdraw' && withdrawCart.length === 0) ||
      (currentCart === 'delivery' && deliveryCart.length === 0) ||
      (currentCart === 'drive' && driveCart.length === 0)

    if (protectCondition) {
      navigate(`/loja/cardapio${search}`)
    }
  }, [withdrawCart.length, deliveryCart.length, driveCart.length, currentCart, navigate, search])

  // sale gathering
  useEffect(() => {
    getSales()
  }, [getSales])

  // fidelity gathering
  useEffect(() => {
    getFidelity()
  }, [getFidelity])

  // coupon campaign gathering
  useEffect(() => {
    getCampaign()
  }, [getCampaign])

  // vouchers gathering
  useEffect(() => {
    getAccountVouchers()
  }, [getAccountVouchers])

  // payment methods gathering
  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${storeParams.api}/${process.env.REACT_APP_STORE_PAYMENT_METHODS}`,
          headers: {
            Authorization: `Bearer ${storeToken.access_token}`
          }
        })

        setMethods(response.data)
      } catch (err) {
        setIsError(true)
      }
    })()
  }, [storeParams.api, storeToken.access_token])

  // timestamps gathering
  useEffect(() => {
    (async () => {
      if (!storeParams.api) return

      try {
        const response = await axios({
          method: 'GET',
          url: `${storeParams.api}/${process.env.REACT_APP_STORE_TIMESTAMPS}`,
          headers: {
            Authorization: `Bearer ${storeToken.access_token}`
          }
        })

        setIsTimestampsLoading(false)
        setTimestamps(response.data)
      } catch (err) {
        setIsTimestampsLoading(false)
        setIsError(true)
      }
    })()
  }, [storeParams.api, storeToken.access_token])

  useEffect(() => {
    if (isError) {
      setIsLoading(false)
    }

    if (
      methods && timestamps.length > 0
    ) {
      setIsLoading(false)
    }
  }, [methods, timestamps, isError])

  useEffect(() => {
    if (!methods) return

    if (methods.length < 1) {
      setMethod('online')
    }
  }, [methods])

  function validateOnlinePaymentFields(): boolean {
    const isNameFieldValid = validateEmptyField(
      onlinePaymentOptions.name,
      'name',
      'Informe o proprietário do cartão',
      setNewError,
      cleanErrorsByFieldname
    )

    const isCardNumberFieldValid = validateCardNumberField(
      onlinePaymentOptions.card.number,
      'cardNumber',
      'Informe um número de cartão válido',
      setNewError,
      cleanErrorsByFieldname
    )

    const isExpirationDateFieldValid = validateExpiringDateField(
      onlinePaymentOptions.card.expiringDate,
      'expiringDate',
      'Informe uma data válida',
      setNewError,
      cleanErrorsByFieldname
    )

    const isCvvFieldValid = validateCvvField(
      onlinePaymentOptions.card.cvv,
      'cvv',
      'Informe um código de segurança válido',
      setNewError,
      cleanErrorsByFieldname
    )

    const isCpfFieldValid = validateCpfField(
      onlinePaymentOptions.cpf,
      'paymentCpf',
      'Informe um CPF válido',
      setNewError,
      cleanErrorsByFieldname
    )

    return (
      isNameFieldValid &&
      isCardNumberFieldValid &&
      isExpirationDateFieldValid &&
      isCvvFieldValid &&
      isCpfFieldValid
    )
  }

  function validateFields() {
    const isCpfFieldValid = validateCpfField(
      moreInfo.cpf,
      'cpf',
      'Insira um CPF válido',
      setNewError,
      cleanErrorsByFieldname
    )

    const isPhoneFieldValid = validatePhoneField(
      moreInfo.phone,
      'phone',
      'Insira um número de telefone válido',
      setNewError,
      cleanErrorsByFieldname
    )

    const isPaymentMethodsValid =
      method === 'local'
        ? validateEmptyField(
          localPaymentOption,
          'local_payment_option',
          'Escolha uma opção de pagamento',
          setNewError,
          cleanErrorsByFieldname
        )
        : validateOnlinePaymentFields()

    return (
      isCpfFieldValid &&
      isPhoneFieldValid &&
      isPaymentMethodsValid
    )
  }

  function handleOnlinePaymentOptionsChange(e: ChangeEvent<HTMLInputElement>) {
    switch (e.target.name) {
      case 'name':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          name: e.target.value
        }))
        break

      case 'cpf':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          cpf: cpfMask(e.target.value)
        }))
        break

      case 'cardNumber':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          card: {
            ...prevState.card,
            number: cardNumberMask(e.target.value)
          }
        }))
        break

      case 'expiringDate':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          card: {
            ...prevState.card,
            expiringDate: expiringDateMask(e.target.value)
          }
        }))
        break

      case 'cvv':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          card: {
            ...prevState.card,
            cvv: cvvMask(e.target.value)
          }
        }))
        break

      default: break
    }
  }

  function handleClearOnlinePaymentOption(field: string) {
    switch (field) {
      case 'name':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          name: ''
        }))
        break

      case 'cpf':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          cpf: ''
        }))
        break

      case 'cardNumber':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          card: {
            ...prevState.card,
            number: ''
          }
        }))
        break

      case 'cardExpiringDate':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          card: {
            ...prevState.card,
            expiringDate: ''
          }
        }))
        break

      case 'cardCvv':
        setOnlinePaymentOptions((prevState) => ({
          ...prevState,
          card: {
            ...prevState.card,
            cvv: ''
          }
        }))
        break

      default: break
    }
  }

  function handleMoreInfoChange(e: ChangeEvent<HTMLInputElement>) {
    switch (e.target.name) {
      case 'cpf':
        setMoreInfo((prevState) => ({
          ...prevState,
          cpf: cpfMask(e.target.value)
        }))
        break

      case 'phone':
        setMoreInfo((prevState) => ({
          ...prevState,
          phone: phoneNumberMask(e.target.value)
        }))
        break

      default: break
    }
  }

  function handleMoreInfoClear(field: string) {
    switch (field) {
      case 'cpf':
        setMoreInfo((prevState) => ({
          ...prevState,
          cpf: ''
        }))
        break

      case 'phone':
        setMoreInfo((prevState) => ({
          ...prevState,
          phone: ''
        }))
        break

      default: break
    }
  }

  function setSelectedTimestamps(newTimestamp: string) {
    const newDeliveryTime = timestamps.find((timestamp) => timestamp.Horario === newTimestamp)

    if (!newDeliveryTime) return

    setDeliveryTime(newDeliveryTime)
  }

  function changeMethod(newMethod: 'local' | 'online') {
    if (newMethod === 'online') {
      setLocalPaymentOption('')
      cleanErrorsByFieldname('local_payment_option')
    } else {
      cleanErrorsByFieldname('name')
      cleanErrorsByFieldname('cvv')
      cleanErrorsByFieldname('expiringDate')
      cleanErrorsByFieldname('cardNumber')
      cleanErrorsByFieldname('paymentCpf')
    }

    setMethod(newMethod)
  }

  const paymentMethodsProps = {
    paymentMethod: method,
    changeMethod: (newMethod: 'local' | 'online') => changeMethod(newMethod),
    onlinePaymentOptions,
    changeOnlinePaymentOptions: handleOnlinePaymentOptionsChange,
    clearOnlinePaymentOption: handleClearOnlinePaymentOption,
    localPaymentOption,
    changeLocalPaymentOption: (newOption: string) => setLocalPaymentOption(newOption),
    tip,
    changeTip: (e: ChangeEvent<HTMLInputElement>) => setTip(e.target.value),
    clearTip: () => setTip(''),
    methods,
    getError: getErrorByFieldname
  }

  const moreInfoProps = {
    cpf: moreInfo.cpf,
    phone: moreInfo.phone,
    change: handleMoreInfoChange,
    clear: handleMoreInfoClear,
    note: noteInfo,
    changeNoteInfo: () => setNoteInfo((prevState) => !prevState),
    getError: getErrorByFieldname,
    ref: moreInfoRef
  }

  const addressSectionTimestampsProps = {
    timestamps,
    selectedDeliveryTime: deliveryTime,
    setSelectedTimestamps
  }

  const deliveryFee = currentCart === 'delivery' && info.TaxaEntrega ? info.TaxaEntrega : 0
  const fullItemPrice = currentCartObject.reduce((prev, curr) => prev + curr.ValorTotal, 0)
  const discount = currentCartObject.reduce((prev, curr) => prev + ((curr.ValorDesconto || 0) + curr.Tamanho.ValorDesconto), 0)

  const totalPrice = fullItemPrice + deliveryFee - discount

  if (currentCart === 'delivery' && selectedAddress) {
    placeBase = selectedAddress
  } else if (info.Endereco?.Location) {
    placeBase = info.Endereco
  }

  async function validateVoucher(voucherData?: string | Event) {
    const voucherInfo = {
      CodigoLoja: info.Codigo,
      NumeroCupom: typeof voucherData === 'string' ? voucherData : voucher,
      ValorPedido: totalPrice
    }

    const env = process.env.REACT_APP_VOUCHER

    if (!env) return

    setIsLoading(true)

    try {
      const response = await axios({
        method: 'POST',
        url: `${storeParams.api}/${env}`,
        data: voucherInfo,
        headers: {
          Authorization: `Bearer ${storeToken.access_token}`
        }
      })

      setIsLoading(false)
      setVoucherSelectionOpen(false)
      setSelectedVoucher(response.data)
    } catch (err) {
      setIsLoading(false)
      setVoucherSelectionOpen(false)
      setVoucher('')
      setError('O cupom inserido é inválido.')
    }
  }

  async function request() {
    setIsLoading(true)

    const items = currentCartObject.map((cartItem) => {
      let { additionalInfo, id, ValorTotal, ValorUnitario, ...item } = cartItem

      item = {
        ...item,
        Tamanho: {
          ...item.Tamanho,
          ...additionalInfo
        }
      }

      return item
    })

    const FormaPagamento =
      localPaymentOption
        ? {
          Forma: methods?.find((method) => method.Nome === localPaymentOption),
          TrocoPara: localPaymentOption === 'DINHEIRO' ? tip : 0,
          Online: 'NAO',
          CardToken: null,
          NewCreditCard: null
        }
        : {
          Forma: null,
          TrocoPara: 0,
          Online: 'SIM',
          CardToken: 'NEW',
          NewCreditCard: {
            CardNumber: onlyNumbers(onlinePaymentOptions.card.number),
            Holder: onlinePaymentOptions.name,
            ExpirationDate: onlinePaymentOptions.card.expiringDate,
            SecurityCode: onlinePaymentOptions.card.cvv,
            Identity: onlyNumbers(onlinePaymentOptions.cpf),
            SalvarCartao: false,
            IdentityType: 'CPF'
          }
        }

    const requestBody = {
      AprovaTotemDireto: false,
      NotaFiscalPaulista: noteInfo ? 'SIM' : 'NAO',
      FormaPagamento,
      HorarioAgendamento: deliveryTime,
      Observacao: null,
      Origem: 'CARDAPIO',
      Loja: {
        ...info,
        TaxaEntrega: type === 'entrega' ? info.TaxaEntrega : 0
      },
      Account: {
        ...userData.CompleteUser.Usuario,
        Endereco: selectedAddress,
        Telefone: onlyNumbers(moreInfo.phone),
        CPF: onlyNumbers(moreInfo.cpf),
        ddd: onlyNumbers(moreInfo.phone).substring(0, 2),
        numero: onlyNumbers(moreInfo.phone).substring(2)
      },
      Tipo: type.toUpperCase(),
      Itens: items,
      CupomPromocional: selectedVoucher || null,
      versaoApp: process.env.REACT_APP_VERSION
    }

    try {
      await axios({
        method: 'POST',
        headers: {
          Authorization: `Bearer ${storeToken.access_token}`
        },
        url: `${storeParams.api}/${process.env.REACT_APP_REQUEST}`,
        data: requestBody
      })

      setIsLoading(false)
      setRequestSuccess(true)
    } catch (err: any) {
      const errorMessage = err.response.data.Messages[0] || 'Algum erro ocorreu durante a efetivação do pedido. Por favor, revise os dados ou tente mais tarde.'
      setIsLoading(false)
      setRequestError(errorMessage)
    }
  }

  function showError() {
    if (!moreInfoRef.current) return

    const position = findPos(moreInfoRef.current)?.[0]
    if (!position) return

    window.scrollTo(0, position)
  }

  function handleRequest() {
    if (validateFields()) {
      request()
    } else {
      showError()
    }
  }

  function finishRequest(status: 'error' | 'success') {
    if (status === 'success') {
      dispatch(clear(currentCart))

      return navigate(`/pedidos${search}`)
    } else {
      setRequestError('')
    }
  }

  function requiredPointsForFidelityCampaign() {
    if (!fidelity) return 0
    
    return (
      fidelity.PontosParaGanhar - (fidelity.PontosAtual + fidelity.PontosDoPedido)
    )
  }

  const recalculate = useCallback(() => {
    getSales()
    getCampaign()
    
    if (voucher) {
      validateVoucher()
    }

    getFidelity()
  }, [])

  useEffect(() => {
    if (!currentCartObject || currentCartObject.length === 0) return

    recalculate()
  }, [currentCartObject.length, recalculate])

  const somethingLoading =
    (isLoading || isTimestampsLoading || isSalesLoading || isFidelityLoading || isCampaignLoading || isVouchersLoading)

  return (
    <CartContainer>
      <CartHeader currentCart={currentCart} />
      <CartSection title="Loja">
        <StoreCard />
        {
          (campaign && campaign.CAMPANHA_ATIVA === 'SIM' && (totalPrice >= 0) && ((campaign.VALOR_COMPRA_RECEBE_CUPOM - totalPrice) > 0)) && (
            <SimpleCard Image={<img src={coupons} alt="Ícone da campanha" />} noFloatingIcon>
              <span>
                Faltam apenas
                <strong> R$ {(campaign.VALOR_COMPRA_RECEBE_CUPOM - totalPrice).toFixed(2)} </strong>
                para você receber
                <strong> {campaign.QTDE_CUPOM_QUE_RECEBE} </strong>
                cupons da campanha
                <strong> {campaign.NOME_CAMPANHA} </strong>
              </span>
            </SimpleCard>
          )
        }
      </CartSection>
      {
        placeBase &&
        addressSectionTimestampsProps.timestamps.length > 0 &&
        addressSectionTimestampsProps.selectedDeliveryTime &&
        (
          <CartSection title={`Endereço de ${currentCart === 'withdraw' ? 'retirada' : 'entrega'}`}>
            <AddressSection
              method={currentCart}
              placePosition={placeBase}
              timestampsProps={addressSectionTimestampsProps}
            />
          </CartSection>
        )
      }
      {
        currentCartObject.length > 0 && (
          <CartSection title="Itens">
            <CartItems items={currentCartObject} recalculate={recalculate} />
            {
              fidelity && fidelity.PontosParaGanhar > 0 && (
                <SimpleCard Image={<img src={gift} alt="Imagem da campanha fidelidade" />} noFloatingIcon>
                  <span>
                    {
                      fidelity.PontosDoPedido > 0 && (
                        <h3>Ganhe <strong>{fidelity.PontosDoPedido}</strong> {fidelity.PontosDoPedido > 1 ? 'pontos' : 'ponto'} com esta compra.</h3>
                      )
                    }
                    {
                      fidelity.PontosAtual > 0 && (
                        <p>Sua pontuação atual é de <strong>{fidelity.PontosAtual}</strong> {fidelity.PontosAtual > 1 ? 'pontos.' : 'ponto.'}</p>
                      )
                    }
                    {
                      requiredPointsForFidelityCampaign() > 0 && (
                        <h3>
                          Você precisará de mais
                          <strong> {requiredPointsForFidelityCampaign()}</strong>
                          {requiredPointsForFidelityCampaign() > 1 ? ' pontos ' : ' ponto '}
                          para ganhar um voucher {fidelity.ValorVoucher > 0 && (<>de <strong>R$ {fidelity.ValorVoucher.toFixed(2)}</strong></>)}
                        </h3>
                      )
                    }
                  </span>
                </SimpleCard>
              )
            }
          </CartSection>
        )
      }
      {
        !(discount > 0) && (
          <CartSection title="Voucher">
            <Voucher
              voucher={voucher}
              change={(e: ChangeEvent<HTMLInputElement>) => setVoucher(e.target.value)}
              clear={() => setVoucher('')}
              action={validateVoucher}
            />
            {
              accountVouchers && accountVouchers.length > 0 && (
                <div className="voucher_button_wrapper">
                  <button id="voucher_button" onClick={() => setVoucherSelectionOpen(true)}>
                    <Ticket size={24} />
                    <span>Vouchers</span>
                    <ArrowRight size={24} />
                  </button>
                </div>
              )
            }
          </CartSection>
        )
      }
      <CartSection title="Outras Informações">
        <MoreInfo moreInfoProps={moreInfoProps} />
      </CartSection>
      <CartSection title="Formas de Pagamento">
        <PaymentMethods
          paymentProps={paymentMethodsProps}
          localError={getErrorByFieldname('local_payment_option')}
        />
      </CartSection>
      <CartFooter
        totalPrice={totalPrice}
        discount={discount}
        voucher={selectedVoucher}
        handleSubmit={handleRequest}
        type={currentCart}
      />
      {
        somethingLoading && <ScaleLoader />
      }
      {
        requestError && (
          <OverlayNotification
            text={requestError}
            close={() => finishRequest('error')}
          />
        )
      }
      {
        requestSuccess && (
          <OverlayNotification
            text="Pedido realizado com sucesso!"
            close={() => finishRequest('success')}
          />
        )
      }
      {
        error && <OverlayNotification text={error} close={() => setError('')} />
      }
      {
        voucherSelectionOpen && accountVouchers && accountVouchers.length > 0 && (
          <VoucherSelection
            vouchers={accountVouchers}
            close={() => setVoucherSelectionOpen(false)}
            setVoucher={setVoucher}
            action={validateVoucher}
          />
        )
      }
    </CartContainer>
  )
}

export default Cart
