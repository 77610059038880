import { Lock, Pencil } from 'lucide-react'
import { EditSectionContainer } from '../styles'

type EditSectionProps = {
  editProfileAction?: () => void,
  changePasswordAction?: () => void
}

function EditSection({ editProfileAction, changePasswordAction }: EditSectionProps) {
  return (
    <EditSectionContainer>
      <div className="page_context">
        <button
          type="button"
          onClick={editProfileAction}
        >
          <Pencil size={18} />
          <span>editar perfil</span>
        </button>
        <button
          type="button"
          onClick={changePasswordAction}
        >
          <Lock size={18} />
          <span>alterar senha</span>
        </button>
      </div>
    </EditSectionContainer>
  )
}
export default EditSection