import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useFormError from '../../../hooks/useFormError'
import { loginWithCredentials, reset } from '../../../store/features/user/userSlice'
import { AppDispatch, RootState } from '../../../store/store'
import { validateEmailField, validatePasswordField } from '../../../utils/fieldValidations'
import FormField from './FormField'
import OverlayNotification from '../../../components/OverlayNotification'

type LoginFormProps = {
  goToRegister: () => void
}

const LoginFormContainer = styled.div`
  padding: 2rem;

  form {
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;

      margin-bottom: 3rem;

      button {
        flex: 1;

        border-radius: 1.6rem;

        font-weight: 700;

        padding: .6rem 0;

        box-shadow: 0px 2px 2px 0px rgb(0, 0, 0, .12);
      }

      .register {
        background: #ffffff;

        margin-right: .6rem;
      }

      .login {
        background: #ff0000;
        color: #ffffff;
      }
    }
  }

  .forgot_password {
    text-align: center;

    button {
      color: #ff0000;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
`

function LoginForm({ goToRegister }: LoginFormProps) {
  const { generalToken, generalLink } = useSelector((state: RootState) => state.token)
  const { isSuccess, isLoading, isError, message } = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const [loginFormValues, setLoginFormValues] = useState({
    email: '',
    password: ''
  })
  const [invalidAttempt, setInvalidAttempt] = useState<string>('')

  const [setNewError, getErrorByFieldname, cleanErrorsByFieldname] = useFormError()

  function validateFields() {
    const isEmailValid = validateEmailField(loginFormValues.email, setNewError, cleanErrorsByFieldname)
    const isPasswordValid = validatePasswordField(loginFormValues.password, setNewError, cleanErrorsByFieldname)

    return isEmailValid && isPasswordValid
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault()

    if (validateFields()) {
      dispatch(loginWithCredentials({
        credentials: {
          UserName: loginFormValues.email,
          Password: loginFormValues.password
        },
        token: generalToken,
        domain: generalLink
      }))
    }
  }

  function handleForgotPassword() {
    navigate('/restaurarSenha')
  }

  function handleLoginFormValuesChange(e: ChangeEvent<HTMLInputElement>) {
    setLoginFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  function handleLoginFormValueClear(field: string) {
    setLoginFormValues((prevState) => ({
      ...prevState,
      [field]: ''
    }))
  }

  function handleCloseSign() {
    dispatch(reset())
    setInvalidAttempt('')
  }

  useEffect(() => {
    if (isSuccess) {
      navigate('/')
    }

    if (isError) {
      setInvalidAttempt(message)
    }
  }, [isSuccess, isError, message, navigate])

  const fields = [
    {
      id: 1,
      value: loginFormValues.email,
      placeholder: 'Digite seu e-mail...',
      name: 'email',
      error: getErrorByFieldname('email')
    }, {
      id: 2,
      value: loginFormValues.password,
      placeholder: 'Digite sua senha...',
      name: 'password',
      type: 'password',
      error: getErrorByFieldname('password')
    }
  ]

  return (
    <LoginFormContainer>
      <form onSubmit={handleSubmit}>
        {
          fields.map((field) => (
            <FormField
              key={field.id}
              change={handleLoginFormValuesChange}
              clear={handleLoginFormValueClear}
              {...field}
            />
          ))
        }
        <div className="buttons">
          <button
            type="button"
            className="register"
            onClick={goToRegister}
          >
            Registre-se
          </button>
          <button type="submit" className="login">Acessar</button>
        </div>
      </form>
      <div className="forgot_password">
        <button type="button" onClick={handleForgotPassword}>Esqueci minha senha</button>
      </div>
      {
        invalidAttempt && !isLoading && (
          <OverlayNotification
            text={invalidAttempt}
            close={handleCloseSign}
          />
        )
      }
    </LoginFormContainer>
  )
}

export default LoginForm
