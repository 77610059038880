import { Search } from 'lucide-react';
import { SearchButtonElement } from '../styles';

type SearchButtonProps = {
  onClick: () => void
}

function SearchButton(props: SearchButtonProps) {
  return (
    <SearchButtonElement {...props}>
      <Search size={18} />
      <p>Você quer encontrar algo?</p>
    </SearchButtonElement>
  )
}
export default SearchButton