import { CheckCircle, XCircle } from 'lucide-react'
import ReactDOM from 'react-dom'
import { requestIcons } from '../../../assets/icons'

import AgilePageLayout from '../../../components/AgilePageLayout'
import { Request, RequestEvent } from '../../../types/requests'
import { RequestStatusContainer } from '../styles'
import ActionButtons from './ActionButtons'

type RequestStatusProps = {
  request: Request,
  close: () => void,
  openOrderEvaluation: () => void,
  events: RequestEvent[]
}

function RequestStatus({ request, close, openOrderEvaluation, events }: RequestStatusProps) {
  const requestStatusPortal = document.getElementById('request_status_portal')

  if (!requestStatusPortal) return null

  const takeAwayRequest = (request.Tipo === 'RETIRADA' || request.Tipo === 'DRIVE')

  const eventsInfo = [
    {
      image: requestIcons.orderFood,
      text: 'Pedido realizado',
      time: events.find((ev) => ev.EVENTO === 'CONFIRMED')?.DATAHORA,
      condition: true
    },
    {
      image: requestIcons.cooking,
      text: 'Preparando',
      time: events.find((ev) => ev.EVENTO === 'PREPARATION_STARTED')?.DATAHORA,
      condition: Boolean(events.find((ev) => ev.EVENTO === 'PREPARATION_STARTED'))
    },
    {
      image: takeAwayRequest ? requestIcons.takeAway : requestIcons.delivery,
      text: takeAwayRequest ? 'Pronto p/ Retirada' : 'Saiu p/ Entrega',
      time: takeAwayRequest 
        ? events.find((ev) => ev.EVENTO === 'READ_TO_PICKUP')?.DATAHORA
        : events.find((ev) => ev.EVENTO === 'DISPATCHED')?.DATAHORA,
      condition: takeAwayRequest 
        ? Boolean(events.find((ev) => ev.EVENTO === 'READ_TO_PICKUP'))
        : Boolean(events.find((ev) => ev.EVENTO === 'DISPATCHED'))
    }
  ]
  
  return ReactDOM.createPortal((
      <RequestStatusContainer>
        <AgilePageLayout goBack={close}>
          <div className="content_wrapper">
            <div className="texts">
              <h2>Status do Pedido ({request.Id})</h2>
              <p>Você pode avaliar o seu pedido agora. Conte-nos como foi sua experiência.</p>
            </div>
            <div className="request_status">
              {
                eventsInfo.map((ev) => (
                  <div className="event" key={ev.text}>
                    <div className="event_icon">
                      <img src={ev.image} alt="Imagem do evento" />
                    </div>
                    <div className="event_condition">
                      {
                        ev.condition ? (
                          <CheckCircle color="#44aa66" />
                        ) : (
                          <XCircle color="#aa0022" />
                        )
                      }
                      {
                        ev.time && <p className="time">{ev.time.split('T')[1].substring(0, 5)}</p>
                      }
                    </div>
                    <p>{ev.text}</p>
                  </div>
                ))
              }
            </div>
            <ActionButtons
              close={close}
              action={openOrderEvaluation}
              closeText="Voltar"
              actionText="Avaliar Pedido"
            />
          </div>
        </AgilePageLayout>
      </RequestStatusContainer>
  ), requestStatusPortal)
}

export default RequestStatus