/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OverlayNotification from '../../components/OverlayNotification'
import ScaleLoader from '../../components/ScaleLoader'
import { RootState } from '../../store/store'
import { Request } from '../../types/requests'
import { StoreParams } from '../../types/storeInfo'
import EmptyList from './components/EmptyList'
import Header from './components/Header'
import RequestCard from './components/RequestCard'
import StoreListing from './components/StoreListing'
import { RequestsContainer } from './styles'

function Requests() {
  const { generalLink } = useSelector((state: RootState) => state.token)
  const { accessToken, selectedAddress } = useSelector((state: RootState) => state.user)

  const [requests, setRequests] = useState<Request[]>([])
  const [stores, setStores] = useState<StoreParams[]>([])
  const [isRequestsLoading, setIsRequestsLoading] = useState<boolean>(false)
  const [isStoresLoading, setIsStoresLoading] = useState<boolean>(false)

  const [error, setError] = useState<string>('')

  const requestsPageContainerRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  async function searchStores() {
    const env = process.env.REACT_APP_ACCOUNT_STORES

    if (!env) return

    setIsStoresLoading(true)

    try {
      const response = await axios({
        method: 'POST',
        url: `${generalLink}/${env}`,
        data: selectedAddress,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      setStores(response.data)
      setIsStoresLoading(false)
    } catch (err) {
      setIsStoresLoading(false)
    }
  }

  async function searchRequests() {
    const env = process.env.REACT_APP_REQUESTS

    if (!env) {
      return setError('Algum erro ocorreu durante a busca dos pedidos, tente novamente mais tarde.')
    }

    setIsRequestsLoading(true)

    try {
      const response = await axios({
        method: 'POST',
        url: `${generalLink}/${env}`,
        data: selectedAddress,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      setRequests(response.data)
      setIsRequestsLoading(false)
    } catch (err) {
      setIsRequestsLoading(false)
    }
  }

  function refresh() {
    searchRequests()
    searchStores()
  }

  useEffect(() => {
    if (!accessToken) return

    searchRequests()
  }, [accessToken, selectedAddress])

  useEffect(() => {
    if (!accessToken) return

    searchStores()
  }, [accessToken, selectedAddress])

  return (
    <RequestsContainer ref={requestsPageContainerRef}>
      <Header goBack={() => navigate('/')} refresh={refresh} />
      {
        (isRequestsLoading || isStoresLoading)
          ? <ScaleLoader />
          : requests.length > 0
            ? (
              <div className="page_context">
                {
                  stores.length > 0 && (
                    <div className="stores">
                      <h2>Lojas mais pedidas</h2>
                      <StoreListing stores={stores} />
                    </div>
                  )
                }
                <div className="items">
                  <h2>Histórico</h2>
                  <div className="item_list">
                    {requests.map((request) => (
                      <RequestCard
                        key={`${request.CdPed}${request.DataHora}`}
                        request={request}
                        stores={stores}
                        containerRef={requestsPageContainerRef}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )
            : (
              <EmptyList />
            )
      }
      {
        error && (
          <OverlayNotification text={error} close={() => navigate('/')} />
        )
      }
    </RequestsContainer>
  )
}

export default Requests
