import { useEffect, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { StoreParams } from '../../../types/storeInfo'
import StoreCard from './StoreCard'

type StoreListProps = {
  stores: StoreParams[]
}

function StoreListing({ stores }: StoreListProps) {
  const [pageWidth, setPageWidth] = useState<number>(0)

  useEffect(() => {
    setPageWidth(window.innerWidth)

    function handlePageResize (e: UIEvent) {
      const target = e.target as Window

      setPageWidth(target.innerWidth)
    }

    window.addEventListener('resize', handlePageResize)

    return () => {
      window.removeEventListener('resize', handlePageResize)
    }
  }, [])

  return (
    <div className="store_listing">
      <ScrollContainer
        hideScrollbars={false}
        className={`scroll_container ${pageWidth > 1024 ? 'custom_scrollbar' : undefined}`}
      >
        {stores.map((store) => {
          const storeBackgroundColor = store.Parametros.find((param) => param.Chave === 'MV_COLOR_BACKGROUND')?.Valor
          
          return (
            <StoreCard
              key={`${store.Nome}${store.Id}`}
              name={store.Nome}
              image={store.LogoUri}
              bg={storeBackgroundColor}
            />
          )
        })}
      </ScrollContainer>
    </div>
  )
}

export default StoreListing