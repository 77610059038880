import { ChevronLeft } from 'lucide-react'
import { ReactNode } from 'react'

import { LayoutContainer } from './styles'

import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import Logo from '../Logo'

type LayoutProps = {
  children: ReactNode,
  goBack?: () => void,
  hideItemStructure?: boolean,
  noGoBack?: boolean,
  useProfileHeader?: boolean
}

function AgilePageLayout ({ children, goBack, hideItemStructure, noGoBack, useProfileHeader }: LayoutProps) {
  const { userData } = useSelector((state: RootState) => state.user)

  return (
    <LayoutContainer hideItemStructure={hideItemStructure}>
      <header className="page_context">
        {
          !noGoBack && (
            <button type="button" onClick={goBack}>
              <ChevronLeft size={36} color="#ffffff" />
            </button>
          )
        }
      </header>
      <div className="container small_context">
        <div className="logo">
          <Logo />
          {
            useProfileHeader && <p>Olá {userData.CompleteUser.Usuario.Nome}</p>
          }
        </div>
        {children}
      </div>
    </LayoutContainer>
  )
}

export default AgilePageLayout
