import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../store/store'
import AccountAction from './components/AccountAction'
import AddressList from './components/AddressList'
import EditSection from './components/EditSection'
import ProfileHeader from './components/ProfileHeader'
import { ProfilePageContainer } from './styles'

function Profile() {
  const navigate = useNavigate()

  const { userData } = useSelector((state: RootState) => state.user)

  return (
    <ProfilePageContainer>
      {
        userData.CompleteUser && (
          <>
            <ProfileHeader userName={userData.CompleteUser.Usuario.Nome} />
            <EditSection
              editProfileAction={() => navigate('editar')}
              changePasswordAction={() => navigate('senha')}
            />
            <AddressList addresses={userData.CompleteUser.Usuario.Enderecos} />
            <AccountAction />
          </>
        )
      }
    </ProfilePageContainer>
  )
}

export default Profile
