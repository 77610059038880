import { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import Field from './Field'
import { ErrorType } from '../hooks/useFormError'
import { RootState } from '../store/store'
import RedButton from './RedButton'
import styled from 'styled-components'

type ChangePasswordProps = {
  changePasswordProps: {
    handleUpdatePassword: (newPassword: string, confirmPassword: string) => void,
    passwordError: ErrorType | undefined,
    confirmPasswordError: ErrorType | undefined,
    hideName?: boolean
  }
}

const ChangePasswordContainer = styled.div`
  .identification_box {
    color: #464646;

    text-align: center;

    font-size: 1.3rem;
  }

  .label {
    color: #464646;

    font-size: 1.2rem;
    font-weight: 700;

    text-align: center;

    margin: 1rem 0 2rem;
  }
`

function ChangePassword({ changePasswordProps }: ChangePasswordProps) {
  const { handleUpdatePassword, passwordError, confirmPasswordError, hideName } = changePasswordProps

  const { userData } = useSelector((state: RootState) => state.user)

  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const passwordFieldProps = {
    value: password,
    placeholder: 'Digite sua senha...',
    type: 'password',
    change: (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    clear: () => setPassword(''),
    error: passwordError
  }

  const confirmPasswordFieldProps = {
    value: confirmPassword,
    placeholder: 'Confirme sua senha...',
    type: 'password',
    change: (e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value),
    clear: () => setConfirmPassword(''),
    error: confirmPasswordError
  }

  function handleChangePassword() {
    handleUpdatePassword(password, confirmPassword)
  }

  return (
    <ChangePasswordContainer>
      <div className="identification_box">
        {
          !hideName && (
            <p>Olá, <strong>{userData.CompleteUser.Usuario.Nome}!</strong></p>
          )
        }
      </div>
      <p className="label">Altere sua senha informando a nova nos campos abaixo.</p>
      <Field fieldProps={passwordFieldProps} />
      <Field fieldProps={confirmPasswordFieldProps} />
      <RedButton
        type="button"
        onClick={handleChangePassword}
      >
        Alterar
      </RedButton>
    </ChangePasswordContainer>
  )
}
export default ChangePassword
