import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper';

import { Banner } from '../../../types/banners'

import "swiper/css";
import "swiper/css/pagination";
import { ItemSliderContainer } from '../styles';
import { useEffect, useState } from 'react';

type ItemSliderProps = {
  items: Banner[]
}


function ItemSlider({ items }: ItemSliderProps) {
  const [pageWidth, setPageWidth] = useState<number>()
  const [pagination, setPagination] = useState<boolean>(false)

  const swiperBreakpoints = {
    470: {
      slidesPerView: 1.4
    },
    560: {
      slidesPerView: 1.6
    },
    650: {
      slidesPerView: 1.8
    },
    720: {
      slidesPerView: 2
    },
    800: {
      slidesPerView: 2.2
    },
    870: {
      slidesPerView: 2.4
    },
    930: {
      slidesPerView: 2.6
    },
    1000: {
      slidesPerView: 2.8
    }
  }

  const swiperStyles = {
    width: '100%'
  }

  const autoPlayConfig = {
    delay: 5000,
    disableOnInteraction: false
  }

  useEffect(() => {
    setPageWidth(window.innerWidth)

    function handleResizeEvent (event: UIEvent) {
      const eventTarget = event.target as Window

      setPageWidth(eventTarget.innerWidth)
    }

    window.addEventListener('resize', handleResizeEvent)

    return () => {
      window.removeEventListener('resize', handleResizeEvent)
    }
  }, [])

  useEffect(() => {
    if (!pageWidth) return

    if (pageWidth >= 720) {
      setPagination(true)
    } else {
      setPagination(false)
    }
  }, [pageWidth])

  return (
    <ItemSliderContainer>
      <Swiper
        slidesPerView={1.2}
        spaceBetween={15}
        centeredSlides
        loop
        autoplay={autoPlayConfig}
        modules={[EffectFade, Autoplay, Pagination]}
        pagination={pagination ? { clickable: true } : false}
        style={swiperStyles}
        breakpoints={swiperBreakpoints}
      >
        {
          items.map((item, index) => (
            <SwiperSlide key={`slide-${index}`}>
              {({ isActive }) => (
                <div className={`slider_item ${isActive ? 'active_item' : undefined}`}>
                  <img src={item.ImageUrl} alt="Item em destaque" />
                </div>
              )}
            </SwiperSlide>
          ))
        }
      </Swiper>
    </ItemSliderContainer>
  )
}

export default ItemSlider