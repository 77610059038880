/* eslint-disable react-hooks/exhaustive-deps */

import { Building } from 'lucide-react'
import { ChangeEvent, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Location } from '..'
import SearchBar from '../../../components/SearchBar'

import { SearchAddressContainer } from '../styles'
import { InformationType } from './AddressForm'

type SearchAddressProps = {
  searchValue: string,
  changeSearchValue: (e: ChangeEvent<HTMLInputElement>) => void,
  clearSearchValue: () => void,
  close: () => void,
  information: InformationType,
  cleanInfo: () => void,
  isLoading: boolean,
  cleanLoading: () => void,
  setValue: (value: Location) => void,
  isCitySearch: boolean
}

function SearchAddress ({
  searchValue,
  changeSearchValue,
  clearSearchValue,
  close,
  information,
  cleanInfo,
  isLoading,
  cleanLoading,
  setValue,
  isCitySearch
}: SearchAddressProps) {
  const searchAddressPortal = document.getElementById('search_portal')

  useEffect(() => {
    return () => {
      cleanInfo()
      clearSearchValue()
      cleanLoading()
    }
  }, [])

  if (!searchAddressPortal) return null

  return ReactDOM.createPortal((
    <SearchAddressContainer>
      <SearchBar
        searchValue={searchValue}
        handleChangeSearchValue={changeSearchValue}
        cleanSearchValue={clearSearchValue}
        handleGoBack={close}
        placeholder={isCitySearch ? 'Digite o nome da cidade' : 'Digite o nome do bairro'}
      />
      <div className="items page_context">
        {
          information.length > 0
            ? information.map((info) => (
            <button type="button" key={info.c} onClick={() => setValue(info)}>
              <Building size={24} />
              <span>{info.d}</span>
            </button>
            ))
            : searchValue.length < 4
              ? (
                  <div className="span_wrapper">
                    <span>Para realizar a pesquisa o texto tem que ter mais que 4 caracteres.</span>
                  </div>
                )
              : !isLoading
                  ? (
                  <div className="span_wrapper">
                    <span>{isCitySearch ? 'Nenhuma cidade encontrada' : 'Nenhum bairro encontrado'}</span>
                  </div>
                    )
                  : (
                  <div className="span_wrapper">
                    <span>Carregando...</span>
                  </div>
                    )
        }
      </div>
    </SearchAddressContainer>
  ), searchAddressPortal)
}

export default SearchAddress
