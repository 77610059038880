import axios from 'axios'
import { GetTokenParams } from './tokenSlice'

async function getToken (getTokenParams: GetTokenParams) {

  const response = await axios({
    method: 'POST',
    url: process.env.REACT_APP_TOKEN,
    data: getTokenParams
  })

  return response
}

export const tokenService = {
  getToken
}