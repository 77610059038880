import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropsParams, TokenResponse } from '../../../types/authApi';
import { StoreParams } from '../../../types/storeInfo';
import { tokenService } from './tokenService';

export type GetTokenParams = {
  userName: string,
  password: string,
  versaoApi: string
}

const initialState = {
  generalToken: '',
  generalLink: '',
  generalCode: 0,
  storeToken: {} as TokenResponse,
  storeProps: {} as PropsParams,
  storeParams: {
    name: '',
    api: '',
    store: '',
    item: '',
    complement: '',
    background: '',
    bgColor: '',
    logo: ''
  },
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: ''
}

export const getGeneralToken = createAsyncThunk(
  'token/general',
  async (getGeneralTokenParams: GetTokenParams, thunkApi) => {
    try {
      const response = await tokenService.getToken(getGeneralTokenParams)

      if (response.status !== 200) {
        throw new Error('O Agile Delivery Web não está disponível no momento.')
      }

      return response.data
    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
)

export const getStoreToken = createAsyncThunk(
  'token/store',
  async (getStoreTokenParams: GetTokenParams, thunkApi) => {
    try {
      const response = await tokenService.getToken(getStoreTokenParams)

      if (response.status !== 200) {
        throw new Error('Essa loja não está disponível no momento.')
      }

      return response.data
    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
)

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    clearStoreToken: (state) => {
      state.storeToken = {} as TokenResponse
    },
    setUrl: (state, action: PayloadAction<TokenResponse>) => {
      if (!action.payload.Perfis) return

      const url = action.payload.Perfis.find((profile: any) => (profile.Ambiente === process.env.REACT_APP_ENV))?.Url

      if (url) {
        state.storeParams = {
          ...state.storeParams,
          api: url
        }
      }
    },
    setResourcesUri: (state, action: PayloadAction<StoreParams>) => {
      state.storeParams = {
        ...state.storeParams,
        name: action.payload.Nome,
        store: action.payload.Parametros?.find((param) => param.Chave === 'MV_HOST_FOTO_LOJA')?.Valor || '',
        item: action.payload.Parametros?.find((param) => param.Chave === 'MV_HOST_FOTO_ITEM')?.Valor || '',
        complement: action.payload.Parametros?.find((param) => param.Chave === 'MV_HOST_FOTO_COMPLEMENTO')?.Valor || '',
        background: action.payload.Parametros?.find((param) => param.Chave === 'MV_HOST_FOTO_CAPA')?.Valor || '',
        bgColor: action.payload.Parametros?.find((param) => param.Chave === 'MV_COLOR_BACKGROUND')?.Valor || '',
        logo: action.payload.LogoUri
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralToken.pending, (state) => {
        state.isLoading = true
      })
      
      .addCase(getGeneralToken.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.generalToken = action.payload.access_token
        state.generalLink = action.payload.Perfis.find((profile: any) => (profile.Ambiente === process.env.REACT_APP_ENV)).Url
        state.generalCode = action.payload.CodigoExterno
      })

      .addCase(getGeneralToken.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action)
      })

      .addCase(getStoreToken.pending, (state) => {
        state.isLoading = true
      })

      .addCase(getStoreToken.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.storeToken = action.payload
      })

      .addCase(getStoreToken.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action)
      })
  }
})

export default tokenSlice.reducer
export const { clearStoreToken, setUrl, setResourcesUri } = tokenSlice.actions
