import axios from 'axios';

async function getAllStores (generalToken: string, url: string) {
  const env = process.env.REACT_APP_STORES

  if (!env) return

  const data = {
    Id: 6,
    Cep: '15025000',
    Uf: 'SP',
    Numero: '4270',
    Complemento: null,
    Log: 'AVENIDA BADY BASSITT',
    Ativo: true,
    Bai: {
      c: 24703.0,
      d: 'BOA VISTA'
    },
    Cid: {
      c: 9659.0,
      d: 'SAO JOSE DO RIO PRETO'
    },
    Location: {
      Latitude: -208147462.0,
      Longitude: -493919145.0
    }
  }

  const response = await axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${generalToken}`
    },
    url: `${url}/${env}`,
    data
  })

  return response
}

async function getStoreByCategory (generalToken: string, category: string, url: string) {
  const env = process.env.REACT_APP_STORES_BY_CATEGORY

  if (!env) return

  const data = {
    Id: 6,
    Cep: '15025000',
    Uf: 'SP',
    Numero: '4270',
    Complemento: null,
    Log: 'AVENIDA BADY BASSITT',
    Ativo: true,
    Bai: {
      c: 24703.0,
      d: 'BOA VISTA'
    },
    Cid: {
      c: 9659.0,
      d: 'SAO JOSE DO RIO PRETO'
    },
    Location: {
      Latitude: -208147462.0,
      Longitude: -493919145.0
    }
  }

  const response = await axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${generalToken}`
    },
    url: `${url}/${env}/${category}`,
    data
  })

  return response
}

async function getGeneralParams (token: string, domain: string) {
  const env = process.env.REACT_APP_PARAM

  if (!env || !token || !domain) return

  const response = await axios({
    method: 'GET',
    url: `${domain}/${env}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

const storesService = {
  getAllStores,
  getStoreByCategory,
  getGeneralParams
}

export default storesService