import axios from 'axios'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ConfirmationModal from '../../../components/ConfirmationModal'
import Notification from '../../../components/Notification'
import ScaleLoader from '../../../components/ScaleLoader'
import { setNotification } from '../../../store/features/notification/notificationSlice'
import { removeAddress } from '../../../store/features/user/userSlice'
import { AppDispatch, RootState } from '../../../store/store'
import { Address } from '../../../types/address'
import { AddressListContainer } from '../styles'
import AddressItem from './AddressItem'

type AddressListProps = {
  addresses: Address[]
}

function AddressList({ addresses }: AddressListProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const { accessToken, selectedAddress } = useSelector((state: RootState) => state.user)
  const { generalLink } = useSelector((state: RootState) => state.token)
  const { notification } = useSelector((state: RootState) => state.notification)

  const [isDeleteAddressLoading, setIsDeleteAddressLoading] = useState<boolean>(false)

  const [addressToBeDeleted, setAddressToBeDeleted] = useState<Address>()

  async function deleteAddress (addressId: string | undefined) {
    if (!addressId) return

    if (addressId === selectedAddress.Id) {
      dispatch(setNotification('Você não pode excluir o endereço atual.'))
      setAddressToBeDeleted(undefined)
      return
    }

    setIsDeleteAddressLoading(true)
    
    await axios({
      method: 'DELETE',
      url: `${generalLink}/${process.env.REACT_APP_DEL_ADDRESS}/${addressId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    dispatch(removeAddress(addressId))

    setIsDeleteAddressLoading(false)
    setAddressToBeDeleted(undefined)
  }

  return (
    <AddressListContainer>
      <div className="page_context">
        <div className="address_list_header">
          <h2>Endereços</h2>
          <button
            type="button"
            onClick={() => navigate('/endereco')}
          >
            <Plus size={18} />
            Adicionar novo endereço
          </button>
        </div>
        <div className="listing">
          {
            addresses.map((address) => (
              <AddressItem
                key={address.Id}
                address={address}
                deleteAddress={() => setAddressToBeDeleted(address)}
                isActive={selectedAddress.Id === address.Id}
              />
            ))
          }
        </div>
        {
          isDeleteAddressLoading && (
            <ScaleLoader />
          )
        }
        {
          addressToBeDeleted && (
            <ConfirmationModal
              heading="Endereço"
              confirmationText="Deseja remover este endereço?"
              additionalInfo={
                `
                  ${addressToBeDeleted.Log.toUpperCase()}${addressToBeDeleted.Numero && `, ${addressToBeDeleted.Numero}`}
                `
              }
              yes={() => deleteAddress(addressToBeDeleted.Id)}
              no={() => setAddressToBeDeleted(undefined)}
            />
          )
        }
        {
          notification && (
            <Notification
              text={notification}
            />
          )
        }
      </div>
    </AddressListContainer>
  )
}
export default AddressList