import axios from 'axios'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AgilePageLayout from '../../../components/AgilePageLayout'
import ChangePassword from '../../../components/ChangePassword'
import OverlayNotification from '../../../components/OverlayNotification'
import ScaleLoader from '../../../components/ScaleLoader'
import useFormError from '../../../hooks/useFormError'
import { RootState } from '../../../store/store'
import { validateConfirmPasswordField, validatePasswordField } from '../../../utils/fieldValidations'

function ModifyPassword() {
  const navigate = useNavigate()
  
  const { accessToken, userData } = useSelector((state: RootState) => state.user)
  const { generalLink } = useSelector((state: RootState) => state.token)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const [
    setNewError,
    getErrorByFieldname,
    cleanErrorsByFieldname
  ] = useFormError()

  function validateFields (password: string, confirmPassword: string) {
    const isPasswordValid = validatePasswordField(
      password,
      setNewError,
      cleanErrorsByFieldname
    )

    const isConfirmPasswordValid = validateConfirmPasswordField(
      confirmPassword,
      password,
      setNewError,
      cleanErrorsByFieldname
    )

    return (isPasswordValid && isConfirmPasswordValid)
  }
  
  async function handleUpdatePassword (password: string, confirmPassword: string) {
    if (!validateFields(password, confirmPassword)) return

    const env = process.env.REACT_APP_CHANGE_PASSWORD

    if (!env || !accessToken) return

    setIsLoading(true)

    try {
      const changePasswordData = {
        Login: userData.email,
        Nova: password,
        ConfirmaNova: confirmPassword
      }

      await axios({
        method: 'PUT',
        url: `${generalLink}/${env}`,
        data: changePasswordData,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      setIsLoading(false)
      setSuccess(true)
    } catch (err) {
      setIsLoading(false)
    }
  }
  
  const changePasswordProps = {
    handleUpdatePassword,
    passwordError: getErrorByFieldname('password'),
    confirmPasswordError: getErrorByFieldname('confirmPassword'),
    hideName: true
  }

  return (
    <AgilePageLayout
      goBack={() => navigate('/perfil')}
      useProfileHeader
    >
      <ChangePassword changePasswordProps={changePasswordProps} />
      {
        isLoading && <ScaleLoader />
      }
      {
      success && (
        <OverlayNotification
          text={"Senha modificada com sucesso."}
          close={() => navigate('/perfil')}
        />
      )
      }
    </AgilePageLayout>
  )
}

export default ModifyPassword