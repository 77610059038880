import ReactDOM from 'react-dom'
import styled from 'styled-components'
import Overlay from './Overlay'

const OverlayNotificationContainer = styled.div`
  background: #ffffff;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: min(90vw, 45rem);
  min-height: 16rem;

  padding: 1.6rem;
  margin: 0 auto;

  h3 {
    font-size: 1.6rem;

    margin-bottom: 3rem;
  }

  p {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .button-wrapper {
    text-align: right;

    button {
      background: none;

      color: #4472ad;
      font-weight: 700;
    }
  }
`

type OverlayNotificationProps = {
  text: string,
  close: () => void
}

function OverlayNotification ({ text, close }: OverlayNotificationProps) {
  const overlayNotificationPortal = document.getElementById('warning_portal')

  if (!overlayNotificationPortal) return null

  return ReactDOM.createPortal((
    (
      <Overlay
        onMouseDown={close}
        onTouchStart={close}
      >
        <OverlayNotificationContainer>
          <div className="texts">
            <h3>Atenção</h3>
            <p>{text}</p>
          </div>
          <div className="button-wrapper">
            <button type="button" onClick={close}>OK</button>
          </div>
        </OverlayNotificationContainer>
      </Overlay>
    )
  ), overlayNotificationPortal)
}

export default OverlayNotification
