import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Router from './components/Router'
import ScaleLoader from './components/ScaleLoader'
import { getCardapio } from './store/features/cardapio/cardapioSlice'
import { defineAppStoreData, getStoreInfo } from './store/features/info/infoSlice'
import { getGeneralToken, getStoreToken, setResourcesUri, setUrl } from './store/features/token/tokenSlice'
import { loginWithRefreshToken, setAddress, setOfflineAddress, toggleAttemptAddress } from './store/features/user/userSlice'
import { AppDispatch, RootState } from './store/store'
import GlobalStyle from './styles/GlobalStyle'
import { theme } from './styles/theme'

import appsMock from './mocks/apps.json'

function App() {
  const { generalToken, generalCode, storeToken, storeParams, generalLink, isLoading: isTokenLoading } = useSelector((state: RootState) => state.token)
  const { userData, selectedAddress, isLoading: isUserLoading } = useSelector((state: RootState) => state.user)
  const { params, isLoading: isInfoLoading, appParams } = useSelector((state: RootState) => state.info)
  const { type, isLoading: isCardapioLoading } = useSelector((state: RootState) => state.cardapio)
  const { isLoading: isItemLoading } = useSelector((state: RootState) => state.item)
  const { isLoading: isStoresLoading } = useSelector((state: RootState) => state.stores)
  const { stores } = useSelector((state: RootState) => state.stores)
  const [rgb, setRgb] = useState<string>();
  const [isThereAnApp, setIsThereAnApp] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      navigate('/')

      firstRender.current = false
    }
  }, [navigate])

  useEffect(() => {
    if (storeToken && !storeParams.api) {
      dispatch(setUrl(storeToken))
    }
  }, [storeToken, storeParams.api, dispatch])
  
  // general token gathering
  useEffect(() => {
    const domain = window.location.hostname
    const currentApp = appsMock.apps.find((app) => app.domain === domain)?.data
    //const currentApp = appsMock.apps.find((app) => true)?.data

    if (currentApp) {
      setIsThereAnApp(true)
    }

    const userName = currentApp?.username || process.env.REACT_APP_GENERAL_TOKEN_USERNAME
    const password = currentApp?.password || process.env.REACT_APP_GENERAL_TOKEN_PASSWORD
    const versaoApi = currentApp?.version || process.env.REACT_APP_GENERAL_TOKEN_VERSION
    
    if (!password || !userName || !versaoApi) return
    
    const data = {
      userName,
      password,
      versaoApi
    }

    dispatch(getGeneralToken(data))
  }, [dispatch])

  useEffect(() => {
    if (isThereAnApp && generalToken && generalCode && selectedAddress && generalLink) {
      const defineAppStoreDataProps = {
        token: generalToken,
        body: selectedAddress,
        storeId: String(generalCode),
        domain: generalLink
      }

      
      dispatch(defineAppStoreData(defineAppStoreDataProps))
    } 
  }, [isThereAnApp, generalLink, selectedAddress, generalToken, generalCode, dispatch])

  // refresh token login
  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token')
    const isThereAOfflineAddress = localStorage.getItem('offline_address')

    if (!generalLink) return

    if (!refreshToken) {
      if (!isThereAOfflineAddress) {
        dispatch(toggleAttemptAddress())
      }

      return
    }

    const loginWithRefreshTokenParams = {
      token: refreshToken,
      domain: generalLink
    }

    dispatch(loginWithRefreshToken(loginWithRefreshTokenParams))
  }, [dispatch, generalLink])

  // set addresses
  useEffect(() => {
    let selectedAddress

    if (userData.unique_name) {
      selectedAddress = localStorage.getItem(`selected_address_${userData.unique_name}`)
      dispatch(setAddress(selectedAddress))
    } else {
      selectedAddress = localStorage.getItem('offline_address')

      if (selectedAddress) {
        const parsedAddress = JSON.parse(selectedAddress)

        dispatch(setOfflineAddress(parsedAddress))
      }
    }
  }, [userData, dispatch])

  // get store token
  useEffect(() => {
    if (!params || !params.Parametros) return

    const domain = window.location.hostname
    const currentApp = appsMock.apps.find((app) => app.domain === domain)?.data
    
    //Trocado username e versãoapi que estavam usando o .env, agora usam o appsMock.json
    // const userName = process.env.REACT_APP_STORE_TOKEN_USERNAME
    const userName = currentApp?.username;
    const password = params.Parametros.find((param) => param.Chave === 'MV_NROLICENCA')?.Valor
    // const versaoApi = process.env.REACT_APP_STORE_TOKEN_VERSION
    const versaoApi = currentApp?.version

    if (!userName || !password || !versaoApi) return

    dispatch(getStoreToken({ userName, password, versaoApi }))
  }, [params, dispatch])

  // get store info, menu and resources
  useEffect(() => {
   
    if (!params || !params?.Nome || !generalLink || !storeToken.access_token) return

    const getStoreInfoParams = {
      token: storeToken.access_token,
      domain: generalLink
    }

    dispatch(getStoreInfo(getStoreInfoParams))

    if (storeParams.api) {
      const getCardapioParams = {
        option: type,
        token: storeToken.access_token,
        domain: storeParams.api
      }

      dispatch(getCardapio(getCardapioParams))
    }

    dispatch(setResourcesUri(params))
  }, [params, dispatch, generalLink,  type, storeToken, storeParams.api])

  const loading = (isTokenLoading || isUserLoading || isInfoLoading || isCardapioLoading || isItemLoading || isStoresLoading)
  

  useEffect(()=> {
    if(!stores || !generalCode) return
    
    const store:any = stores.filter((store:any) => store.CodigoExterno === generalCode);
    
    if(!store) return

    if(store.length > 0){
      const Rgb = '#' + store
      .map((item:any) => item.Parametros
      .find((param:any) => param.Chave === 'MV_COLOR_BACKGROUND')?.Valor);
      
      
      setRgb(Rgb);
    }
    
    
  },[generalCode, rgb, stores])

  
  let currentTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: {
        ...theme.colors.primary,
        normal: appParams.background || rgb || theme.colors.primary.normal
      }
    }
  }

  return (
    <div className="App">
      <ThemeProvider theme={currentTheme}>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
      {
        loading && (
          <ScaleLoader />
        )
      }
    </div>
  );
}

export default App;
