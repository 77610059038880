import { MapPin } from 'lucide-react'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import imageError from '../../../../assets/icons/image-error.png'
import { StoreButtonContainer } from '../../styles'

type StoreButtonProps = {
  image: string,
  log: string,
  number: string,
  name: string,
  location: number,
  iconColor: string | undefined,
  action: () => void
}

function StoreButton({ image, log, number, name, location, iconColor, action }: StoreButtonProps) {
  const [photoError, setPhotoError] = useState<boolean>(false)

  return (
    <StoreButtonContainer
      type="button"
      onClick={action}
      iconColor={iconColor || 'ffffff'}
    >
      <div className="icon">
        <LazyLoadImage
          src={!photoError ? image : imageError}
          alt="Imagem da loja"
          className={photoError ? 'image_error' : undefined}
          onError={() => setPhotoError(true)} />
      </div>
      <div className="desc">
        <strong>{name.toUpperCase()}</strong>
        <div className="address_info">
          <MapPin size={18} />
          <span>{log}, {number}</span>
        </div>
      </div>
      <span className="distance">{location} km</span>
    </StoreButtonContainer>
  )
}

export default StoreButton