import { List, Search, ShoppingCart } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import PageNavigation from './PageNavigation'
import { FooterMenuContainer } from '../styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

type FooterMenuProps = {
  type: string
}

function FooterMenu ({ type }: FooterMenuProps) {
  const { accessToken } = useSelector((state: RootState) => state.user)
  const { withdrawCart, deliveryCart, driveCart } = useSelector((state: RootState) => state.cart)

  const [isPageNavigationMenuOpen, setIsPageNavigationMenuOpen] = useState<boolean>(false)

  const navigate = useNavigate()

  function goToSearchProducts () {
    navigate('/pesquisa')
  }

  function handleNavigateToCart () {
    navigate(`/carrinho/${type}`)
  }

  const isThereAnyItemOnTheCurrentMenuType =
    (type === 'retirada' && withdrawCart.length > 0) ||
    (type === 'entrega' && deliveryCart.length > 0) ||
    (type === 'drive' && driveCart.length > 0)

  return (
    <FooterMenuContainer>
      {
        accessToken && isThereAnyItemOnTheCurrentMenuType && (
          <div className="cart_button_wrapper">
            <button type="button" id="cart_button" onClick={handleNavigateToCart}>
              <ShoppingCart size={24} color="#ffffff" />
            </button>
          </div>
        )
      }
      <div className="action_buttons page_context">
        <button type="button" className="search" onClick={goToSearchProducts}>
          <Search size={24} color="#121212" />
        </button>

        <button type="button" onClick={() => setIsPageNavigationMenuOpen(true)} className="categories">
          <List size={24} color="#121212" />
        </button>
      </div>
      { isPageNavigationMenuOpen && <PageNavigation closeNavigation={() => setIsPageNavigationMenuOpen(false)} /> }
    </FooterMenuContainer>
  )
}

export default FooterMenu
