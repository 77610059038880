import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { ItemCardapio } from '../../../../types/cardapio'

type HalfItemButtonProps = {
  item: ItemCardapio,
  action: (arg: string) => void
}

function HalfItemButton({ item, action }: HalfItemButtonProps) {
  const { storeParams } = useSelector((state: RootState) => state.token)

  const [isImageError, setIsImageError] = useState<boolean>(false)

  return (
    <button
      className="item_card"
      key={item.Codigo}
      onClick={() => action(item.Nome)}
    >
      {
        !isImageError && (
          <div className="image">
            <img
              src={`${storeParams.item}/${item.Codigo}.jpg`}
              alt="Imagem do item"
              onError={() => setIsImageError(true)}
            />
          </div>
        )
      }
      <div className="item_description">
        <p className="title">{item.Nome}</p>
        <p className="more">{item.ReceitaCurta}</p>
      </div>
    </button>
  )
}

export default HalfItemButton
