import { ReactNode } from 'react'
import styled from 'styled-components'

type RedButtonProps = {
  children: ReactNode,
  type?: 'button' | 'submit' | 'reset',
  onClick?: () => void
}

const RedButtonContainer = styled.button`
  background: #ff0000;
  border-radius: 3rem;
  color: #ffffff;
  
  font-size: 1.4rem;
  font-weight: 700;

  padding: 1rem;

  width: 100%;

  box-shadow: 0px 0px 9px 2px rgb(0 0 0 / 6%);
`

function RedButton({ children, ...props }: RedButtonProps) {
  return (
    <RedButtonContainer {...props}>
      {children}
    </RedButtonContainer>
  )
}
export default RedButton