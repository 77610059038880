/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Location, LocationType } from '..'
import OverlayNotification from '../../../components/OverlayNotification'
import ScaleLoader from '../../../components/ScaleLoader'
import useFormError from '../../../hooks/useFormError'
import { validateEmptyField } from '../../../utils/fieldValidations'
import { dneInstance } from '../dneInstance'
import { FieldPattern } from '../styles'
import ConfirmAddress from './ConfirmAddress'
import FAField from './FAField'
import PlaceButton from './PlaceButton'
import SearchAddress from './SearchAddress'

export type FormValuesType = {
  uf: string,
  city: Location,
  street: string,
  neighbourhood: Location,
  number: string,
  complement: string
}

const InitialFormValues: FormValuesType = {
  uf: '',
  city: {
    c: 0,
    d: ''
  },
  street: '',
  neighbourhood: {
    c: 0,
    d: ''
  },
  number: '',
  complement: ''
}

const InitialFieldFocus = {
  uf: false,
  street: false,
  complement: false,
  number: false
}

export type InformationType = {
  c: number,
  d: string
}[]

type AddressFormProps = {
  location: LocationType
}

type UfsType = {
  d: string
}[]

type PlaceLocation = {
  lat: number,
  lng: number
}

const AddressFormContainer = styled.div`
  .splitted_fields {
    display: flex;

    div:first-child {
      flex: 2;

      margin-right: 1rem;
    }

    div:last-child {
      flex: 3;
    }
  }

  .submit_button {
    background: #ff0000;
    border-radius: 2rem;
    color: #ffffff;
    font-weight: 700;

    width: 100%;

    padding: .8rem 0;
    margin-top: 1rem;
  }
`

function AddressForm ({ location }: AddressFormProps) {
  const [ufs, setUfs] = useState<UfsType>([])

  const [isCitySearch, setIsCitySearch] = useState<boolean>(false)
  const [isNeighbourhoodSearch, setIsNeighbourhoodSearch] = useState<boolean>(false)
  const [isSearchAddressOpen, setIsSearchAddressOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const [information, setInformation] = useState<InformationType>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { uf, cid, log } = location

  const [formValues, setFormValues] = useState<FormValuesType>(() => ({
    ...InitialFormValues,
    uf: uf,
    city: cid,
    neighbourhood: log?.bai || {
      c: 0,
      d: ''
    },
    street: log?.d || ''
  }))

  const [fieldFocus, setFieldFocus] = useState(InitialFieldFocus)

  const [placeLocation, setPlaceLocation] = useState<PlaceLocation>()
  const [invalidPlace, setInvalidPlace] = useState<boolean>(false)

  const [
    setNewError,
    getErrorByFieldname,
    cleanErrorsByFieldname
  ] = useFormError()

  function validateFields () {
    const isUfFieldValid = validateEmptyField(
      formValues.uf,
      'uf',
      'UF é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isCityFieldValid = validateEmptyField(
      formValues.city.d,
      'city',
      'Cidade é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isStreetFieldValid = validateEmptyField(
      formValues.street,
      'street',
      'Logradouro é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isNeighbourhoodFieldValid = validateEmptyField(
      formValues.neighbourhood.d,
      'neighbourhood',
      'Bairro é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isNumberFieldValid = validateEmptyField(
      formValues.number,
      'number',
      'Número é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    return isUfFieldValid && isCityFieldValid && isNeighbourhoodFieldValid && isStreetFieldValid && isNumberFieldValid
  }

  async function handleSubmit (e: FormEvent) {
    e.preventDefault()

    if (validateFields()) {
      setIsLoading(true)

      const response = await axios({
        method: 'GET',
        url: process.env.REACT_APP_GEOCODING_API,
        params: {
          key: process.env.REACT_APP_GOOGLE_KEY,
          address: `${formValues.number} ${formValues.street}, ${formValues.neighbourhood.d}, ${formValues.city.d}, ${formValues.uf}`
        }
      })

      setIsLoading(false)

      if (response.data.results[0]) {
        setPlaceLocation(response.data.results[0].geometry.location)
      } else {
        setInvalidPlace(true)
      }
    }
  }

  function handleFormValueChange (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    switch (e.target.name) {
      case 'uf':
        setFormValues((prevState) => ({
          ...prevState,
          uf: e.target.value,
          neighbourhood: {
            ...prevState.neighbourhood,
            d: ''
          },
          city: {
            ...prevState.city,
            d: ''
          }
        }))

        break

      default:
        setFormValues((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value
        }))
    }
  }

  function handleFormValueClear (field: string) {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: ''
    }))
  }

  function handleFieldFocus (field: string) {
    setFieldFocus((prevState) => ({
      ...prevState,
      [field]: true
    }))
  }

  function handleFieldBlur (field: string) {
    setFieldFocus((prevState) => ({
      ...prevState,
      [field]: false
    }))
  }

  async function gatherCitiesInformation () {
    const response = await dneInstance({
      url: `${process.env.REACT_APP_DNE}/Cidade`,
      params: { 
        $top: 20,
        $filter: `(UFE_SG eq '${formValues.uf}') and substringof('${searchValue}',LOC_NO)`
      }
    })

    setIsLoading(false)
    setInformation(response.data)
  }

  async function gatherNeighbourhoodsInformation () {
    const response = await dneInstance({
      url: `${process.env.REACT_APP_DNE}/Bairro`,
      params: {
        $top: 20,
        $filter: `(UFE_SG eq '${formValues.uf}') and (LOC_NO eq '${formValues.city.d}') and substringof('${searchValue}',BAI_NO)`
      }
    })

    setIsLoading(false)
    setInformation(response.data)
  }

  function handleSearchAddress (e: MouseEvent<HTMLButtonElement>) {
    if (!e.currentTarget.id) return

    switch (e.currentTarget.id) {
      case 'city':
        if (!formValues.uf) return
        setIsCitySearch(true)
        break

      case 'neighbourhood':
        if (!formValues.city.d) return
        setIsNeighbourhoodSearch(true)
        break

      default: break
    }

    setIsSearchAddressOpen(true)
  }

  function setFieldValue (value: Location) {
    if (isCitySearch) {
      setFormValues((prevState) => ({
        ...prevState,
        city: value,
        neighbourhood: {
          ...prevState.neighbourhood,
          d: ''
        }
      }))

      setIsCitySearch(false)
    } else if (isNeighbourhoodSearch) {
      setFormValues((prevState) => ({
        ...prevState,
        neighbourhood: value
      }))

      setIsNeighbourhoodSearch(false)
    }

    setIsSearchAddressOpen(false)
  }

  function handleSearchValueChange (e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length < 4) setInformation([])

    setSearchValue(e.target.value)
  }

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const response = await dneInstance({
          url: '/uf'
        })

        setIsLoading(false)
        setUfs(response.data)
      } catch (err: any) {
        setIsLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (searchValue.length < 4) {
      return
    }

    let getDataTimeout: ReturnType<typeof setTimeout>

    setIsLoading(true)

    if (isCitySearch) {
      getDataTimeout = setTimeout(gatherCitiesInformation, 500)
    } else {
      getDataTimeout = setTimeout(gatherNeighbourhoodsInformation, 500)
    }

    return () => {
      clearTimeout(getDataTimeout)
    }
  }, [searchValue])

  const streetFieldProps = {
    heading: 'Logradouro',
    placeholder: 'Digite o nome da rua...',
    name: 'street',
    clear: () => handleFormValueClear('street'),
    value: formValues.street,
    onChange: handleFormValueChange,
    onFocus: () => handleFieldFocus('street'),
    onBlur: () => handleFieldBlur('street'),
    focus: fieldFocus.street,
    error: getErrorByFieldname('street')?.message
  }

  const numberFieldProps = {
    heading: 'Número',
    placeholder: 'Digite o número da residência',
    name: 'number',
    clear: () => handleFormValueClear('number'),
    value: formValues.number,
    onChange: handleFormValueChange,
    onFocus: () => handleFieldFocus('number'),
    onBlur: () => handleFieldBlur('number'),
    focus: fieldFocus.number,
    error: getErrorByFieldname('number')?.message
  }

  const complementFieldProps = {
    heading: 'Complemento',
    placeholder: 'Apto / Bloco / Casa ...',
    name: 'complement',
    clear: () => handleFormValueClear('complement'),
    value: formValues.complement,
    onChange: handleFormValueChange,
    onFocus: () => handleFieldFocus('complement'),
    onBlur: () => handleFieldBlur('complement'),
    focus: fieldFocus.complement
  }

  const searchAddressProps = {
    searchValue: searchValue,
    changeSearchValue: handleSearchValueChange,
    clearSearchValue: () => setSearchValue(''),
    close: () => setIsSearchAddressOpen(false),
    information: information,
    cleanInfo: () => setInformation([]),
    isLoading: isLoading,
    cleanLoading: () => setIsLoading(false),
    setValue: setFieldValue,
    isCitySearch
  }

  const ufError = getErrorByFieldname('uf')?.message

  return (
    <>
      <AddressFormContainer>
        <form onSubmit={handleSubmit}>
          <div className="splitted_fields">
            <FieldPattern className={fieldFocus.uf ? 'focused' : undefined}>
              <div className="box">
                <span className="heading">UF</span>
                <div className="select_wrapper">
                  <select
                    name="uf"
                    value={formValues.uf}
                    onChange={handleFormValueChange}
                    onFocus={() => handleFieldFocus('uf')}
                    onBlur={() => handleFieldBlur('uf')}
                  >
                    <option value="" disabled>Informe o UF...</option>
                    {
                      ufs.map(({ d }) => (
                        <option key={d} value={d}>{d}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              { ufError && (
                <span className="error">{ufError}</span>
              )}
            </FieldPattern>
            <PlaceButton
              value={formValues.city.d}
              id="city"
              heading="Cidade"
              placeholder="Digite o nome da cidade..."
              action={handleSearchAddress}
              error={getErrorByFieldname('city')?.message}
            />
          </div>
          <FAField info={streetFieldProps} />
          <div className="splitted_fields">
          <FAField info={numberFieldProps} />
          <FAField info={complementFieldProps} />
          </div>
          <PlaceButton
            value={formValues.neighbourhood.d}
            id="neighbourhood"
            heading="Bairro"
            placeholder="Digite o nome do bairro..."
            action={handleSearchAddress}
            error={getErrorByFieldname('neighbourhood')?.message}
          />
          <button className="submit_button" type="submit">Seguir</button>
        </form>
      </AddressFormContainer>
      {
         (isCitySearch || isNeighbourhoodSearch) && isSearchAddressOpen && information && (
          <SearchAddress {...searchAddressProps} />
         )
      }
      { isLoading && !isSearchAddressOpen && <ScaleLoader /> }
      { !isLoading && invalidPlace
        ? (
          <OverlayNotification
            text="Endereço inválido"
            close={() => setInvalidPlace(false)}
          />
          )
        : placeLocation && (
          <ConfirmAddress
            address={placeLocation}
            close={() => setPlaceLocation(undefined)}
            formValues={formValues}
            cep={location.cep}
          />
        )
      }
    </>
  )
}

export default AddressForm
