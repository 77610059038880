import styled from 'styled-components'

export const InfoContainer = styled.section`
  padding: 1.4rem;

  h3 {
    font-size: 2rem;

    margin-bottom: 1.4rem;
  }

  .address {
    margin-bottom: 2rem;
  }

  .map {
    height: 20vh;

    margin-top: 2rem;
  }

  .address_info, .business_hours > div {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 1rem;
    }
  }

  p {
    font-size: 1.2rem;

    color: #454545;
  }
`
