import styled, { css } from 'styled-components'

type CategoryContainerTypes = {
  chef: boolean
}

type CategoryItemWrapperTypes = {
  chef: boolean
}

export const CategoryItemWrapper = styled.div<CategoryItemWrapperTypes>`
  border-bottom: 1px solid #d0d0d0;
  
  display: flex;

  padding: 2rem 0;

  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  .image {
    border-radius: 1.2rem;

    min-width: 100px;
    height: 125px;

    flex: 2;

    img {
      width: 100%;
      height: 125px;
      object-fit: cover;
      object-position: 50% 50%;

      border-radius: 1.2rem;
    }

    &.image_with_error {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;

        object-fit: contain;

        opacity: .3;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin: 0 1.4rem;

    flex: 3;

    strong {
      font-size: 2rem;
    }

    .names strong {
      color: #464646;
    }

    .description {
      max-width: 250px;

      font-size: 1.2rem;

      margin: 1rem 0 3.6rem;
    }

    .pricing {

      display: flex;
      justify-content: flex-end;

      span {
        font-size: 1.2rem;
        font-weight: bolder;

        &.risked {
          text-decoration: line-through;
        }

        &.old {
          color: #ff0000;
          margin-right: 1.2rem;
        }

        &.new{
          color: #55ff00;
        }
      }

      .amplified {
        font-size: 1.4rem;
      }
    }
  }

  ${({ chef }) => chef && css`
    border-bottom: 0;  
  
    display: inline-block;

    width: 70vw;

    flex: none;

    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }

    .image {
      margin-bottom: .8rem;
    }

    .info {

      .description {
        margin-top: .4rem;
        margin-bottom: 1.4rem;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
      }
    }
  `}

  @media (min-width: 646px) {
    width: 45%;

    margin: 0 1rem;

    &:last-child {
      border-bottom: 1px solid #d0d0d0;
    }
  }

  @media (min-width: 962px) {
    width: 30%;

    flex-direction: column;

    .image {
      margin-bottom: 1rem;

      flex: none;
    }

    .info {
      .description {
        margin: .8rem 0 .4rem;
      }
    }
  }
`

export const CategoryContainer = styled.div<CategoryContainerTypes>`
  h3 {
    background: #00000012;
    color: #464646;

    padding: .6rem .4rem;
  }

  .items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  ${({ chef }) => chef && css`
    margin-bottom: .6rem;
  
    .items {
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;

      flex-direction: row;
      flex-wrap: nowrap;
    }
  `}

  @media (min-width: 646px) {
    h3 {
      font-size: 1.6rem;

      margin-top: 2rem;
    }

    .items {
      flex-direction: row;

      margin: 0 2rem;
    }
  }
`
