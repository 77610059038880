import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { reset as clearCardapio } from '../store/features/cardapio/cardapioSlice'
import { reset as clearInfo } from '../store/features/info/infoSlice'
import { clearStoreToken } from '../store/features/token/tokenSlice'
import { AppDispatch } from '../store/store'

function InitialPageRouteWrapper() {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(clearInfo())
    dispatch(clearCardapio())
    dispatch(clearStoreToken())
  }, [dispatch])

  return (
    <>
      <Outlet />
    </>
  )
}

export default InitialPageRouteWrapper