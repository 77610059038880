import { DollarSign } from 'lucide-react'
import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { OnlinePaymentOptionsType } from '..'
import { ErrorType } from '../../../hooks/useFormError'
import { RootState } from '../../../store/store'
import { PaymentMethod } from '../../../types/storeInfo'
import { capitalizeEachWord } from '../../../utils/capitalizeEachWord'
import { PaymentMethodsContainer } from '../styles'
import Field from '../../../components/Field'
import PaymentForm from './PaymentForm'

type PaymentMethodsProps = {
  paymentProps: {
    methods: PaymentMethod[] | undefined,
    paymentMethod: 'online' | 'local',
    changeMethod: (method: 'local' | 'online') => void,
    onlinePaymentOptions: OnlinePaymentOptionsType,
    changeOnlinePaymentOptions: (e: ChangeEvent<HTMLInputElement>, clear?: boolean) => void,
    clearOnlinePaymentOption: (field: string) => void,
    localPaymentOption: string,
    changeLocalPaymentOption: (option: string) => void,
    tip: string,
    changeTip: (e: ChangeEvent<HTMLInputElement>) => void,
    clearTip: () => void,
    getError: (field: string) => ErrorType | undefined
  },
  localError: ErrorType | undefined
}

function PaymentMethods ({ paymentProps, localError }: PaymentMethodsProps) {
  const { info } = useSelector((state: RootState) => state.info)

  const {
    paymentMethod,
    changeMethod,
    onlinePaymentOptions,
    changeOnlinePaymentOptions,
    clearOnlinePaymentOption,
    localPaymentOption,
    changeLocalPaymentOption,
    tip,
    changeTip,
    clearTip,
    methods,
    getError
  } = paymentProps

  const tipFieldProps = {
    placeholder: 'Troco para...',
    value: tip,
    change: changeTip,
    clear: clearTip,
    type: 'number'
  }

  return (
    <PaymentMethodsContainer>
      <div className="buttons">
        {
          methods && methods.length > 0 && (
            <button
              type="button"
              className={paymentMethod === 'local' ? 'active' : undefined}
              onClick={() => changeMethod('local')}
            >
              <p>Pague na loja</p>
              (dinheiro/cartão)
            </button>
          )
        }
        {
          info.PagtoOnline === 'SIM' && (
            <button
              type="button"
              className={paymentMethod === 'online' ? 'active' : undefined}
              onClick={() => changeMethod('online')}
            >Pagamento Online</button>
          )
        }
      </div>
      {
        localError?.message &&
          <span className="error">{localError.message}</span>
      }
      <div className="payment_options">
        {paymentMethod === 'local'
          ? (
              <div className="local_payment">
                {
                  methods && methods.map((method) => (
                    method.Nome === 'DINHEIRO'
                      ? (
                        <div key={method.Id} className="method">
                          <label className="item_definition">
                            <span>{capitalizeEachWord(method.Nome)}</span>
                            <div className="custom_checkbox">
                              <input
                                type="radio"
                                name="local_payment_method"
                                onChange={() => changeLocalPaymentOption(method.Nome)}
                                checked={localPaymentOption === method.Nome}
                              />
                              <span className="checkmark" />
                            </div>
                          </label>
                          {
                            localPaymentOption === 'DINHEIRO' && (
                              <Field fieldProps={tipFieldProps}>
                                <DollarSign size={20} color="#ff0000" />
                              </Field>
                            )
                          }
                        </div>
                        )
                      : (
                        <div key={method.Id} className="method">
                          <label className="item_definition">
                            <span>{capitalizeEachWord(method.Nome)}</span>
                            <div className="custom_checkbox">
                              <input
                                type="radio"
                                name="local_payment_method"
                                onChange={() => changeLocalPaymentOption(method.Nome)}
                                checked={localPaymentOption === method.Nome}
                              />
                              <span className="checkmark" />
                            </div>
                          </label>
                        </div>
                        )
                  ))
                }
              </div>
            )
          : (
              <PaymentForm
                options={onlinePaymentOptions}
                change={changeOnlinePaymentOptions}
                error={getError}
                clear={clearOnlinePaymentOption}
              />
            )}
      </div>
    </PaymentMethodsContainer>
  )
}

export default PaymentMethods
