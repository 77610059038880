import styled from 'styled-components';

export const RestorePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .main_button {
    background: #ff0000;
    border-radius: 2.4rem;
    color: #ffffff;

    font-weight: 700;

    margin-top: .6rem;
    padding: 1rem;

    width: 100%;
  }

  .fields {
    display: flex;
    justify-content: space-between;

    width: 100%;

    margin-bottom: 2rem;

    .input_field_wrapper {
      border: 1px solid #000000;
      border-radius: .6rem;

      width: 6rem;
      height: 6rem;

      display: flex;
      justify-content: center;
      align-items: center;

      input {
        background: none;
        border: 0;

        width: 1.4ch;

        font-size: 1.8rem;
      }
    }

    .error {
      border: 1px solid #ff0000;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    width: 100%;

    margin-top: 2rem;

    p {
      color: #666666;

      font-size: 1.2rem;
      font-weight: 700;

      button {
        margin-left: 1rem;
      }
    }

    button {
      background: none;
      
      font-size: 1.2rem;
      font-weight: 700;
    }

    > button {
      color: #ff0000;
    }
  }

  .error {
    font-size: 1.2rem;
    font-weight: 700;

    color: #ff0000;

    margin-bottom: .8rem;
  }
`