import axios from 'axios'

async function getItem (type: string, code: string | number, token: string, domain: string) {
  const env = process.env.REACT_APP_ITEM_DETAILS

  if (!domain || !type || !code || !env || !token) return

  const response = await axios({
    method: 'GET',
    url: `${domain}/${env}/${type}/${code}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

export const itemService = {
  getItem
}
