import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginForm from './components/LoginForm'
import { SignContainer } from './styles'
import RegisterForm from './components/RegisterForm'
import AgilePageLayout from '../../components/AgilePageLayout'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useDispatch } from 'react-redux'
import { cleanBackAddress } from '../../store/features/item/itemSlice'

function Sign() {
  const { accessToken } = useSelector((state: RootState) => state.user)
  const { info } = useSelector((state: RootState) => state.info)
  const { backToItem } = useSelector((state: RootState) => state.item)

  const [register, setRegister] = useState<boolean>(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleGoBackButton() {
    if (register) {
      return setRegister(false)
    } else {
      if (backToItem) {
        navigate(`/loja/cardapio/${backToItem}`)
        dispatch(cleanBackAddress())
      } else {
        navigate(-1)
      }
    }
  }

  useEffect(() => {
    if (!accessToken) return

    if (backToItem) {
      navigate(`/loja/cardapio/${backToItem}`)
    } else if (info?.Codigo) {
      navigate('/loja/cardapio')
    } else {
      navigate('/')
    }
  }, [accessToken, navigate, info.Codigo, backToItem])

  return (
    <AgilePageLayout goBack={handleGoBackButton}>
      <SignContainer>
        {
          register
            ? <RegisterForm goToLogin={() => setRegister(false)} />
            : <LoginForm goToRegister={() => setRegister(true)} />
        }
      </SignContainer>
    </AgilePageLayout>
  )
}
export default Sign
