/* eslint-disable react-hooks/exhaustive-deps */

import ReactDOM from 'react-dom'
import { LogIn, Plus } from 'lucide-react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Overlay from '../Overlay'
import { RootState } from '../../store/store'
import { useNavigate } from 'react-router-dom'
import { setAddress } from '../../store/features/user/userSlice'
import { Address } from '../../types/address'
import { AddressSelectorContainer } from './styles'

type AddressSelectorProps = {
  close: () => void
}

function AddressSelector ({ close }: AddressSelectorProps) {
  const addressSelectorPortal = document.getElementById('selector_portal')

  const overlayRef = useRef<HTMLDivElement>(null)

  const { userData, selectedAddress } = useSelector((state: RootState) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [optionValue, setOptionValue] = useState<string>(() => {
    if (selectedAddress.Log) {
      return `${selectedAddress.Log.toUpperCase()}, ${selectedAddress.Numero}`
    }

    return ''
  })

  function handleOptionChange (e: ChangeEvent<HTMLInputElement>) {
    setOptionValue(e.target.value)

    localStorage.setItem(`selected_address_${userData.unique_name}`, e.target.value)

    dispatch(setAddress(e.target.value))

    close()
  }

  function handleDocumentTouch (e: TouchEvent | MouseEvent) {
    if (e.target === overlayRef.current) {
      close()
    }
  }

  useEffect(() => {
    document.addEventListener('touchstart', handleDocumentTouch)
    document.addEventListener('mousedown', handleDocumentTouch)

    return () => {
      document.removeEventListener('touchstart', handleDocumentTouch)
      document.removeEventListener('mousedown', handleDocumentTouch)
    }
  }, [handleDocumentTouch])

  if (!addressSelectorPortal) return null

  const offlineAddress = localStorage.getItem('offline_address')

  const addresses: Address[] | undefined =
    userData.CompleteUser
      ? userData.CompleteUser.Usuario.Enderecos
      : offlineAddress ? ([JSON.parse(offlineAddress)]) : undefined

  return ReactDOM.createPortal((
    <Overlay position="bottom" ref={overlayRef}>
      <AddressSelectorContainer className="page_context">
        <h3>{ userData.unique_name ? 'SELECIONE UM ENDEREÇO' : 'Selecione onde deseja receber seu pedido!' }</h3>
        {
          userData.unique_name && (
            <div className="options">
              {
                addresses && addresses.map((address: Address) => (
                  <label key={`${address.Log.toUpperCase()}, ${address.Numero}`}>
                    <input
                      id={address.Id}
                      type="radio"
                      name="select_address"
                      value={`${address.Log.toUpperCase()}, ${address.Numero}`}
                      onChange={handleOptionChange}
                      checked={optionValue === `${address.Log.toUpperCase()}, ${address.Numero}`}
                    />
                    <div className="address_description">
                      <p>{address.Log.toUpperCase()}, {address.Numero}</p>
                      <p>{address.Cid.d}/{address.Uf}</p>
                    </div>
                  </label>
                ))
              }
            </div>
          )
        }
        <button
          type="button"
          onClick={() => navigate('/endereco')}
          className="add_new_address_button"
        >
          <Plus size={18} />
          Adicionar novo endereço
        </button>
        {
          !userData.unique_name && (
            <button
              type="button"
              onClick={() => navigate('/login')}
              className="login_button"
            >
              <LogIn size={18} />
              Fazer Login
            </button>
          )
        }
      </AddressSelectorContainer>
    </Overlay>
  ), addressSelectorPortal)
}

export default AddressSelector
