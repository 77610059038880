import { LogOut } from 'lucide-react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { disconnect } from '../../../store/features/user/userSlice'
import { RootState } from '../../../store/store'
import { AccountActionContainer } from '../styles'

function AccountAction() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { privacyUrl } = useSelector((state: RootState) => state.stores)

  function logOut () {
    dispatch(disconnect())
    navigate('/')
  }

  return (
    <AccountActionContainer>
      <div className="page_context">
        <div className="privacy_link">
          {
            privacyUrl && (
              <a
              href={privacyUrl}
              target="_blank"
              rel="noreferrer"
              >Declaração de Privacidade</a>
            )
          }
        </div>
        <button
          type="button"
          onClick={logOut}
        >
          <LogOut />
          Fazer logoff
        </button>
      </div>
    </AccountActionContainer>
  )
}
export default AccountAction