import styled from 'styled-components'

export const SuggestionsContainer = styled.div`
  background: #ffffff;
  border-radius: 1.4rem;

  width: 85%;

  padding: 2rem;

  .section_header {
    background: #f2f2f2;
    border-radius: .1rem;

    color: #999999;
    font-size: 1.4rem;

    padding: 1.2rem .6rem;
    margin-bottom: .4rem;
  }

  .card_heading {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 2rem;
    }

    .current_suggestion_container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .image_wrapper {
      width: 80%;
      max-width: 500px;
      margin-bottom: 1rem;

      img {
        width: 100%;
      }
    }

    p {
      color: #464646;
      font-size: 1.1rem;
      font-weight: 700;

      margin-bottom: 1.6rem;

      span {
        color: #990000;
      }
    }
  }

  .quantity_selection {
    display: flex;
    align-items: center;

    button {
      padding: 1rem 2rem;
    }

    p {
      flex: 1;
      text-align: center;

      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  .suggestions_listing {
    display: flex;

    overflow-x: auto;

    padding: 1rem 0 1rem;
    margin-bottom: .8rem;

    border-bottom: 2px solid #eeeeee;
  }

  .actions {
    display: flex;
    flex-direction: column;

    .add_button {
      background: #ff0000;
      border-radius: .8rem;
      color: #ffffff;

      font-size: 1.2rem;
      font-weight: 700;

      padding: .8rem;

      margin-bottom: 1rem;
    }

    .refuse_button {
      color: #ff0000;
    }
  }
`
