import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { MapPin } from 'lucide-react'
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { addNewAddress, loginWithRefreshToken, reset, setOfflineAddress } from '../../../store/features/user/userSlice'
import { AppDispatch, RootState } from '../../../store/store'
import { FormValuesType } from './AddressForm'

type ConfirmAddressProps = {
  address: {
    lat: number,
    lng: number
  },
  close: () => void,
  formValues: FormValuesType,
  cep: string
}

const ConfirmAddressContainer = styled.div`
  background: #ffffff;

  position: absolute;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  .confirm_box {
    width: 95%;

    background: #ffffff;
    border-radius: .4rem;
    
    position: absolute;
    top: 6rem;
    left: 50%;
    transform: translateX(-50%);

    padding: 1.2rem;

    z-index: 1000;

    .info {
      border-bottom: 1px solid #eeeeee;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding-bottom: 2rem;
    }

    .address {
      display: flex;
      align-items: center;

      svg {
        margin-right: 1.6rem;
      }

      div {
        font-weight: 700;

        p {
          margin: .2rem 0;
        }

        .grey_tone {
          color: #bebebe;
        }
      }
    }

    button {
      border: 1px solid #cccccc;
      border-radius: .4rem;
      color: #ff0000;
      font-size: 1.2rem;
      font-weight: 700;

      padding: .6rem 1.6rem;
    }

    .question {
      font-size: 1.2rem;
      font-weight: 700;
      text-align: center;

      padding-top: 1.2rem;
    }
  }

  .btn_wrapper {
    width: 70%;

    position: absolute;

    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);

    z-index: 1000;

    button {
      background: #ff0000;
      border-radius: .4rem;
      color: #ffffff;
      font-size: 1.2rem;
      font-weight: 700;

      width: 100%;

      padding: .8rem;
    }
  }
`

function ConfirmAddress ({ address, close, formValues, cep }: ConfirmAddressProps) {
  const confirmAddressPortal = document.getElementById('confirm_portal')

  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY || ''
  })

  const { generalLink } = useSelector((state: RootState) => state.token)
  const { accessToken, userId, isLoading, isSuccess, refreshToken, userData } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(reset())

      if (userData.unique_name) {
        localStorage.setItem(
          `selected_address_${userData.unique_name}`,
          `${formValues.street}, ${formValues.number}`
        )
      } else {
        localStorage.setItem(
          'offline_address',
          `${formValues.street}, ${formValues.number}`
        )
      }

      const loginWithRefreshTokenProps = {
        token: refreshToken,
        domain: generalLink
      }

      dispatch(loginWithRefreshToken(loginWithRefreshTokenProps))
      navigate('/')
    }
  }, [isLoading, isSuccess, dispatch, formValues, navigate, refreshToken, userData, generalLink])

  async function handleAddAddress () {
    const addressBody = {
      Cep: cep,
      Uf: formValues.uf,
      Numero: formValues.number,
      Complemento: formValues.complement,
      Log: formValues.street,
      Bai: formValues.neighbourhood,
      Cid: formValues.city,
      Ativo: true,
      Location: {
        Latitude: address.lat,
        Longitude: address.lng
      }
    }

    if (accessToken) {
      const addNewAddressProps = {
        address: addressBody,
        userToken: accessToken,
        userId,
        domain: generalLink
      }

      dispatch(addNewAddress(addNewAddressProps))

      if (!isLoading && isSuccess) {
        const loginWithRefreshTokenProps = {
          token: refreshToken,
          domain: generalLink
        }

        dispatch(loginWithRefreshToken(loginWithRefreshTokenProps))

        if (!isLoading && isSuccess) {
          navigate('')
        }
      }
    } else {
      dispatch(setOfflineAddress(addressBody))
      localStorage.setItem(
        'offline_address',
        JSON.stringify(addressBody)
      )
      navigate('/')
    }
  }

  if (!confirmAddressPortal) return null

  const mapStyles = {
    width: '100%',
    height: '100%'
  }

  return ReactDOM.createPortal((
    <>
      <ConfirmAddressContainer>
        <div className="confirm_box small_context">
          <div className="info">
            <div className="address">
              <MapPin color="#ffcc00" />
              <div>
                { cep && <p>CEP: {cep}</p> }
                <p className="grey_tone">{formValues.street}, {formValues.number}</p>
                <p className="grey_tone">{formValues.neighbourhood.d}</p>
                <p className="grey_tone">{formValues.city.d}/{formValues.uf}</p>
              </div>
            </div>
            <button type="button" onClick={close}>Alterar</button>
          </div>
          <div className="question">
            <p>O endereço está neste ponto?</p>
          </div>
        </div>
        {
          isLoaded && (
            <GoogleMap
              mapContainerStyle={mapStyles}
              center={address}
              zoom={15}
            >
              <Marker position={address} />
            </GoogleMap>
          )
        }
        <div className="btn_wrapper small_context">
          <button type="button" onClick={handleAddAddress}>
            Confirmar Localização
          </button>
        </div>
      </ConfirmAddressContainer>
    </>
  ), confirmAddressPortal)
}

export default ConfirmAddress
