import { ArrowLeft } from 'lucide-react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { RootState } from '../../../store/store'
import { HeaderContainer } from '../styles'

function Header () {
  const { info } = useSelector((state: RootState) => state.info)
  const { storeParams } = useSelector((state: RootState) => state.token)
  const { type } = useSelector((state: RootState) => state.cardapio)

  const navigate = useNavigate()

  function handleGoBackButton () {
    navigate('/')
  }

  const estimate = info.TempoEntrega?.split(':')[1]

  return (
    <HeaderContainer background={`${storeParams.store}/${info.Sigla}1.jpg`}>
      <div className="dark_film" />
      <div className="page_context">
        <div className="store_info">
          <button type="button" onClick={handleGoBackButton} className="go_back_button">
            <ArrowLeft size={24} color="#ffffff" />
          </button>
          <div className="description">
            <h1>{storeParams.name}</h1>
            { type !== 'salao' && estimate && <span>Estimativa de entrega: {estimate}min</span> }
          </div>
        </div>
        <nav>
          <ul>
            <li><NavLink to="cardapio">Cardápio</NavLink></li>
            <li><NavLink to="informacoes">Informações</NavLink></li>
          </ul>
        </nav>
      </div>
    </HeaderContainer>
  )
}
export default Header
