import styled from 'styled-components'

type SearchBarContainerProps = {
  background?: string
}

export const SearchBarContainer = styled.div<SearchBarContainerProps>`
  background: ${({ theme, background }) => background || theme.colors.primary.normal};

  padding: .8rem 0;

  > div {
    display: flex;
  }

  input {
    flex: 1;
    border: 0;
    border-radius: .8rem;

    padding: 0 1rem;
  }

  svg {
    filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 52%));
  }

  .icon-wrapper {
    background: 0;

    padding: .4rem 1.2rem;
  }
`
