import styled from 'styled-components'

export const OrderEvaluationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  h2 {
    color: ${({ theme }) => theme.colors.primary.normal};
    font-size: 1.6rem;

    text-align: center;

    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .heading {
    border-bottom: 1px solid #cccccc;

    text-align: center;

    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .evaluation {
    border-bottom: 1px solid #cccccc;

    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .commentary {
    margin-bottom: 1rem;

    textarea {
      width: 100%;

      border: 2px solid #aaaaaa;
      border-radius: .6rem;

      padding: 1rem .6rem;

      resize: none;
    }
  }

  .evaluation_group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .evaluation_rating {
      display: flex;
    }

    @media (max-width: 380px) {
      svg {
        width: 1.8rem;
      }
    }
  }
`

export const RequestStatusContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  .texts {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 3rem;

    h2 {
      color: ${({ theme }) => theme.colors.primary.normal};

      margin-bottom: 1rem;
    }

    p {
      font-size: 1.1rem;
      font-weight: 700;

      text-align: center;
    }
  }

  .request_status {
    display: flex;
    justify-content: space-between;

    margin-bottom: 3rem;
  }

  .event {
    margin-right: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 1.1rem;
      font-weight: 700;

      text-align: center;
    }

    .event_condition {
      margin: 1rem 0 .6rem;

      svg {
        margin-bottom: .6rem;
      }
    }

    .event_icon {
      max-width: 10rem;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
      }
    }
  }
`

export const RequestsContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.modal_open {
    height: 100vh;
    overflow: hidden;
  }

  h2 {
    color: ${({ theme }) => theme.colors.primary.normal};
    margin-bottom: 1rem;
  }

  .items {
    width: 90%;
    margin: 0 auto;

    .item_list {
      display: flex;
      flex-direction: column;

      margin: 0 auto;
    }

    h2 {
      width: 90%;
      text-align: left;
    }
  }

  .stores {
    width: 90%;
    
    margin: 0 auto 2rem;

    .store_listing {
      display: flex;

      overflow-x: auto;
    }
  }

  @media (min-width: 646px) {
    .items {
      .item_list {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
`
