import { useEffect, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Category } from '../../../types/categories'
import { CategoriesContainer } from '../styles'

type CategoriesProps = {
  categories: Category[],
  setCategory: (category: string) => void,
  selectedCategory: string
}

function Categories({ categories, setCategory, selectedCategory }: CategoriesProps) {
  const [pageWidth, setPageWidth] = useState<number>(0)

  useEffect(() => {
    setPageWidth(window.innerWidth)

    function handlePageResize (e: UIEvent) {
      const target = e.target as Window

      setPageWidth(target.innerWidth)
    }

    window.addEventListener('resize', handlePageResize)

    return () => {
      window.removeEventListener('resize', handlePageResize)
    }
  }, [])

  return (
      <CategoriesContainer>
        <ScrollContainer
          className={`scroll_container ${pageWidth > 1024 ? 'custom_scrollbar' : undefined}`}
          hideScrollbars={false}
        >
          {
            categories.map((category, index) => (
              <button
                type="button"
                key={`category-${index}`}
                className={(selectedCategory === String(category.Id) || (!selectedCategory && category.Id === 1)) ? 'selected' : undefined}
                onClick={() => setCategory(String(category.Id))}
              >
                <img src={category.ImageUrl} alt="Categoria" />
                <span>{category.Descricao}</span>
              </button>
            ))
          }
        </ScrollContainer>
      </CategoriesContainer>
  )
}
export default Categories