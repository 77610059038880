import { HomeHeaderContainer } from '../styles'
import AddressButton from '../../../components/AddressSelector/AddressButton'
import SearchButton from './SearchButton'

import { Menu, ShoppingBag, User } from 'lucide-react'
import { useState } from 'react'
import MobileMenu from '../../../components/MobileMenu'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import Logo from '../../../components/Logo'

type HomeHeaderProps = {
  openSearchStoreMenu: () => void,
  openAddressSelector: () => void
}

function HomeHeader({ openSearchStoreMenu, openAddressSelector }: HomeHeaderProps) {
  const { selectedAddress, userData } = useSelector((state: RootState) => state.user)

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)
  const [isMobileMenuClosing, setIsMobileMenuClosing] = useState<boolean>(false)

  const navigate = useNavigate()

  function handleOpenMobileMenu () {
    setIsMobileMenuOpen(true)
  }

  function handleCloseMobileMenu () {
    setIsMobileMenuClosing(true)

    setTimeout(() => {
      setIsMobileMenuOpen(false)
      setIsMobileMenuClosing(false)
    }, 200)
  }

  return (
    <HomeHeaderContainer>
      <div className="content_wrapper page_context">
        <div className="logo">
          <Logo />
        </div>
        <SearchButton onClick={openSearchStoreMenu} />
        <div className="profile_options">
          <AddressButton handleAddressSelector={openAddressSelector} selectedAddress={selectedAddress} homePageStyle />
          {
            userData.CompleteUser && (
              <div className="links">
                <button
                  type="button"
                  className="common_link"
                  onClick={() => navigate('/pedidos')}
                >
                  <ShoppingBag />
                </button>
                <button
                  type="button"
                  className="common_link"
                  onClick={() => navigate('/perfil')}
                >
                  <User />
                </button>
                <button type="button" onClick={handleOpenMobileMenu} className="hamburguer_button">
                  <Menu />
                </button>
              </div>
            )
          }
        </div>
      </div>
      {
        isMobileMenuOpen && (
          <MobileMenu handleClose={handleCloseMobileMenu} goingOut={isMobileMenuClosing} />
        )
      }
    </HomeHeaderContainer>
  )
}
export default HomeHeader