import { configureStore } from '@reduxjs/toolkit'
import cardapioReducer from './features/cardapio/cardapioSlice'
import cartReducer from './features/cart/cartSlice'
import infoReducer from './features/info/infoSlice'
import itemReducer from './features/item/itemSlice'
import notificationReducer from './features/notification/notificationSlice'
import storesReducer from './features/stores/storesSlice'
import tokenReducer from './features/token/tokenSlice'
import userReducer from './features/user/userSlice'

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    stores: storesReducer,
    user: userReducer,
    notification: notificationReducer,
    cardapio: cardapioReducer,
    info: infoReducer,
    cart: cartReducer,
    item: itemReducer
  },
  middleware: (curryGetDefaultMiddleware) => curryGetDefaultMiddleware({
    serializableCheck: false
  })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
