import { useState } from 'react'
import styled from 'styled-components'

import imageError from '../../../assets/icons/image-error.png'

type StoreCardProps = {
  image: string | undefined,
  name: string,
  bg: string | undefined
}

type StoreCardContainerProps = {
  bg: string
}

const StoreCardContainer = styled.div<StoreCardContainerProps>`
  border-radius: .8rem;

  width: 18rem;

  flex: none;

  box-shadow: 3px 3px 9px -8px rgb(0 0 0 / 52%);
  pointer-events: none;

  margin-bottom: 1rem;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  .image {
    background: #${({ bg }) => bg};

    height: 10rem;

    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem;

    flex: 1;

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;

      &.image_error {
        object-fit: contain;

        opacity: .8;
      }
    }
  }

  p {
    color: #464646;

    font-size: 1.1rem;
    font-weight: 700;

    margin: 1rem 0 1rem 1rem;
  }
`

function StoreCard({ image, name, bg }: StoreCardProps) {
  const [photoError, setPhotoError] = useState<boolean>(false)

  return (
    <StoreCardContainer bg={bg || 'ffffff'}>
      <div className="image">
        <img
          className={photoError ? 'image_error' : undefined}
          src={!photoError ? image : imageError}
          alt="Imagem da loja"
          onError={() => setPhotoError(true)}
        />
      </div>
      <p>{name}</p>
    </StoreCardContainer>
  )
}

export default StoreCard
