import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store/store'

import agileIcon from '../assets/icons/icone-agile-branco.png'
function Logo() {
  const { generalCode } = useSelector((state: RootState) => state.token)
  const { stores } = useSelector((state: RootState) => state.stores)
  const [capa, setCapa] = useState<string>()
  const { appParams } = useSelector((state: RootState) => state.info)
  const [appLogoError, setAppLogoError] = useState<boolean>(false)



  useEffect(()=> {
    if(!stores || !generalCode) return
    const store:any = stores.filter((store:any) => store.CodigoExterno === generalCode);
    if(!store) return

    if(store.length > 0){
      const capa = store.map((item:any) => item.LogoUri)
      setCapa(capa);
    }
  },[generalCode, stores])

  const logo = appLogoError ? (capa || agileIcon) : appParams.logo || capa || agileIcon;

  return (
    <img
      src={logo}
      alt="Logo App"
      onError={() => setAppLogoError(true)}

    />
  )
}
export default Logo