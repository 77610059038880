import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OverlayNotification from '../../../../components/OverlayNotification'
import { setStoreParams } from '../../../../store/features/info/infoSlice'
import { RootState } from '../../../../store/store'
import { StoreParams } from '../../../../types/storeInfo'
import getStoreDistance from '../../../../utils/getStoreDistance'
import { StoreListContainer } from '../../styles'
import StoreButton from './StoreButton'

type StoreListProps = {
  stores: StoreParams[]
}

function StoreList({ stores }: StoreListProps) {
  const { selectedAddress } = useSelector((state: RootState) => state.user)
  const { pictureType } = useSelector((state: RootState) => state.stores)

  const [error, setError] = useState<string>('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  function chooseStore(store: StoreParams) {
    dispatch(setStoreParams(store))

    navigate('/loja/cardapio')
  }

  const sortedStores = [...stores]

  sortedStores.sort((firstStore, secondStore) => {
    const firstStoreDistance = getStoreDistance(firstStore.Endereco, selectedAddress)

    const secondStoreDistance = getStoreDistance(secondStore.Endereco, selectedAddress)

    if (firstStoreDistance < secondStoreDistance) {
      return -1
    }

    return 1
  })

  let storeLocations = sortedStores.map((store: StoreParams) => store.Endereco.Cid.d)

  storeLocations = Array.from(new Set(storeLocations))

  console.log(sortedStores)

  return (
    <StoreListContainer className="page_context">
      {
        storeLocations.map((storeLocation) => (
          <div className="store_section" key={storeLocation}>
            <strong className="store_location">{storeLocation}</strong>
            <div className="list">
              {
                sortedStores.map((store) => store.Endereco.Cid.d === storeLocation && (
                  <StoreButton
                    image={pictureType === 'LOGO' ? store.LogoUri : store.FotoUri}
                    log={store.Endereco.Log}
                    name={store.Nome}
                    number={store.Endereco.Numero || ''}
                    key={`${store.Nome}_${store.CodigoExterno}`}
                    action={() => chooseStore(store)}
                    iconColor={store.Parametros.find((param) => param.Chave === 'MV_COLOR_BACKGROUND')?.Valor}
                    location={getStoreDistance(selectedAddress, store.Endereco)}
                  />
                ))
              }
            </div>
          </div>
        ))
      }
      {
        error && (
          <OverlayNotification text={error} close={() => setError('')} />
        )
      }
    </StoreListContainer>
  )
}

export default StoreList