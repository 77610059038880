import styled from 'styled-components'
import Overlay from './Overlay'

type ConfirmationModalProps = {
  heading: string,
  confirmationText: string,
  additionalInfo?: string,
  yes: () => void,
  no: () => void
}

const ConfirmationModalContainer = styled.div`
  background: #ffffff;
  border-radius: .4rem;

  width: 300px;
  padding: 3rem;

  h3 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  h3, p:nth-of-type(2) {
    margin-bottom: 2rem;
  }

  h3, p, .options button:first-child {
    color: ${({ theme }) => theme.colors.primary.normal};
  }

  h3, p, button {
    font-weight: 700;
  }

  .options {
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 1.6rem;
    }

    button:last-child {
      color: #ff0000;
    }
  }
`

function ConfirmationModal({ heading, confirmationText, additionalInfo, yes, no }: ConfirmationModalProps) {
  return (
    <Overlay>
      <ConfirmationModalContainer>
        <h3>{heading}</h3>
        <p>{confirmationText}</p>
        { additionalInfo && (
          <p>{additionalInfo}</p>
        ) }
        <div className="options">
          <button type="button" onClick={no}>Não</button>
          <button type="button" onClick={yes}>Sim</button>
        </div>
      </ConfirmationModalContainer>
    </Overlay>
  )
}
export default ConfirmationModal