import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useFormError from '../../../hooks/useFormError'
import { loginWithCredentials, register, reset } from '../../../store/features/user/userSlice'
import { AppDispatch, RootState } from '../../../store/store'
import { validateConfirmPasswordField, validateEmailField, validateEmptyField, validatePasswordField } from '../../../utils/fieldValidations'
import FormField from './FormField'
import SignNotification from '../../../components/OverlayNotification'
import RedButton from '../../../components/RedButton'

type RegisterFormProps = {
  goToLogin: () => void
}

function RegisterForm({ goToLogin }: RegisterFormProps) {
  const [registerFormValues, setRegisterFormValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    alias: ''
  })
  const [errorNotification, setErrorNotification] = useState<boolean>(false)

  const { generalToken, generalLink } = useSelector((state: RootState) => state.token)
  const { isSuccess, isLoading, accessToken, isError, message } = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [setNewError, getErrorByFieldname, cleanErrorsByFieldname] = useFormError()

  function validateFields(): boolean {
    const isEmailValid = validateEmailField(registerFormValues.email, setNewError, cleanErrorsByFieldname)
    const isPasswordValid = validatePasswordField(registerFormValues.password, setNewError, cleanErrorsByFieldname)
    const isConfirmPasswordValid =
      validateConfirmPasswordField(
        registerFormValues.confirmPassword,
        registerFormValues.password,
        setNewError,
        cleanErrorsByFieldname
      )
    const isFullNameValid = validateEmptyField(
      'fullName',
      'Nome completo é obrigatório',
      registerFormValues.fullName,
      setNewError,
      cleanErrorsByFieldname
    )

    return (
      isEmailValid &&
      isPasswordValid &&
      isConfirmPasswordValid &&
      isFullNameValid
    )
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault()

    if (validateFields()) {
      const credentials = {
        IdFacebook: null,
        Login: registerFormValues.email,
        Nome: registerFormValues.fullName,
        Senha: registerFormValues.password,
        ConfirmaSenha: registerFormValues.confirmPassword,
        Apelido: registerFormValues.alias
      }

      const registerProps = {
        credentials,
        token: generalToken,
        domain: generalLink
      }

      dispatch(register(registerProps))
    }
  }

  function handleRegisterFormValuesChange(e: ChangeEvent<HTMLInputElement>) {
    setRegisterFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  function handleRegisterFormValuesClear(field: string) {
    setRegisterFormValues((prevState) => ({
      ...prevState,
      [field]: ''
    }))
  }

  function closeErrorSign () {
    setErrorNotification(false)
    dispatch(reset())
  }

  useEffect(() => {
    if (isSuccess) {
      if (accessToken) {
        navigate('/')
      } else {
        const loginWithCredentialsProps = {
          credentials: {
            UserName: registerFormValues.email,
            Password: registerFormValues.password
          },
          token: generalToken,
          domain: generalLink
        }

        dispatch(loginWithCredentials(loginWithCredentialsProps))
      }
    }

    if (isError) {
      setErrorNotification(true)
    }
  }, [
    isSuccess,
    isError,
    accessToken,
    errorNotification,
    dispatch,
    generalToken,
    navigate,
    registerFormValues,
    generalLink
  ])

  const fields = [
    {
      id: 1,
      value: registerFormValues.email,
      placeholder: 'Digite seu email...',
      name: 'email',
      error: getErrorByFieldname('email')
    }, {
      id: 2,
      value: registerFormValues.password,
      placeholder: 'Digite sua senha...',
      name: 'password',
      type: 'password',
      error: getErrorByFieldname('password')
    }, {
      id: 3,
      value: registerFormValues.confirmPassword,
      placeholder: 'Confirme sua senha...',
      name: 'confirmPassword',
      type: 'password',
      error: getErrorByFieldname('confirmPassword')
    }, {
      id: 4,
      value: registerFormValues.fullName,
      placeholder: 'Nome completo...',
      name: 'fullName',
      error: getErrorByFieldname('fullName')
    }, {
      id: 5,
      value: registerFormValues.alias,
      placeholder: 'Apelido...',
      name: 'alias',
      error: getErrorByFieldname('alias')
    }
  ]

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {
          fields.map((field) => (
            <FormField
              key={field.id}
              change={handleRegisterFormValuesChange}
              clear={handleRegisterFormValuesClear}
              {...field}
            />
          ))
        }
        <RedButton type="submit">Cadastrar</RedButton>
      </form>
      {errorNotification && message && !isLoading && (
        <SignNotification
          text={message}
          close={closeErrorSign}
        />
      )}
    </div>
  )
}

export default RegisterForm
