import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
  }

  :root {
    font-size: 62.5%;

    @media (max-width: 300px) {
      font-size: 55%;
    }
  }

  // display issue fix
  #root {
    width: 100vw;
  }

  html {
    scroll-behavior: smooth;
  }

  html, body {
    overflow-x: hidden;
  }

  button {
    background: none;
    border: 0;

    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
  }

  .page_context {
    width: 100%;
    max-width: 1024px;

    margin: 0 auto;
  }

  .small_context {
    width: 100%;
    max-width: 512px;

    margin: 0 auto;
  }

  .black_text {
    color: #000000;
  }

  .white_text {
    color: #ffffff;
  }

  .dark_film {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: #00000066;
  }

  .custom_scrollbar {
    &::-webkit-scrollbar {
      height: 1.2rem;
    }

    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.primary.normal};
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.primary.normal};
      border: 3px solid ${({ theme }) => theme.colors.primary.normal};
      border-radius: 1rem;
    }
  }

  .scroll_container {
    display: flex;

    padding-bottom: 1rem;
  }
`