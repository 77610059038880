import { ChangeEvent, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import SearchBar from '../../../components/SearchBar'
import { StoreParams } from '../../../types/storeInfo'
import { StoreSearchContainer } from '../styles'
import StoreList from './StoreList'

type StoreSearchProps = {
  stores: StoreParams[],
  close: () => void
}

function StoreSearch({ stores, close }: StoreSearchProps) {
  const [searchValue, setSearchValue] = useState<string>('')

  const [filteredStores, setFilteredStores] = useState<StoreParams[]>()
  const [places, setPlaces] = useState<string[]>()

  useEffect(() => {
    const htmlBody = document.querySelector('body')
    if (!htmlBody) return

    const topPos = window.scrollY

    htmlBody.style.position = 'fixed'
    htmlBody.style.top = `0px`

    return () => {
      const oldTopPos = `-${String(topPos)}`
      htmlBody.style.position = ''
      htmlBody.style.top = ''
      window.scrollTo(0, parseInt(oldTopPos || '0') * -1)
    }
  }, [])

  useEffect(() => {
    if (!searchValue || !stores) return

    let filteredStores = stores.filter((store) => store.Nome.toLowerCase().includes(searchValue.toLowerCase()))
    setFilteredStores(filteredStores)

    let allPlaces = filteredStores.map((store) => store.Endereco.Cid.d)
    setPlaces(Array.from(new Set(allPlaces)))
  }, [searchValue, stores])

  const storeSearchPortal = document.getElementById('search_portal')
  if (!storeSearchPortal) return null

  function handleChangeSearchValue(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value)
  }

  return ReactDOM.createPortal((
    <StoreSearchContainer>
      <SearchBar
        searchValue={searchValue}
        handleChangeSearchValue={handleChangeSearchValue}
        cleanSearchValue={() => setSearchValue('')}
        handleGoBack={close}
        placeholder="Você quer encontrar algo?"
      />
      <div className="content">
        {
          searchValue && places && filteredStores && (
            <StoreList stores={filteredStores} />
          )
        }
      </div>
    </StoreSearchContainer>
  ), storeSearchPortal)
}
export default StoreSearch