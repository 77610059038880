import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreParams } from '../../../types/storeInfo';
import storesService from './storesService';

type GetAllStoresParams = {
  generalToken: string,
  generalLink: string
}

type GetStoresByCategoryParams = {
  generalToken: string,
  generalLink: string,
  category: string
}

type GetGeneralParamsProps = {
  token: string,
  domain: string
}

const initialState = {
  stores: [] as StoreParams[],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
  privacyUrl: '',
  pictureType: ''
}

export const getAllStores = createAsyncThunk(
  'stores/get_all',
  async (getAllStoresParams: GetAllStoresParams, thunkApi) => {
    try {
      const response = await storesService.getAllStores(getAllStoresParams.generalToken, getAllStoresParams.generalLink)

      if (response?.status !== 200 || !response.data) {
        throw new Error('Nenhuma loja disponível.')
      }

      return response.data
    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
)

export const getStoresByCategory = createAsyncThunk(
  'stores/get_by_category',
  async (getStoresByCategoryParams: GetStoresByCategoryParams, thunkApi) => {
    try {
      const { generalToken, category, generalLink } = getStoresByCategoryParams

      const response = await storesService.getStoreByCategory(generalToken, category, generalLink)

      if (response?.status !== 200 || !response.data) {
        throw new Error('Nenhuma loja disponível nessa categoria.')
      }

      return response.data
    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
)

export const getGeneralParams = createAsyncThunk(
  'info/get_general_params',
  async (props: GetGeneralParamsProps, thunkApi) => {
    try {
      const response = await storesService.getGeneralParams(props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      return thunkApi.rejectWithValue(err)
    }
  }
)

const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllStores.pending, (state) => {
      state.isLoading = true
    })
    
    .addCase(getAllStores.fulfilled, (state, action: PayloadAction<StoreParams[]>) => {
      state.isLoading = false
      state.isSuccess = true
      state.stores = action.payload
    })

    .addCase(getAllStores.rejected, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.message = String(action.payload)
    })

    .addCase(getStoresByCategory.pending, (state) => {
      state.isLoading = true
    })

    .addCase(getStoresByCategory.fulfilled, (state, action: PayloadAction<StoreParams[]>) => {
      state.isLoading = false
      state.isSuccess = true
      state.stores = action.payload
    })

    .addCase(getStoresByCategory.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = String(action)
    })

    .addCase(getGeneralParams.pending, (state) => {
      state.isLoading = true
    })

    .addCase(getGeneralParams.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true

      const privacyUrl = action.payload.find((param: any) => param?.Chave === 'MV_URL_DECLARACAO_PRIVACIDADE')?.Valor
      const pictureType = action.payload.find((param: any) => param?.Chave === 'MV_IMAGEM_CARD_LOJA')?.Valor

      state.privacyUrl = privacyUrl || ''
      state.pictureType = pictureType || ''
    })

    .addCase(getGeneralParams.rejected, (state) => {
      state.isLoading = false
      state.isError = true
    })
  }
})

export default storesSlice.reducer
